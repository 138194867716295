[
  {
    "//key": "font-slug",
    "//type": "font",
    "//name": "Font Name"
  },

  {
    "//key": "img-slug",
    "//type": "img",
    "//path": "/img-path.jpg"
  }
]

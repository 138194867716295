import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { Emitter } from '../core'
import { Stick } from '../components'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class IRX extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      page: qs('.page'),
      lines: qsa('.irxhonu-line', el),
      hovers: qsa('.irxhonu-hover', el),
      problem: qs('.irxhonu-problem', el),
      sticky: qs('[data-stick]', el),
      stick: qs('.sticky', el),
      content: qsa('.sticky-content', el),
      isActive: false,
    })

    // * Sticky makes the section sticky / fixed

    this.sticky = new Stick({
      container: el,
      sticky: this.dom.stick,
      items: this.dom.content,
      name: this.dom.stick.parentNode,
    })
  }

  setAnimation(splitText) {
    const { page, lines, hovers, problem } = this.dom

    const chars = splitText[0].chars
    const description = splitText[1].lines

    gsap.set(page, { autoAlpha: 1 })

    const hero = gsap.timeline({
      paused: true,
      onComplete: () => {
        Emitter.emit('animateIn:ended')
      },
    })

    hero.from(chars, { duration: 0.5, yPercent: 100, stagger: 0.1 })

    description.forEach((line) => {
      hero.from(
        qsa('.char-1', line),
        { duration: 0.5, yPercent: -100, stagger: 0.05 },
        0.2,
      )
    })

    hero.from(
      '.irxhonu-line',
      { duration: 0.35, autoAlpha: 0, stagger: 0.035 },
      0,
    )

    hero.play()

    this.tl = gsap.timeline({ paused: true })
    this.tl.to(lines, { autoAlpha: 0, stagger: 1 }, 0)
    this.tl.to(hovers, { autoAlpha: 0, stagger: 1 }, 0)
  }

  on() {
    const { hovers, problem, lines } = this.dom

    hovers.forEach((item) => {
      item.addEventListener('mouseenter', (el) => {
        this.dom.isActive = true
        const index = el.target.dataset.line
        const text = el.target.dataset.txt

        gsap.to(problem, { autoAlpha: 1 })

        problem.innerHTML = text

        gsap.to(lines[index], {
          duration: 0.2,
          stroke: 'rgba(183, 183, 183, 1)',
        })
      })
      item.addEventListener('mouseleave', (el) => {
        this.dom.isActive = false
        const index = el.target.dataset.line

        gsap.to(problem, { autoAlpha: 0 })
        gsap.to(lines[index], {
          duration: 0.2,
          stroke: 'rgba(55, 55, 55, 1)',
        })
      })
    })
  }

  run(x, y) {
    if (!this.state.isPreVisible) return
    const { problem, isActive } = this.dom

    if (problem && isActive) {
      gsap.set(problem, { top: y, left: x })
    }
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'top top',
      end: 'bottom bottom',
      scrub: true,
      onUpdate: (self) => {
        this.tl.progress(self.progress)
      },
    })
  }
}

import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { Stick } from '../components'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Signup extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      sticky: qs('[data-stick]', el),
      stick: qs('.sticky', el),
      content: qsa('.sticky-content', el),
      leftRing: qsa('.ring.left .ring-icon', el),
      rightRing: qsa('.ring.right .ring-icon', el),
      titles: qsa('.-t5', el),
      button: qs('.form-button', el),
      input: qs('.signup-input', el),
      lineInput: qs('.line', el),
      foot: qsa('.signup-footnote', el),
    })

    // * Sticky makes the section sticky / fixed

    this.sticky = new Stick({
      container: el,
      sticky: this.dom.stick,
      items: this.dom.content,
      name: this.dom.stick.parentNode,
    })
  }

  setAnimation() {
    const {
      content,
      leftRing,
      rightRing,
      titles,
      lineInput,
      input,
      button,
      foot,
    } = this.dom

    const setBG = gsap.to('html', { duration: 0.1, '--cardBackground': '#000' })

    // * Masking / Reveal animation of the rings

    this.masking = gsap.timeline({ paused: true })
    this.masking.to(content, { duration: 0.1, autoAlpha: 1 })
    this.masking.add(setBG, '-=0.1')
    this.masking.to(
      leftRing,
      { duration: 8, width: '0%', stagger: { each: 1 } },
      0,
    )
    this.masking.to(
      rightRing,
      { duration: 8, width: '100%', stagger: { each: 1 } },
      0,
    )
    this.masking.to('html', { duration: 8, '--mask': '100%' }, '-=14')

    // * Signup animation

    this.tl = gsap.timeline({ paused: true })

    titles.forEach((title) => {
      const lines = qsa('.line-0', title)
      lines.forEach((line, i) => {
        const chars = qsa('.char-1', line)
        this.tl.from(
          chars,
          { duration: 0.6, y: '101%', stagger: 0.035, ease: 'power2.inOut' },
          0,
        )
      })
    })

    this.tl.from(lineInput, { scaleX: 0, ease: 'power2.inOut' }, '-=0.5')
    this.tl.from(input, { y: 60 }, '-=0.4')
    this.tl.from(button, { y: -40, autoAlpha: 0 }, '-=0.3')
    foot.forEach((foot) => {
      const lines = qsa('.line-0', foot)
      this.tl.from(
        lines,
        {
          duration: 0.6,
          y: 30,
          autoAlpha: 0,
          stagger: 0.05,
          ease: 'power2.inOut',
        },
        '-=0.5',
      )
    })
  }

  setTrigger() {
    this.tl && this.tl.play()
    this.masking && this.masking.play()
  }

  resize() {
    this.sticky && this.sticky.resize()
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'center center',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })

    ScrollTrigger.create({
      trigger: el,
      start: 'top top',
      end: 'bottom bottom-=80%',
      scrub: true,
      onUpdate: (self) => {
        this.masking.progress(self.progress)
      },
    })
  }

  destroy() {
    gsap.set('html', { '--sides': '50%' })
    gsap.set('html', { '--tops': '50%' })
  }
}

export const waves = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 120,
  w: 640,
  h: 240,
  nm: 'L - Sine Waves 2',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'Waves Container',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [810, 108, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [-110, 108, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [882.5, 70.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                      ],
                      o: [
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                      ],
                      v: [
                        [-880, -68],
                        [-730.55, 68],
                        [-653.45, 68],
                        [-504, -68],
                        [-424, -68],
                        [-274.55, 68],
                        [-189.45, 68],
                        [-40, -68],
                        [40, -68],
                        [189.45, 68],
                        [266.55, 68],
                        [416, -68],
                        [496, -68],
                        [645.45, 68],
                        [730.55, 68],
                        [880, -68],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [882.5, 70.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [810, 108, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [-110, 108, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [882.5, 70.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                      ],
                      o: [
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                      ],
                      v: [
                        [-880, 68],
                        [-730.55, -68],
                        [-653.45, -68],
                        [-504, 68],
                        [-424, 68],
                        [-274.55, -68],
                        [-189.45, -68],
                        [-40, 68],
                        [40, 68],
                        [189.45, -68],
                        [266.55, -68],
                        [416, 68],
                        [496, 68],
                        [645.45, -68],
                        [730.55, -68],
                        [880, 68],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [882.5, 70.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [790, 108, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [-130, 108, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [906.5, 70.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                      ],
                      o: [
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                      ],
                      v: [
                        [-880, 68],
                        [-730.55, -68],
                        [-653.45, -68],
                        [-504, 68],
                        [-424, 68],
                        [-274.55, -68],
                        [-189.45, -68],
                        [-40, 68],
                        [40, 68],
                        [189.45, -68],
                        [266.55, -68],
                        [416, 68],
                        [496, 68],
                        [645.45, -68],
                        [730.55, -68],
                        [880, 68],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [906.5, 70.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [790, 108, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [-130, 108, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [906.5, 70.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                      ],
                      o: [
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                      ],
                      v: [
                        [-880, -68],
                        [-730.55, 68],
                        [-653.45, 68],
                        [-504, -68],
                        [-424, -68],
                        [-274.55, 68],
                        [-189.45, 68],
                        [-40, -68],
                        [40, -68],
                        [189.45, 68],
                        [266.55, 68],
                        [416, -68],
                        [496, -68],
                        [645.45, 68],
                        [730.55, 68],
                        [880, -68],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [906.5, 70.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [770, 108, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [-150, 108, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [930.5, 70.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                      ],
                      o: [
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                      ],
                      v: [
                        [-880, -68],
                        [-730.55, 68],
                        [-653.45, 68],
                        [-504, -68],
                        [-424, -68],
                        [-274.55, 68],
                        [-189.45, 68],
                        [-40, -68],
                        [40, -68],
                        [189.45, 68],
                        [266.55, 68],
                        [416, -68],
                        [496, -68],
                        [645.45, 68],
                        [730.55, 68],
                        [880, -68],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [930.5, 70.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [770, 108, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [-150, 108, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [930.5, 70.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                      ],
                      o: [
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                      ],
                      v: [
                        [-880, 68],
                        [-730.55, -68],
                        [-653.45, -68],
                        [-504, 68],
                        [-424, 68],
                        [-274.55, -68],
                        [-189.45, -68],
                        [-40, 68],
                        [40, 68],
                        [189.45, -68],
                        [266.55, -68],
                        [416, 68],
                        [496, 68],
                        [645.45, -68],
                        [730.55, -68],
                        [880, 68],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [930.5, 70.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Group 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [750, 108, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [-170, 108, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [954.5, 70.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                      ],
                      o: [
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                      ],
                      v: [
                        [-880, 68],
                        [-730.55, -68],
                        [-653.45, -68],
                        [-504, 68],
                        [-424, 68],
                        [-274.55, -68],
                        [-189.45, -68],
                        [-40, 68],
                        [40, 68],
                        [189.45, -68],
                        [266.55, -68],
                        [416, 68],
                        [496, 68],
                        [645.45, -68],
                        [730.55, -68],
                        [880, 68],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [954.5, 70.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [750, 108, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [-170, 108, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [954.5, 70.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                        [0, 0],
                        [-74.73, 0],
                        [0, 0],
                        [-74.72, 0],
                      ],
                      o: [
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                        [74.72, 0],
                        [0, 0],
                        [74.73, 0],
                        [0, 0],
                      ],
                      v: [
                        [-880, -68],
                        [-730.55, 68],
                        [-653.45, 68],
                        [-504, -68],
                        [-424, -68],
                        [-274.55, 68],
                        [-189.45, 68],
                        [-40, -68],
                        [40, -68],
                        [189.45, 68],
                        [266.55, 68],
                        [416, -68],
                        [496, -68],
                        [645.45, 68],
                        [730.55, 68],
                        [880, -68],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [954.5, 70.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  fonts: {
    list: [
      {
        fName: 'Berkeley Mono',
        fFamily: 'Berkeley Mono',
        fStyle: 'Regular',
        ascent: 72.7996826171875,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: 'I slider number 2',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [11.262, 8.168, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 12,
                f: 'Berkeley Mono',
                t: 'FSF',
                ca: 1,
                j: 0,
                tr: 0,
                lh: 66.9562225341797,
                ls: 0,
                fc: [0, 0, 0],
              },
              t: 0,
            },
          ],
          x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 23900);\n$bm_rt = n = Math.round(n);',
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: -4,
      op: 247.5,
      st: -4,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'UI/waves Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [322.2, 118.986, 0], ix: 2, l: 2 },
        a: { a: 0, k: [328.5, 114, 0], ix: 1, l: 2 },
        s: { a: 0, k: [98, 98, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [469.959, 202.519],
                    [469.959, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [477.62, 202.519],
                    [477.62, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [485.28, 202.519],
                    [485.28, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [492.941, 202.519],
                    [492.941, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [500.609, 202.519],
                    [500.609, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [508.27, 202.519],
                    [508.27, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [515.93, 202.519],
                    [515.93, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [523.598, 202.519],
                    [523.598, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [531.262, 202.519],
                    [531.262, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [538.922, 202.519],
                    [538.922, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [546.582, 202.519],
                    [546.582, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [554.252, 202.519],
                    [554.252, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [561.912, 202.519],
                    [561.912, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [569.572, 202.519],
                    [569.572, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [577.232, 202.519],
                    [577.232, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [625.442, 202.519],
                    [625.442, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [584.902, 202.519],
                    [584.902, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [633.102, 202.519],
                    [633.102, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [592.562, 202.519],
                    [592.562, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 19',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [640.762, 202.519],
                    [640.762, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 20',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [600.222, 202.519],
                    [600.222, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 21',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 21,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [648.432, 202.519],
                    [648.432, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 22',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 22,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [607.892, 202.519],
                    [607.892, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 23',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 23,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [656.092, 202.519],
                    [656.092, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 24',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 24,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [615.552, 202.519],
                    [615.552, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 25',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 25,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [315.891, 202.519],
                    [315.891, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 26',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 26,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [323.552, 202.519],
                    [323.552, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 27',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 27,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [331.212, 202.519],
                    [331.212, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 28',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 28,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [338.873, 202.519],
                    [338.873, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 29',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 29,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [346.541, 202.519],
                    [346.541, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 30',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 30,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [354.202, 202.519],
                    [354.202, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 31',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 31,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [361.862, 202.519],
                    [361.862, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 32',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 32,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [369.53, 202.519],
                    [369.53, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 33',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 33,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [377.191, 202.519],
                    [377.191, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 34',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 34,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [384.852, 202.519],
                    [384.852, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 35',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 35,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [392.512, 202.519],
                    [392.512, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 36',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 36,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [400.18, 202.519],
                    [400.18, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 37',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 37,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [407.841, 202.519],
                    [407.841, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 38',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 38,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [415.501, 202.519],
                    [415.501, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 39',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 39,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [423.162, 202.519],
                    [423.162, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 40',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 40,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [430.83, 202.519],
                    [430.83, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 41',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 41,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [438.49, 202.519],
                    [438.49, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 42',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 42,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [446.151, 202.519],
                    [446.151, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 43',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 43,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [453.82, 202.519],
                    [453.82, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 44',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 44,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [461.48, 202.519],
                    [461.48, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 45',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 45,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [161.823, 202.519],
                    [161.823, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 46',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 46,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [169.484, 202.519],
                    [169.484, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 47',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 47,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [177.144, 202.519],
                    [177.144, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 48',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 48,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [184.804, 202.519],
                    [184.804, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 49',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 49,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [192.473, 202.519],
                    [192.473, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 50',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 50,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [200.134, 202.519],
                    [200.134, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 51',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 51,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [207.794, 202.519],
                    [207.794, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 52',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 52,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [215.462, 202.519],
                    [215.462, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 53',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 53,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [223.123, 202.519],
                    [223.123, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 54',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 54,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [230.783, 202.519],
                    [230.783, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 55',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 55,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [238.444, 202.519],
                    [238.444, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 56',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 56,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [246.112, 202.519],
                    [246.112, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 57',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 57,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [253.773, 202.519],
                    [253.773, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 58',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 58,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [261.433, 202.519],
                    [261.433, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 59',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 59,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [269.093, 202.519],
                    [269.093, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 60',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 60,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [276.762, 202.519],
                    [276.762, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 61',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 61,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [284.422, 202.519],
                    [284.422, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 62',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 62,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [292.083, 202.519],
                    [292.083, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 63',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 63,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [299.751, 202.519],
                    [299.751, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 64',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 64,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [307.412, 202.519],
                    [307.412, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 65',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 65,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0.5, 202.519],
                    [0.5, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 66',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 66,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [8.168, 202.519],
                    [8.168, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 67',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 67,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [15.828, 202.519],
                    [15.828, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 68',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 68,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [23.489, 202.519],
                    [23.489, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 69',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 69,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [31.15, 202.519],
                    [31.15, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 70',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 70,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [38.818, 202.519],
                    [38.818, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 71',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 71,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [46.478, 202.519],
                    [46.478, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 72',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 72,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [54.139, 202.519],
                    [54.139, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 73',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 73,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [61.807, 202.519],
                    [61.807, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 74',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 74,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [69.467, 202.519],
                    [69.467, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 75',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 75,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [77.128, 202.519],
                    [77.128, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 76',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 76,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [84.789, 202.519],
                    [84.789, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 77',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 77,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [92.457, 202.519],
                    [92.457, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 78',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 78,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [100.117, 202.519],
                    [100.117, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 79',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 79,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [107.778, 202.519],
                    [107.778, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 80',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 80,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [115.439, 202.519],
                    [115.439, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 81',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 81,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [123.107, 202.519],
                    [123.107, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 82',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 82,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [130.767, 202.519],
                    [130.767, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 83',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 83,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [138.428, 202.519],
                    [138.428, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 84',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 84,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [146.096, 202.519],
                    [146.096, 208.191],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 85',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 85,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [153.757, 202.519],
                    [153.757, 213.87],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 86',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 86,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.653, 3.107],
                    [10.653, 3.107],
                    [10.653, -3.107],
                    [-10.653, -3.107],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [56.262, 224.634], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 88',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 87,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.716, 0],
                    [0, 1.716],
                    [1.716, 0],
                    [0, -1.716],
                  ],
                  o: [
                    [1.716, 0],
                    [0, -1.716],
                    [-1.716, 0],
                    [0, 1.716],
                  ],
                  v: [
                    [0, 3.107],
                    [3.107, 0.001],
                    [0, -3.107],
                    [-3.107, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [66.915, 224.634], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 89',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 88,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.716, 0],
                    [0, 1.716],
                    [1.716, 0],
                    [0, -1.716],
                  ],
                  o: [
                    [1.716, 0],
                    [0, -1.716],
                    [-1.716, 0],
                    [0, 1.716],
                  ],
                  v: [
                    [0, 3.107],
                    [3.107, 0.001],
                    [0, -3.107],
                    [-3.107, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [45.609, 224.634], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 90',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 89,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.716, 0],
                    [0, 1.716],
                    [1.716, 0],
                    [0, -1.716],
                  ],
                  o: [
                    [1.716, 0],
                    [0, -1.716],
                    [-1.716, 0],
                    [0, 1.716],
                  ],
                  v: [
                    [0, 3.107],
                    [3.107, 0.001],
                    [0, -3.107],
                    [-3.107, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [30.73, 224.634], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 91',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 90,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.49, 0.456],
                    [-0.33, 0.416],
                    [-0.06, 0.16],
                    [0, 0.232],
                    [0.1, 0.192],
                    [0.21, 0.08],
                    [0.4, 0],
                    [0.22, -0.072],
                    [0.11, -0.168],
                    [0.03, -0.304],
                    [0, 0],
                    [-0.16, 0.288],
                    [-0.36, 0.12],
                    [-0.65, 0],
                    [-0.36, -0.136],
                    [-0.15, -0.328],
                    [0, -0.6],
                    [0.11, -0.256],
                    [0.31, -0.336],
                    [0.44, -0.432],
                    [0.64, -0.552],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [1.05, -0.896],
                    [0.5, -0.464],
                    [0.18, -0.208],
                    [0.06, -0.168],
                    [0, -0.352],
                    [-0.09, -0.2],
                    [-0.22, -0.08],
                    [-0.38, 0],
                    [-0.21, 0.064],
                    [-0.1, 0.16],
                    [0, 0],
                    [0.05, -0.544],
                    [0.18, -0.296],
                    [0.36, -0.128],
                    [0.67, 0],
                    [0.37, 0.136],
                    [0.15, 0.328],
                    [0, 0.368],
                    [-0.11, 0.256],
                    [-0.58, 0.616],
                    [-0.44, 0.424],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [29.855, 2.544],
                    [32.165, 0.516],
                    [33.405, -0.804],
                    [33.765, -1.356],
                    [33.865, -1.956],
                    [33.715, -2.772],
                    [33.265, -3.192],
                    [32.335, -3.312],
                    [31.435, -3.204],
                    [30.955, -2.856],
                    [30.755, -2.16],
                    [29.685, -2.16],
                    [30.005, -3.408],
                    [30.815, -4.032],
                    [32.325, -4.224],
                    [33.875, -4.02],
                    [34.655, -3.324],
                    [34.885, -1.932],
                    [34.715, -0.996],
                    [34.075, -0.108],
                    [32.545, 1.464],
                    [30.925, 2.928],
                    [30.925, 3.096],
                    [34.985, 3.096],
                    [34.985, 4.056],
                    [29.855, 4.056],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.73, 0],
                    [0.3, 0.064],
                    [0.36, 0.152],
                    [0, 0],
                    [-0.3, -0.08],
                    [-0.34, 0],
                    [-0.28, 0.112],
                    [-0.12, 0.256],
                    [0, 0.448],
                    [0.24, 0.224],
                    [0.56, 0],
                    [0.47, -0.064],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.09, 0],
                    [-0.3, -0.36],
                    [0, -0.848],
                    [0.19, -0.384],
                    [0.42, -0.168],
                  ],
                  o: [
                    [-0.43, 0],
                    [-0.31, -0.056],
                    [0, 0],
                    [0.42, 0.2],
                    [0.3, 0.08],
                    [0.5, 0],
                    [0.29, -0.112],
                    [0.12, -0.256],
                    [0, -0.528],
                    [-0.23, -0.232],
                    [-0.28, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.25, -0.016],
                    [0.73, 0],
                    [0.3, 0.352],
                    [0, 0.664],
                    [-0.19, 0.384],
                    [-0.42, 0.168],
                  ],
                  v: [
                    [24.605, 4.164],
                    [23.515, 4.068],
                    [22.515, 3.756],
                    [22.515, 2.712],
                    [23.595, 3.132],
                    [24.555, 3.252],
                    [25.715, 3.084],
                    [26.335, 2.532],
                    [26.525, 1.476],
                    [26.165, 0.348],
                    [24.975, 0],
                    [23.855, 0.096],
                    [23.855, -1.032],
                    [26.135, -3.18],
                    [26.135, -3.216],
                    [22.685, -3.216],
                    [22.685, -4.104],
                    [27.215, -4.104],
                    [27.215, -2.964],
                    [24.985, -0.852],
                    [25.015, -0.804],
                    [25.535, -0.828],
                    [27.075, -0.288],
                    [27.535, 1.512],
                    [27.245, 3.084],
                    [26.335, 3.912],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [15.375, 1.344],
                    [18.095, -4.224],
                    [19.185, -4.224],
                    [16.415, 1.344],
                    [16.415, 1.608],
                    [18.665, 1.608],
                    [18.665, -0.384],
                    [19.625, -0.384],
                    [19.625, 1.608],
                    [20.775, 1.608],
                    [20.775, 2.52],
                    [19.625, 2.52],
                    [19.625, 4.056],
                    [18.665, 4.056],
                    [18.665, 2.52],
                    [15.375, 2.52],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [8.185, 1.344],
                    [10.895, -4.224],
                    [11.985, -4.224],
                    [9.215, 1.344],
                    [9.215, 1.608],
                    [11.475, 1.608],
                    [11.475, -0.384],
                    [12.435, -0.384],
                    [12.435, 1.608],
                    [13.585, 1.608],
                    [13.585, 2.52],
                    [12.435, 2.52],
                    [12.435, 4.056],
                    [11.475, 4.056],
                    [11.475, 2.52],
                    [8.185, 2.52],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 4,
              ty: 'sh',
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -0.512],
                    [-0.03, -0.272],
                    [-0.06, -0.336],
                    [-0.09, -0.168],
                    [-0.2, -0.08],
                    [-0.39, 0],
                    [-0.2, 0.08],
                    [-0.09, 0.168],
                    [-0.06, 0.32],
                    [-0.03, 0.272],
                    [0, 0.512],
                    [0.03, 0.272],
                    [0.05, 0.336],
                    [0.09, 0.168],
                    [0.21, 0.08],
                    [0.38, 0],
                    [0.21, -0.08],
                    [0.09, -0.168],
                    [0.06, -0.32],
                    [0.03, -0.272],
                  ],
                  o: [
                    [0, 0.512],
                    [0.03, 0.272],
                    [0.06, 0.32],
                    [0.09, 0.168],
                    [0.21, 0.08],
                    [0.38, 0],
                    [0.21, -0.08],
                    [0.09, -0.168],
                    [0.05, -0.336],
                    [0.03, -0.272],
                    [0, -0.512],
                    [-0.03, -0.272],
                    [-0.06, -0.32],
                    [-0.09, -0.168],
                    [-0.2, -0.08],
                    [-0.39, 0],
                    [-0.2, 0.08],
                    [-0.09, 0.168],
                    [-0.06, 0.336],
                    [-0.03, 0.272],
                  ],
                  v: [
                    [-5.295, 0.024],
                    [-5.245, 1.2],
                    [-5.115, 2.112],
                    [-4.895, 2.844],
                    [-4.455, 3.216],
                    [-3.565, 3.336],
                    [-2.695, 3.216],
                    [-2.245, 2.844],
                    [-2.015, 2.112],
                    [-1.885, 1.2],
                    [-1.835, 0.024],
                    [-1.885, -1.152],
                    [-2.015, -2.064],
                    [-2.245, -2.796],
                    [-2.695, -3.168],
                    [-3.565, -3.288],
                    [-4.455, -3.168],
                    [-4.895, -2.796],
                    [-5.115, -2.064],
                    [-5.245, -1.152],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 5',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 5,
              ty: 'sh',
              ix: 6,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0.576],
                    [-0.04, 0.36],
                    [-0.07, 0.312],
                    [-0.16, 0.248],
                    [-0.3, 0.08],
                    [-0.61, 0],
                    [-0.31, -0.088],
                    [-0.16, -0.248],
                    [-0.13, -0.536],
                    [-0.04, -0.36],
                    [0, -0.568],
                    [0.04, -0.36],
                    [0.07, -0.304],
                    [0.17, -0.248],
                    [0.3, -0.08],
                    [0.61, 0],
                    [0.3, 0.088],
                    [0.17, 0.248],
                    [0.13, 0.536],
                    [0.04, 0.36],
                  ],
                  o: [
                    [0, -0.568],
                    [0.05, -0.36],
                    [0.13, -0.536],
                    [0.17, -0.248],
                    [0.3, -0.088],
                    [0.61, 0],
                    [0.3, 0.08],
                    [0.17, 0.248],
                    [0.07, 0.312],
                    [0.05, 0.36],
                    [0, 0.576],
                    [-0.04, 0.36],
                    [-0.13, 0.536],
                    [-0.16, 0.248],
                    [-0.31, 0.088],
                    [-0.61, 0],
                    [-0.3, -0.08],
                    [-0.17, -0.248],
                    [-0.07, -0.304],
                    [-0.04, -0.36],
                  ],
                  v: [
                    [-6.315, 0.024],
                    [-6.255, -1.368],
                    [-6.075, -2.376],
                    [-5.645, -3.552],
                    [-4.935, -4.044],
                    [-3.565, -4.176],
                    [-2.195, -4.044],
                    [-1.505, -3.552],
                    [-1.055, -2.376],
                    [-0.895, -1.368],
                    [-0.815, 0.024],
                    [-0.875, 1.428],
                    [-1.045, 2.424],
                    [-1.495, 3.6],
                    [-2.195, 4.092],
                    [-3.565, 4.224],
                    [-4.935, 4.092],
                    [-5.645, 3.6],
                    [-6.085, 2.424],
                    [-6.255, 1.428],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 6',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 6,
              ty: 'sh',
              ix: 7,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.22, 0],
                    [0.06, 0.032],
                    [0.03, 0.12],
                    [0, 0.24],
                    [-0.02, 0.072],
                    [0, 0.024],
                    [-0.06, 0.032],
                    [-0.21, 0],
                    [-0.06, -0.04],
                    [-0.02, -0.12],
                    [0, -0.24],
                    [0.02, -0.08],
                    [0.01, -0.024],
                    [0.06, -0.032],
                  ],
                  o: [
                    [-0.2, 0],
                    [-0.07, -0.04],
                    [-0.04, -0.168],
                    [0, -0.224],
                    [0.01, -0.08],
                    [0.04, -0.136],
                    [0.05, -0.032],
                    [0.21, 0],
                    [0.07, 0.032],
                    [0.04, 0.168],
                    [0, 0.224],
                    [-0.02, 0.072],
                    [-0.03, 0.136],
                    [-0.05, 0.032],
                  ],
                  v: [
                    [-3.595, 0.924],
                    [-3.995, 0.876],
                    [-4.135, 0.636],
                    [-4.195, 0.024],
                    [-4.165, -0.42],
                    [-4.135, -0.576],
                    [-3.995, -0.828],
                    [-3.595, -0.876],
                    [-3.185, -0.816],
                    [-3.055, -0.588],
                    [-2.995, 0.024],
                    [-3.015, 0.48],
                    [-3.055, 0.624],
                    [-3.185, 0.876],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 7',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 7,
              ty: 'sh',
              ix: 8,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-10.515, -1.8],
                    [-10.435, -3.024],
                    [-10.515, -3.024],
                    [-11.325, -2.34],
                    [-13.095, -1.224],
                    [-13.095, -2.352],
                    [-10.655, -4.104],
                    [-9.505, -4.104],
                    [-9.505, 4.056],
                    [-10.515, 4.056],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 8',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 8,
              ty: 'sh',
              ix: 9,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -0.528],
                    [-0.08, -0.24],
                    [-0.23, -0.096],
                    [-0.46, 0],
                    [-0.23, 0.096],
                    [-0.08, 0.256],
                    [0, 0.544],
                    [0.1, 0.232],
                    [0.23, 0.088],
                    [0.48, 0],
                    [0.23, -0.096],
                    [0.09, -0.256],
                  ],
                  o: [
                    [0, 0.496],
                    [0.09, 0.24],
                    [0.23, 0.088],
                    [0.5, 0],
                    [0.24, -0.096],
                    [0.09, -0.256],
                    [0, -0.488],
                    [-0.09, -0.24],
                    [-0.23, -0.096],
                    [-0.47, 0],
                    [-0.23, 0.096],
                    [-0.09, 0.256],
                  ],
                  v: [
                    [-19.695, -1.524],
                    [-19.575, -0.42],
                    [-19.105, 0.084],
                    [-18.065, 0.216],
                    [-16.975, 0.072],
                    [-16.495, -0.456],
                    [-16.365, -1.656],
                    [-16.505, -2.736],
                    [-16.985, -3.228],
                    [-18.045, -3.372],
                    [-19.095, -3.228],
                    [-19.565, -2.7],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 9',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 9,
              ty: 'sh',
              ix: 10,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.37, 0.456],
                    [-0.53, 0.808],
                    [0, 0],
                    [0.19, -0.072],
                    [0.42, 0],
                    [0.35, 0.136],
                    [0.12, 0.36],
                    [0, 0.744],
                    [-0.14, 0.368],
                    [-0.36, 0.136],
                    [-0.75, 0],
                    [-0.37, -0.144],
                    [-0.13, -0.376],
                    [0, -0.784],
                    [0.15, -0.512],
                    [0.4, -0.568],
                    [0.82, -0.904],
                  ],
                  o: [
                    [0.7, -0.696],
                    [0.38, -0.456],
                    [0, 0],
                    [-0.19, 0.232],
                    [-0.19, 0.072],
                    [-0.71, 0],
                    [-0.34, -0.136],
                    [-0.13, -0.36],
                    [0, -0.768],
                    [0.14, -0.376],
                    [0.37, -0.144],
                    [0.76, 0],
                    [0.36, 0.144],
                    [0.15, 0.376],
                    [0, 0.712],
                    [-0.15, 0.512],
                    [-0.4, 0.568],
                    [0, 0],
                  ],
                  v: [
                    [-19.605, 4.176],
                    [-18.005, 2.448],
                    [-16.645, 0.552],
                    [-16.705, 0.504],
                    [-17.275, 0.96],
                    [-18.195, 1.068],
                    [-19.785, 0.864],
                    [-20.485, 0.12],
                    [-20.685, -1.536],
                    [-20.475, -3.24],
                    [-19.725, -4.008],
                    [-18.045, -4.224],
                    [-16.345, -4.008],
                    [-15.595, -3.228],
                    [-15.375, -1.488],
                    [-15.605, 0.348],
                    [-16.435, 1.968],
                    [-18.265, 4.176],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 10',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 10,
              ty: 'sh',
              ix: 11,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -0.312],
                    [-0.07, -0.176],
                    [-0.2, -0.12],
                    [-0.42, -0.12],
                    [-0.21, 0.112],
                    [-0.07, 0.168],
                    [0, 0.304],
                    [0.08, 0.152],
                    [0.21, 0.056],
                    [0.42, 0],
                    [0.21, -0.056],
                    [0.08, -0.152],
                  ],
                  o: [
                    [0, 0.312],
                    [0.08, 0.168],
                    [0.21, 0.112],
                    [0.42, -0.128],
                    [0.21, -0.112],
                    [0.08, -0.176],
                    [0, -0.312],
                    [-0.07, -0.152],
                    [-0.21, -0.056],
                    [-0.42, 0],
                    [-0.2, 0.056],
                    [-0.07, 0.144],
                  ],
                  v: [
                    [-26.645, -2.268],
                    [-26.535, -1.536],
                    [-26.115, -1.104],
                    [-25.165, -0.756],
                    [-24.215, -1.116],
                    [-23.795, -1.536],
                    [-23.675, -2.256],
                    [-23.795, -2.952],
                    [-24.215, -3.264],
                    [-25.165, -3.348],
                    [-26.115, -3.264],
                    [-26.535, -2.952],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 11',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 11,
              ty: 'sh',
              ix: 12,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -0.368],
                    [-0.09, -0.184],
                    [-0.23, -0.072],
                    [-0.48, 0],
                    [-0.23, 0.064],
                    [-0.09, 0.184],
                    [0, 0.384],
                    [0.09, 0.208],
                    [0.24, 0.152],
                    [0.49, 0.176],
                    [0.23, -0.152],
                    [0.09, -0.208],
                  ],
                  o: [
                    [0, 0.392],
                    [0.09, 0.184],
                    [0.23, 0.072],
                    [0.49, 0],
                    [0.24, -0.072],
                    [0.09, -0.184],
                    [0, -0.368],
                    [-0.09, -0.216],
                    [-0.23, -0.152],
                    [-0.48, 0.176],
                    [-0.23, 0.144],
                    [-0.09, 0.208],
                  ],
                  v: [
                    [-26.845, 1.932],
                    [-26.715, 2.796],
                    [-26.235, 3.18],
                    [-25.165, 3.288],
                    [-24.085, 3.192],
                    [-23.595, 2.808],
                    [-23.465, 1.956],
                    [-23.595, 1.092],
                    [-24.085, 0.54],
                    [-25.165, 0.048],
                    [-26.235, 0.54],
                    [-26.715, 1.068],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 12',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 12,
              ty: 'sh',
              ix: 13,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.77, 0],
                    [0.36, 0.12],
                    [0.14, 0.312],
                    [0, 0.656],
                    [-0.08, 0.224],
                    [-0.21, 0.184],
                    [-0.43, 0.24],
                    [0, 0],
                    [0.18, 0.16],
                    [0.08, 0.2],
                    [0, 0.336],
                    [-0.13, 0.264],
                    [-0.34, 0.104],
                    [-0.71, 0],
                    [-0.34, -0.104],
                    [-0.13, -0.272],
                    [0, -0.56],
                    [0.07, -0.2],
                    [0.19, -0.16],
                    [0.38, -0.2],
                    [0, 0],
                    [-0.21, -0.184],
                    [-0.08, -0.232],
                    [0, -0.384],
                    [0.15, -0.32],
                    [0.37, -0.12],
                  ],
                  o: [
                    [-0.77, 0],
                    [-0.37, -0.12],
                    [-0.14, -0.32],
                    [0, -0.392],
                    [0.08, -0.232],
                    [0.21, -0.192],
                    [0, 0],
                    [-0.38, -0.2],
                    [-0.19, -0.16],
                    [-0.06, -0.2],
                    [0, -0.56],
                    [0.13, -0.272],
                    [0.34, -0.104],
                    [0.71, 0],
                    [0.35, 0.104],
                    [0.13, 0.264],
                    [0, 0.336],
                    [-0.07, 0.2],
                    [-0.18, 0.16],
                    [0, 0],
                    [0.44, 0.248],
                    [0.21, 0.184],
                    [0.08, 0.232],
                    [0, 0.656],
                    [-0.13, 0.312],
                    [-0.38, 0.12],
                  ],
                  v: [
                    [-25.165, 4.176],
                    [-26.865, 3.996],
                    [-27.635, 3.348],
                    [-27.845, 1.884],
                    [-27.725, 0.96],
                    [-27.285, 0.336],
                    [-26.315, -0.312],
                    [-26.315, -0.384],
                    [-27.155, -0.924],
                    [-27.545, -1.464],
                    [-27.635, -2.268],
                    [-27.445, -3.504],
                    [-26.745, -4.068],
                    [-25.165, -4.224],
                    [-23.585, -4.068],
                    [-22.875, -3.504],
                    [-22.685, -2.268],
                    [-22.785, -1.464],
                    [-23.165, -0.924],
                    [-24.005, -0.384],
                    [-24.005, -0.312],
                    [-23.025, 0.336],
                    [-22.585, 0.96],
                    [-22.465, 1.884],
                    [-22.685, 3.348],
                    [-23.445, 3.996],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 13',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 13,
              ty: 'sh',
              ix: 14,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-34.985, 1.344],
                    [-32.275, -4.224],
                    [-31.185, -4.224],
                    [-33.955, 1.344],
                    [-33.955, 1.608],
                    [-31.705, 1.608],
                    [-31.705, -0.384],
                    [-30.745, -0.384],
                    [-30.745, 1.608],
                    [-29.585, 1.608],
                    [-29.585, 2.52],
                    [-30.745, 2.52],
                    [-30.745, 4.056],
                    [-31.705, 4.056],
                    [-31.705, 2.52],
                    [-34.985, 2.52],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 14',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [596.087, 6.7], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 92',
          np: 16,
          cix: 2,
          bm: 0,
          ix: 91,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [11.639, 1.116],
                    [10.415, -3.204],
                    [10.319, -3.204],
                    [9.095, 1.116],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [9.587, -4.08],
                    [11.147, -4.08],
                    [13.487, 4.08],
                    [12.467, 4.08],
                    [11.867, 2.016],
                    [8.855, 2.016],
                    [8.267, 4.08],
                    [7.247, 4.08],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.456, 0],
                    [0.44, 0.248],
                    [0.184, 0.6],
                    [0, 1.128],
                    [-0.176, 0.592],
                    [-0.424, 0.24],
                    [-0.792, 0],
                    [-0.344, -0.128],
                    [-0.168, -0.296],
                    [-0.048, -0.512],
                    [0, 0],
                    [0.224, 0.152],
                    [0.496, 0],
                    [0.28, -0.184],
                    [0.112, -0.456],
                    [0, -0.872],
                    [-0.12, -0.464],
                    [-0.288, -0.184],
                    [-0.552, 0],
                    [-0.208, 0.04],
                    [-0.232, 0.112],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.344, -0.08],
                  ],
                  o: [
                    [-0.816, 0],
                    [-0.44, -0.256],
                    [-0.184, -0.6],
                    [0, -1.096],
                    [0.176, -0.592],
                    [0.424, -0.248],
                    [0.584, 0],
                    [0.344, 0.128],
                    [0.176, 0.288],
                    [0, 0],
                    [-0.056, -0.408],
                    [-0.216, -0.16],
                    [-0.528, 0],
                    [-0.28, 0.184],
                    [-0.112, 0.448],
                    [0, 0.904],
                    [0.12, 0.464],
                    [0.296, 0.176],
                    [0.288, 0],
                    [0.216, -0.048],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.448, 0.224],
                    [-0.336, 0.08],
                  ],
                  v: [
                    [3.508, 4.2],
                    [1.624, 3.828],
                    [0.688, 2.544],
                    [0.412, -0.048],
                    [0.676, -2.58],
                    [1.576, -3.828],
                    [3.4, -4.2],
                    [4.792, -4.008],
                    [5.56, -3.372],
                    [5.896, -2.172],
                    [4.9, -2.172],
                    [4.48, -3.012],
                    [3.412, -3.252],
                    [2.2, -2.976],
                    [1.612, -2.016],
                    [1.444, -0.036],
                    [1.624, 2.016],
                    [2.236, 2.988],
                    [3.508, 3.252],
                    [4.252, 3.192],
                    [4.924, 2.952],
                    [4.924, 0.648],
                    [3.304, 0.648],
                    [3.304, -0.3],
                    [5.884, -0.3],
                    [5.884, 3.624],
                    [4.696, 4.08],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.288, 0.096],
                    [-0.12, 0.224],
                    [0, 0.416],
                    [0.136, 0.224],
                    [0.32, 0.088],
                    [0.6, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0.528, 0],
                    [0.296, -0.096],
                    [0.128, -0.232],
                    [0, -0.424],
                    [-0.128, -0.232],
                    [-0.312, -0.096],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.348, 0.012],
                    [-3.124, -0.132],
                    [-2.5, -0.612],
                    [-2.308, -1.584],
                    [-2.512, -2.556],
                    [-3.184, -3.036],
                    [-4.552, -3.18],
                    [-5.296, -3.18],
                    [-5.296, 0.012],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 4,
              ty: 'sh',
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.488, -0.152],
                    [-0.208, -0.368],
                    [0, -0.688],
                    [0.184, -0.36],
                    [0.424, -0.152],
                    [0.768, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0.92, 0],
                    [0.488, 0.152],
                    [0.208, 0.368],
                    [0, 0.64],
                    [-0.176, 0.352],
                    [-0.416, 0.152],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.756, -4.116],
                    [-2.644, -3.888],
                    [-1.6, -3.108],
                    [-1.288, -1.524],
                    [-1.564, -0.024],
                    [-2.464, 0.732],
                    [-4.24, 0.96],
                    [-5.296, 0.96],
                    [-5.296, 4.08],
                    [-6.328, 4.08],
                    [-6.328, -4.08],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 5',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 5,
              ty: 'sh',
              ix: 6,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-13.487, -4.08],
                    [-8.603, -4.08],
                    [-8.603, -3.132],
                    [-12.455, -3.132],
                    [-12.455, -0.48],
                    [-9.071, -0.48],
                    [-9.071, 0.468],
                    [-12.455, 0.468],
                    [-12.455, 4.08],
                    [-13.487, 4.08],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 6',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [13.923, 6.676], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 93',
          np: 8,
          cix: 2,
          bm: 0,
          ix: 92,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.362, 3.821],
                    [1.362, 0.004],
                    [-1.362, -3.821],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [51.937, 6.321], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 94',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 93,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.362, 3.821],
                    [1.362, 0.004],
                    [-1.362, -3.821],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [47.454, 6.576], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 95',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 94,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.362, 3.821],
                    [1.362, 0.004],
                    [-1.362, -3.821],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [42.257, 6.321], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 96',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 95,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0.36, 0.208],
                    [0, 0],
                    [0, 0],
                    [-0.35, 0.204],
                    [0, 0],
                    [0, 0],
                    [0, -0.408],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0.408],
                    [0, 0],
                    [0, 0],
                    [-0.35, -0.204],
                    [0, 0],
                    [0, 0],
                    [0.35, -0.204],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [3.58, -0.005],
                    [3.58, 3.466],
                    [2.78, 3.925],
                    [-0.22, 2.191],
                    [-3.23, 0.458],
                    [-3.23, -0.464],
                    [-0.22, -2.197],
                    [2.78, -3.93],
                    [3.58, -3.471],
                    [3.58, -0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [652.762, 6.329], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 97',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 96,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 1800,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'dot num Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 0.05 },
              o: { x: 0.5, y: 0.5 },
              t: 0,
              s: [300.04, 226.728, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 10,
              s: [300.04, 226.728, 0],
              to: [33.333, 0, 0],
              ti: [-33.333, 0, 0],
            },
            {
              i: { x: 0.05, y: 0.05 },
              o: { x: 0.5, y: 0.5 },
              t: 40,
              s: [500.04, 226.728, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 50,
              s: [500.04, 226.728, 0],
              to: [-63.333, 0, 0],
              ti: [63.333, 0, 0],
            },
            {
              i: { x: 0.05, y: 0.05 },
              o: { x: 0.5, y: 0.5 },
              t: 80,
              s: [120.04, 226.728, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 90,
              s: [120.04, 226.728, 0],
              to: [30, 0, 0],
              ti: [-30, 0, 0],
            },
            { t: 120, s: [300.04, 226.728, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [4.32, 4.32, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.715, 0],
                    [0, 1.716],
                    [1.716, 0],
                    [0, -1.716],
                  ],
                  o: [
                    [1.716, 0],
                    [0, -1.716],
                    [-1.715, 0],
                    [0, 1.716],
                  ],
                  v: [
                    [0, 3.107],
                    [3.107, 0],
                    [0, -3.107],
                    [-3.107, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [2.993, 4.635], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 87',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 244,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: 'Waves Container',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [320, 120, 0], ix: 2, l: 2 },
        a: { a: 0, k: [320, 120, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 640,
      h: 240,
      ip: 0,
      op: 1800,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
  chars: [
    {
      ch: '3',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.534, 0],
                      [-3.5, 1.4],
                      [-1.567, 3.2],
                      [0, 5.534],
                      [2.5, 2.967],
                      [6.066, 0],
                      [2.133, -0.133],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.334, 0],
                      [-1.967, -1.9],
                      [0, -4.4],
                      [1.033, -2.133],
                      [2.366, -0.933],
                      [4.133, 0],
                      [2.533, 0.667],
                      [3.466, 1.667],
                      [0, 0],
                      [-2.534, -0.5],
                    ],
                    o: [
                      [6.133, 0],
                      [3.5, -1.4],
                      [1.566, -3.2],
                      [0, -7.066],
                      [-2.5, -2.966],
                      [-0.8, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.866, -0.533],
                      [4.666, 0],
                      [1.966, 1.9],
                      [0, 3.734],
                      [-1.034, 2.134],
                      [-2.367, 0.934],
                      [-2.8, 0],
                      [-2.534, -0.666],
                      [0, 0],
                      [3, 1.267],
                      [2.533, 0.5],
                    ],
                    v: [
                      [24.9, 0.9],
                      [39.35, -1.2],
                      [46.95, -8.1],
                      [49.3, -21.2],
                      [45.55, -36.25],
                      [32.7, -40.7],
                      [28.3, -40.5],
                      [28.1, -40.9],
                      [46.7, -58.5],
                      [46.7, -68],
                      [8.9, -68],
                      [8.9, -60.6],
                      [37.7, -60.6],
                      [37.7, -60.3],
                      [18.7, -42.4],
                      [18.7, -33],
                      [28, -33.8],
                      [37.95, -30.95],
                      [40.9, -21.5],
                      [39.35, -12.7],
                      [34.25, -8.1],
                      [24.5, -6.7],
                      [16.5, -7.7],
                      [7.5, -11.2],
                      [7.5, -2.5],
                      [15.8, 0.15],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '3',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '3',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '1',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [32.1, 0],
                      [40.5, 0],
                      [40.5, -68],
                      [30.9, -68],
                      [10.6, -53.4],
                      [10.6, -44],
                      [25.3, -53.3],
                      [32.1, -59],
                      [32.7, -59],
                      [32.1, -48.8],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '2',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [8.733, -7.466],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.667, 3.567],
                      [-4.867, 5.134],
                      [-0.934, 2.134],
                      [0, 3.067],
                      [1.266, 2.734],
                      [3.033, 1.134],
                      [5.6, 0],
                      [3, -1.033],
                      [1.433, -2.433],
                      [0.4, -4.533],
                      [0, 0],
                      [-0.867, 1.367],
                      [-1.8, 0.567],
                      [-3.2, 0],
                      [-1.8, -0.666],
                      [-0.767, -1.633],
                      [0, -2.933],
                      [0.533, -1.366],
                      [1.466, -1.733],
                      [4.133, -3.833],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.333, -4.6],
                      [3.666, -3.566],
                      [2.6, -2.8],
                      [0.933, -2.133],
                      [0, -5],
                      [-1.267, -2.733],
                      [-3.034, -1.133],
                      [-5.4, 0],
                      [-3, 1.034],
                      [-1.434, 2.434],
                      [0, 0],
                      [0.266, -2.533],
                      [0.866, -1.366],
                      [1.8, -0.566],
                      [3.333, 0],
                      [1.8, 0.667],
                      [0.766, 1.634],
                      [0, 1.934],
                      [-0.534, 1.367],
                      [-2.734, 3.467],
                      [-4.134, 3.834],
                    ],
                    v: [
                      [8.7, -12.6],
                      [8.7, 0],
                      [51.5, 0],
                      [51.5, -8],
                      [17.6, -8],
                      [17.6, -9.4],
                      [31.1, -21.65],
                      [43.9, -34.7],
                      [49.2, -42.1],
                      [50.6, -49.9],
                      [48.7, -61.5],
                      [42.25, -67.3],
                      [29.3, -69],
                      [16.7, -67.45],
                      [10.05, -62.25],
                      [7.3, -51.8],
                      [16.2, -51.8],
                      [17.9, -57.65],
                      [21.9, -60.55],
                      [29.4, -61.4],
                      [37.1, -60.4],
                      [40.95, -56.95],
                      [42.1, -50.1],
                      [41.3, -45.15],
                      [38.3, -40.5],
                      [28, -29.55],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '2',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '9',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [5.8, -5.8],
                      [0, 0],
                      [-3.334, 4.734],
                      [-1.267, 4.267],
                      [0, 5.934],
                      [1.166, 3.134],
                      [3.066, 1.2],
                      [6.333, 0],
                      [3.033, -1.166],
                      [1.166, -3.1],
                      [0, -6.4],
                      [-1.067, -3],
                      [-2.867, -1.133],
                      [-5.934, 0],
                      [-1.6, 0.6],
                      [-1.534, 1.934],
                      [0, 0],
                      [3.1, -3.8],
                    ],
                    o: [
                      [0, 0],
                      [6.866, -7.533],
                      [3.333, -4.733],
                      [1.266, -4.266],
                      [0, -6.533],
                      [-1.167, -3.133],
                      [-3.067, -1.2],
                      [-6.267, 0],
                      [-3.034, 1.167],
                      [-1.167, 3.1],
                      [0, 6.2],
                      [1.066, 3],
                      [2.866, 1.134],
                      [3.533, 0],
                      [1.6, -0.6],
                      [0, 0],
                      [-4.4, 6.734],
                      [-3.1, 3.8],
                    ],
                    v: [
                      [16.3, 1],
                      [27.4, 1],
                      [42.7, -17.4],
                      [49.6, -30.9],
                      [51.5, -46.2],
                      [49.75, -60.7],
                      [43.4, -67.2],
                      [29.3, -69],
                      [15.35, -67.25],
                      [9.05, -60.85],
                      [7.3, -46.6],
                      [8.9, -32.8],
                      [14.8, -26.6],
                      [28, -24.9],
                      [35.7, -25.8],
                      [40.4, -29.6],
                      [40.9, -29.2],
                      [29.65, -13.4],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '9',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 4.134],
                      [-0.734, 2.134],
                      [-1.9, 0.8],
                      [-3.934, 0],
                      [-1.934, -0.766],
                      [-0.767, -1.966],
                      [0, -4.066],
                      [0.7, -2.133],
                      [1.966, -0.8],
                      [4.133, 0],
                      [1.9, 0.767],
                      [0.7, 2],
                    ],
                    o: [
                      [0, -4.4],
                      [0.733, -2.133],
                      [1.9, -0.8],
                      [3.933, 0],
                      [1.933, 0.767],
                      [0.766, 1.967],
                      [0, 4.534],
                      [-0.7, 2.134],
                      [-1.967, 0.8],
                      [-3.867, 0],
                      [-1.9, -0.766],
                      [-0.7, -2],
                    ],
                    v: [
                      [15.5, -46.5],
                      [16.6, -56.3],
                      [20.55, -60.7],
                      [29.3, -61.9],
                      [38.1, -60.75],
                      [42.15, -56.65],
                      [43.3, -47.6],
                      [42.25, -37.6],
                      [38.25, -33.2],
                      [29.1, -32],
                      [20.45, -33.15],
                      [16.55, -37.3],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '9',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '9',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
  ],
}

import Default from './Default'
import { qs, qsa } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { Background } from '../components'
import {
  Header,
  Hero,
  Unlimited,
  Foundation,
  Intro,
  Company,
  Press,
  Feed,
  Signup,
  Footer,
  IRX,
  LargeTitle,
  Split,
  Marquee,
  Message,
  ProductInfo,
  Tunnel,
  Statement,
  Team,
  HeroGlobe,
  Forms,
  HeroNews,
  HeroTitle,
  Paragraph,
  Faqs,
  Contacts,
} from '../modules'
import gsap from 'gsap'

export default class Page extends Default {
  constructor(opt = {}) {
    super(opt)
  }

  setup() {
    super.setup()

    Object.assign(this.dom, {
      bg: qs('.site-background-inner'),
      bgs: qsa('[data-bg]'),
    })
  }

  components() {
    super.components()
    this.addBGUpdate()

    this.modules = []
    const modules = qsa('.module')

    if (modules) {
      for (let i = 0; i < modules.length; i++) {
        const id = modules[i].id
        const object = {
          el: modules[i],
          name: id,
        }

        switch (id) {
          case 'header':
            object.module = new Header(object)
            break
          case 'hero':
            object.module = new Hero(object)
            break
          case 'unlimited':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Unlimited(object)
            break
          case 'foundation':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Foundation(object)
            break
          case 'intro':
            object.splitText = true
            object.module = new Intro(object)
            break
          case 'company':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Company(object)
            break
          case 'press':
            object.splitText = true
            object.module = new Press(object)
            break
          case 'feed':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Feed(object)
            break
          case 'signup':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Signup(object)
            break
          case 'footer':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Footer(object)
            break
          case 'irxhonu':
            object.splitText = true
            object.reverseSplit = true
            object.module = new IRX(object)
            break
          case 'largetitle':
            object.splitText = true
            object.reverseSplit = true
            object.module = new LargeTitle(object)
            break
          case 'split':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Split(object)
            break
          case 'marquee':
            object.module = new Marquee(object)
            break
          case 'message':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Message(object)
            break
          case 'productinfo':
            object.splitText = true
            object.reverseSplit = true
            object.module = new ProductInfo(object)
            break
          case 'tunnel':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Tunnel(object)
            break
          case 'statement':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Statement(object)
            break
          case 'team':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Team(object)
            break
          case 'globe':
            object.module = new HeroGlobe(object)
            break
          case 'form':
            object.module = new Forms(object)
            break
          case 'newshero':
            object.splitText = true
            object.reverseSplit = true
            object.module = new HeroNews(object)
            break
          case 'title':
            object.splitText = true
            object.reverseSplit = true
            object.module = new HeroTitle(object)
            break
          case 'paragraph':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Paragraph(object)
            break
          case 'faq':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Faqs(object)
            break
          case 'contact':
            object.splitText = true
            object.reverseSplit = true
            object.module = new Contacts(object)
            break
        }

        this.modules.push(object)
      }
    }
  }

  addBGUpdate() {
    const { bgs } = this.dom

    this.bg = new Background({
      container: qs('.site-background'),
      canvas: qs('.-bg'),
    })

    bgs.forEach((bg) => {
      ScrollTrigger.create({
        trigger: bg,
        start: 'top top+=50%',
        end: 'bottom bottom-=50%',
        scrub: true,
        onEnter: () => {
          this.updadeBg(bg.dataset.bg)
        },
        onEnterBack: () => {
          this.updadeBg(bg.dataset.bg)
        },
      })
    })
  }

  updadeBg(color) {
    if (color === 'dark') {
      gsap.to('html', { duration: 0.5, '--cardBackground': '#000' })
      gsap.to('html', { duration: 0.5, '--background': '#B7B7B7' })
      gsap.to('html', { duration: 0.5, '--textPositive': '#B7B7B7' })
      if (this.bg)
        gsap.to(this.bg.uniforms.uProgress, { duration: 1, value: 1.6 })
    }
    if (color === 'light') {
      gsap.to('html', { duration: 0.5, '--cardBackground': '#B7B7B7' })
      gsap.to('html', { duration: 0.5, '--background': '#000' })
      gsap.to('html', { duration: 0.5, '--textPositive': '#000' })
      if (this.bg)
        gsap.to(this.bg.uniforms.uProgress, { duration: 1, value: -0.01 })
    }
    if (color === 'black') {
      gsap.to('html', { duration: 0.5, '--cardBackground': '#000' })
      gsap.to('html', { duration: 0.5, '--background': '#000' })
      gsap.to('html', { duration: 0.5, '--textPositive': '#B7B7B7' })
      if (this.bg)
        gsap.to(this.bg.uniforms.uProgress, { duration: 1, value: -0.01 })
    }
    if (color === 'none') {
      gsap.to('html', { duration: 0.5, '--cardBackground': '#000' })
      gsap.to('html', { duration: 0.5, '--background': '#000' })
      gsap.to('html', { duration: 0.5, '--textPositive': '#000' })
      if (this.bg)
        gsap.to(this.bg.uniforms.uProgress, { duration: 1, value: -0.01 })
    }
    if (color === 'yellow') {
      gsap.to('html', { duration: 0.5, '--cardBackground': '#000' })
      gsap.to('html', { duration: 0.5, '--background': '#000' })
      gsap.to('html', { duration: 0.5, '--textPositive': '#000' })
      if (this.bg)
        gsap.to(this.bg.uniforms.uProgress, { duration: 1, value: -0.01 })
    }
  }

  run() {
    super.run()

    const scroll = this.scroll
    const { x, y } = this.mouse

    this.modules &&
      this.modules.forEach((item) => {
        if (item.name === 'header') item.module.run(scroll.current)
        if (item.name === 'intro') item.module.run()
        if (item.name === 'irxhonu') item.module.run(x, y)
        if (item.name === 'marquee') item.module.run()
        if (item.name === 'tunnel') item.module.run()
      })
  }

  onResize() {
    super.onResize()

    this.modules &&
      this.modules.forEach((item) => {
        item.module && item.module.resize()
      })
  }
  onLeave() {
    super.onLeave()
    this.updadeBg('black')

    this.modules &&
      this.modules.forEach((item) => {
        item.module && item.module.destroy()
      })

    this.stickys = []
    setTimeout(() => {
      this.bg && this.bg.destroy()
    }, 1000)
  }
}

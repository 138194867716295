import store from '../store'
import Module from './module'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Header extends Module {
  constructor(obj) {
    super(obj)

    this.state = {
      menu: {
        animate: false,
        hidden: false,
      },
    }
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      logo: qs('.header-logo', el),
      menuItems: qsa('.menu-item', el),
      menuCta: qs('.menu-cta', el),
      mobileOpen: qs('.mobile-open', el),
    })

    this.setAnimation()
  }

  setAnimation() {
    const { el, logo, menuItems, menuCta, mobileOpen } = this.dom

    gsap.set(el, { duration: 0.5, y: 0 })

    this.tl = gsap.timeline({ paused: true })
    this.tl.fromTo(el, { autoAlpha: 0 }, { autoAlpha: 1 })
    this.tl.from(logo, {
      duration: 0.45,
      autoAlpha: 0,
      y: '-15rem',
    })
    this.tl.from(
      menuItems,
      { duration: 0.45, autoAlpha: 0, y: '-15rem', stagger: -0.05 },
      '-=0.4',
    )
    this.tl.from(
      menuCta,
      { duration: 0.45, autoAlpha: 0, y: '-15rem' },
      '-=0.4',
    )
    this.tl.from(
      mobileOpen,
      { duration: 0.45, autoAlpha: 0, y: '-15rem' },
      '-=0.6',
    )

    this.tl.play()
  }

  run(current) {
    const { h, vh } = store.sizes
    const { animate, hidden } = this.state.menu
    const { el } = this.dom

    if (current > h - vh) {
      if (animate || hidden) return
      this.state.menu.animate = true
      this.state.menu.hidden = true
      gsap.to(el, {
        duration: 0.5,
        yPercent: -100,
        onComplete: () => {
          this.state.menu.animate = false
        },
      })
    } else {
      if (hidden && !animate) {
        this.state.menu.animate = true
        gsap.to(el, {
          duration: 0.5,
          yPercent: 0,
          onComplete: () => {
            this.state.menu.animate = false
            this.state.menu.hidden = false
          },
        })
      }
    }
  }

  destroy() {
    const { el } = this.dom
    //console.log('header destroy')
    gsap.set(el, { autoAlpha: 0, yPercent: 0 })
  }
}

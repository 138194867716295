import store from '../store'
import * as THREE from 'three'
import WebGL from './WebGL'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { Emitter } from '../core'
import { bounds } from '../utils'
import gsap from 'gsap'

export default class PressGL extends WebGL {
  constructor(obj) {
    super(obj)
  }

  setup() {
    super.setup()

    const loader = new GLTFLoader()

    Object.assign(this.bgContext, { loader })
  }

  setCamera() {
    const { scene, canvas } = this.bgContext
    const { width, height } = this.sizes
    const camera = new THREE.OrthographicCamera(
      width / -2, // left
      width / 2, // right
      height / 2, // top
      height / -2, // bottom
      0.1, // near
      1000, // far
    )
    const controls = new OrbitControls(camera, canvas)

    camera.position.set(0, 45, 500)
    camera.lookAt(0, 0, 0)
    controls.target.set(0, 0, 0)
    controls.enableDamping = true
    controls.enabled = true
    scene.add(camera)

    Object.assign(this.bgContext, { camera, controls })
  }

  loadModel() {
    const { sniff } = store
    const { width, height } = this.sizes
    const { loader, scene, mesh, textureLoader } = this.bgContext
    const aspectRatio = width / height

    const assets = ['/models/sphere.glb', '/models/landscape.glb']
    const totalAssets = assets.length
    let loadedAssets = 0

    const grey = new THREE.MeshStandardMaterial({
      color: new THREE.Color('white'),
      emissive: new THREE.Color('#D9D9D9'),
    })

    const black = new THREE.MeshStandardMaterial({
      color: new THREE.Color('#010101'),
    })

    const models = []
    const size = sniff.isDesktop ? 145 : 100

    assets.forEach((asset, index) => {
      loader.load(asset, (gltf) => {
        loadedAssets++
        const model = gltf.scene
        model.scale.set(size, size, size)
        mesh.add(model)
        models.push(model)
        model.doubleSided = true

        mesh.traverse((object) => {
          if (object.material && object.name == 'sphere-lines') {
            object.material.dispose()
            object.material = black
          }
          if (object.material && object.name == 'sphere') {
            object.material.dispose()
            object.material = grey
          }
          if (object.material && object.name == 'landscape-lines') {
            object.material.dispose()
            object.material = black
          }
          if (object.material && object.name == 'landscape-solid') {
            object.material.dispose()
            object.material = grey
          }
        })

        if (loadedAssets === totalAssets) {
          Object.assign(this.bgContext, { models: models })
          this.updateMesh(0)
        }
      })
    })
  }

  setMesh() {
    const { scene, model } = this.bgContext
    const mesh = new THREE.Object3D()
    scene.add(mesh)

    Object.assign(this.bgContext, { mesh })
  }

  updateMesh(index) {
    const { models } = this.bgContext
    models.forEach((model) => (model.visible = false))
    models[index].visible = true
    gsap.from(models[index].scale, {
      duration: 0.8,
      x: 0,
      y: 0,
      z: 0,
      ease: 'power3.out',
    })
  }

  update() {
    const { models, mesh, scene } = this.bgContext

    if (models && models.length > 0) {
      models.forEach((model) => {
        if (model) model.rotation.y += 0.005
      })
    }
  }

  updateSize() {}

  setResize() {
    const { sizes } = store
    const { container, camera, renderer } = this.bgContext
    const rect = bounds(container)
    const width = rect.width
    const height = rect.height

    camera.left = width / -2
    camera.right = width / 2
    camera.top = height / 2
    camera.bottom = height / -2
    camera.aspect = width / height
    camera.updateProjectionMatrix()

    renderer.setSize(width, height)
    renderer.setPixelRatio(2)
  }

  on() {
    super.on()
    Emitter.on('updateTab', (index) => this.updateMesh(index))
  }

  init() {
    super.init()
    this.loadModel()
  }
}

import store from '../store'
import { Emitter } from '../core'
import { supportMouseTouch } from '../utils/'
import Lethargy from 'lethargy'
const EVT_ID = 'virtualscroll'

export default class VirtualScroll {
  constructor(obj = {}) {
    this.obj = {}
    this.keyCodes = {
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      DOWN: 40,
      SPACE: 32,
    }

    this.el = window

    if (obj && obj.el) {
      this.el = obj.el
      delete obj.el
    }

    Object.assign(
      this.obj,
      {
        mouseMultiplier: 1,
        touchMultiplier: 2,
        firefoxMultiplier: 15,
        keyStep: 120,
        preventTouch: false,
        unpreventTouchClass: 'vs-touchmove-allowed',
        limitInertia: false,
        useKeyboard: true,
        useTouch: true,
      },
      obj,
    )

    if (this.obj.limitInertia) this.lethargy = new Lethargy()

    this.emitter = Emitter
    this.event = {
      y: 0,
      x: 0,
      deltaX: 0,
      deltaY: 0,
    }
    this.touchStartX = null
    this.touchStartY = null
    this.bodyTouchAction = null

    if (this.obj.passive !== undefined) {
      this.listenerOptions = { passive: this.obj.passive }
    }
  }

  notify(e) {
    var evt = this.event
    evt.x += evt.deltaX
    evt.y += evt.deltaY

    this.emitter.emit(EVT_ID, {
      x: evt.x,
      y: evt.y,
      deltaX: evt.deltaX,
      deltaY: evt.deltaY,
      originalEvent: e,
    })
  }

  onWheel = (e) => {
    var options = this.obj
    if (this.lethargy && this.lethargy.check(e) === false) return
    var evt = this.event

    // In Chrome and in Firefox (at least the new one)
    evt.deltaX = e.wheelDeltaX || e.deltaX * -1
    evt.deltaY = e.wheelDeltaY || e.deltaY * -1

    // for our purpose deltamode = 1 means user is on a wheel mouse, not touch pad
    // real meaning: https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent#Delta_modes
    if (supportMouseTouch.isFirefox && e.deltaMode == 1) {
      evt.deltaX *= options.firefoxMultiplier
      evt.deltaY *= options.firefoxMultiplier
    }

    evt.deltaX *= options.mouseMultiplier
    evt.deltaY *= options.mouseMultiplier

    this.notify(e)
  }

  onMouseWheel = (e) => {
    if (this.obj.limitInertia && this.lethargy.check(e) === false) return

    var evt = this.event

    // In Safari, IE and in Chrome if 'wheel' isn't defined
    evt.deltaX = e.wheelDeltaX ? e.wheelDeltaX : 0
    evt.deltaY = e.wheelDeltaY ? e.wheelDeltaY : e.wheelDelta

    this.notify(e)
  }

  onTouchStart = (e) => {
    var t = e.targetTouches ? e.targetTouches[0] : e
    this.touchStartX = t.pageX
    this.touchStartY = t.pageY
  }

  onTouchMove = (e) => {
    var options = this.obj
    if (
      options.preventTouch &&
      !e.target.classList.contains(options.unpreventTouchClass)
    ) {
      e.preventDefault()
    }

    var evt = this.event

    var t = e.targetTouches ? e.targetTouches[0] : e

    evt.deltaX = (t.pageX - this.touchStartX) * options.touchMultiplier
    evt.deltaY = (t.pageY - this.touchStartY) * options.touchMultiplier

    this.touchStartX = t.pageX
    this.touchStartY = t.pageY

    this.notify(e)
  }

  onKeyDown = (e) => {
    const { keyCodes } = this

    var evt = this.event
    evt.deltaX = evt.deltaY = 0
    var windowHeight = window.innerHeight - 40

    if (store.focus) return

    switch (e.keyCode) {
      case keyCodes.LEFT:
      case keyCodes.UP:
        evt.deltaY = this.obj.keyStep
        break

      case keyCodes.RIGHT:
      case keyCodes.DOWN:
        evt.deltaY = -this.obj.keyStep
        break
      case keyCodes.SPACE && e.shiftKey:
        evt.deltaY = windowHeight
        break
      case keyCodes.SPACE:
        evt.deltaY = -windowHeight
        break
      default:
        return
    }

    this.notify(e)
  }

  bind = () => {
    const support = supportMouseTouch()

    if (support.hasWheelEvent) {
      this.el.addEventListener('wheel', this.onWheel, this.listenerOptions)
    }

    if (support.hasMouseWheelEvent)
      this.el.addEventListener(
        'mousewheel',
        this.onMouseWheel,
        this.listenerOptions,
      )

    if (support.hasTouch && this.obj.useTouch) {
      this.el.addEventListener(
        'touchstart',
        this.onTouchStart,
        this.listenerOptions,
      )
      this.el.addEventListener(
        'touchmove',
        this.onTouchMove,
        this.listenerOptions,
      )
    }

    if (support.hasPointer && support.hasTouchWin) {
      this.bodyTouchAction = document.body.style.msTouchAction
      document.body.style.msTouchAction = 'none'
      this.el.addEventListener('MSPointerDown', this.onTouchStart, true)
      this.el.addEventListener('MSPointerMove', this.onTouchMove, true)
    }

    if (support.hasKeyDown && this.obj.useKeyboard)
      document.addEventListener('keydown', this.onKeyDown)
  }

  unbind = () => {
    const support = supportMouseTouch()

    if (support.hasWheelEvent)
      this.el.removeEventListener('wheel', this.onWheel)
    if (support.hasMouseWheelEvent)
      this.el.removeEventListener('mousewheel', this.onMouseWheel)

    if (support.hasTouch) {
      this.el.removeEventListener('touchstart', this.onTouchStart)
      this.el.removeEventListener('touchmove', this.onTouchMove)
    }

    if (support.hasPointer && support.hasTouchWin) {
      document.body.style.msTouchAction = this.bodyTouchAction
      this.el.removeEventListener('MSPointerDown', this.onTouchStart, true)
      this.el.removeEventListener('MSPointerMove', this.onTouchMove, true)
    }

    if (support.hasKeyDown && this.obj.useKeyboard)
      document.removeEventListener('keydown', this.onKeyDown)
  }

  on = (cb, ctx) => {
    this.emitter.on(EVT_ID, cb, ctx)

    var events = this.emitter.e
    if (events && events[EVT_ID] && events[EVT_ID].length === 1) this.bind()
  }

  off = (cb, ctx) => {
    this.emitter.off(EVT_ID, cb, ctx)

    var events = this.emitter.e
    if (!events[EVT_ID] || events[EVT_ID].length <= 0) this.unbind()
  }

  reset = () => {
    var evt = this.event
    evt.x = 0
    evt.y = 0
  }

  destroy = () => {
    this.emitter.off()
    this.unbind()
  }
}

export const hole = {
  v: '5.12.1',
  fr: 30,
  ip: 420,
  op: 600,
  w: 480,
  h: 480,
  nm: 'L - Blackhole',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'Line 13',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Machines',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 317, 0],
                  t: 197,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.25, 0],
                  t: 198,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.345, 0],
                  t: 199,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.717, 0],
                  t: 200,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.998, 0],
                  t: 201,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 244.175, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.885, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.636, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 195.839, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 180.817, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.799, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 153.905, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 142.615, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.605, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.938, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 141.241, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 149.558, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 158.494, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 167.663, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 177.238, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 187.096, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 196.698, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.62, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.555, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.311, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 225.811, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.097, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.332, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 235.657, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 236.963, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.179, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 236.296, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.368, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 231.512, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.904, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.779, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.431, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.21, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.51, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.778, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.796, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.575, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.373, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.608, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 189.639, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 184.772, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 180.263, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 176.323, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.138, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 171.341, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 171.415, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.396, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 177.254, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.89, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 190.155, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 198.821, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.616, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.198, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.173, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.087, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.432, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.658, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.523, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 275.009, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.039, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.519, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.345, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.393, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.519, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.548, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.403, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.614, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.692, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.128, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.386, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.918, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.15, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.485, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.299, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.941, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 198.719, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 198.915, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.772, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.42, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.82, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.13, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.502, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 224.015, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 228.672, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.403, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.058, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.427, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.804, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.44, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.403, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.745, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.499, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.683, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.315, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.384, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 294.895, 0],
                  t: 303,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.815, 0],
                  t: 304,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.133, 0],
                  t: 305,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 306,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.783, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.234, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.319, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 275.475, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.178, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.956, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.389, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.785, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.261, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.343, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.456, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.983, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.25, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.539, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.076, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.033, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.536, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.655, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.408, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 277.762, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.668, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.195, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.128, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-10, -4.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'MACHINES',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [0],
                    t: 197,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.355],
                    t: 198,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.493],
                    t: 199,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [22.858],
                    t: 200,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.814],
                    t: 201,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.366],
                    t: 202,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.17],
                    t: 203,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.953],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [65.492],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [73.61],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.187],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [88.156],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [94.257],
                    t: 209,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.126],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [100],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [100],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [98.952],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [95],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [90.507],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [85.675],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [80.721],
                    t: 221,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [75.546],
                    t: 222,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.217],
                    t: 223,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [65.027],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.204],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.916],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.264],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [49.291],
                    t: 228,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.975],
                    t: 229,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.227],
                    t: 230,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.97],
                    t: 231,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.264],
                    t: 232,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.147],
                    t: 233,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.624],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.666],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.21],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.16],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.39],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.74],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.021],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.021],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.498],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.569],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.311],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.581],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [66.156],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [68.842],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [71.474],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [73.911],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [76.039],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.762],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [78.731],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [78.691],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.622],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [75.538],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [72.49],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [68.565],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.879],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.585],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.865],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.934],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.035],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.443],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.457],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.206],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [22.7],
                    t: 266,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.982],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.1],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [17.111],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [17.085],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.1],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.247],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.568],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.779],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.6],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.77],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.035],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.153],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.892],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.034],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.378],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.735],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.935],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.828],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.284],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.773],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.394],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.064],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.701],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.262],
                    t: 290,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [47.745],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.188],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.672],
                    t: 293,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.945],
                    t: 295,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.439],
                    t: 296,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.756],
                    t: 297,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.869],
                    t: 298,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.76],
                    t: 299,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.416],
                    t: 300,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.833],
                    t: 301,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.01],
                    t: 302,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.953],
                    t: 303,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.671],
                    t: 304,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.178],
                    t: 305,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 306,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 329,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.363],
                    t: 330,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.148],
                    t: 331,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.588],
                    t: 332,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [22.449],
                    t: 333,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.473],
                    t: 334,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.375],
                    t: 335,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.845],
                    t: 336,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.712],
                    t: 337,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.535],
                    t: 338,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.573],
                    t: 339,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.592],
                    t: 340,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.389],
                    t: 341,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.784],
                    t: 342,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.628],
                    t: 343,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.798],
                    t: 344,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.2],
                    t: 345,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.766],
                    t: 346,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.459],
                    t: 347,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.268],
                    t: 348,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.213],
                    t: 349,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.319],
                    t: 350,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.546],
                    t: 351,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1.015],
                    t: 352,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 353,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      nm: 'Line 12',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Crunching',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 317, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.624, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 308.785, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.152, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.648, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.242, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.906, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.675, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.593, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.72, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.136, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 244.945, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.256, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.192, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.893, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.128, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.393, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.728, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 209.369, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.53, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 195.419, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 188.21, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 181.077, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.169, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 167.622, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.552, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 156.052, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 151.222, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.12, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 143.809, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 141.318, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 139.681, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.889, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.95, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 139.837, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 141.51, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 143.922, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.004, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 150.677, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 154.839, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 159.372, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.143, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 169.034, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.898, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 178.573, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.97, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 187.288, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 191.476, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 195.466, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.184, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.592, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.651, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.336, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.634, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.543, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.068, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.224, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.033, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.523, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.692, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.453, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.062, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.567, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.473, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.978, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.588, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.352, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.53, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.027, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.847, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.015, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 217.558, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.377, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.03, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.555, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 224.046, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 225.583, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.237, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.067, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 231.122, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.443, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 236.061, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.997, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.266, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.878, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.831, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.118, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.726, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.644, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.843, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.301, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.987, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.862, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.893, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.047, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 304.27, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.531, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.781, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 326,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.976, 0],
                  t: 327,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.435, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.009, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.825, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.005, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.704, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 277.073, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.276, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.488, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.885, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.316, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.367, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.03, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.48, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.876, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.357, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.069, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.118, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.617, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.658, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.321, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.667, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.748, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.599, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.24, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.682, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.916, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 284.92, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.647, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.057, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.074, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.611, 0],
                  t: 358,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 359,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-21, -3.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'CRUNCHING',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 317, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.624, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 308.785, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.152, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.648, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.242, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.906, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.675, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.593, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.72, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.136, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 244.945, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.256, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.192, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.893, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.128, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.393, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.728, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 209.369, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.53, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 195.419, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 188.21, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 181.077, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.169, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 167.622, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.552, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 156.052, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 151.222, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.12, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 143.809, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 141.318, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 139.681, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.889, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.95, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 139.837, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 141.51, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 143.922, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.004, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 150.677, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 154.839, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 159.372, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.143, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 169.034, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.898, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 178.573, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.97, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 187.288, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 191.476, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 195.466, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.184, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.592, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.651, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.336, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.634, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.543, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.068, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.224, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.033, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.523, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.692, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.453, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.062, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.567, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.473, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.978, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.588, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.352, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.53, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.027, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.847, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.015, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 217.558, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.377, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.03, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.555, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 224.046, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 225.583, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.237, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.067, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 231.122, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.443, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 236.061, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.997, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.266, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.878, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.831, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.118, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.726, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.644, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.843, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.301, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.987, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.862, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.893, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.047, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 304.27, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.531, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.781, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 326,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.976, 0],
                  t: 327,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.435, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.009, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.825, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.005, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.704, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 277.073, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.276, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.488, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.885, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.316, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.367, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.03, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.48, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.876, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.357, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.069, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.118, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.617, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.658, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.321, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.667, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.748, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.599, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.24, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.682, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.916, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 284.92, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.647, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.057, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.074, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.611, 0],
                  t: 358,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 359,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-197.873, -211.857, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.215686276555, 0.215686276555, 0.215686276555, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-197.873, -223.357], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.215686276555, 0.215686276555, 0.215686276555, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [0],
                    t: 202,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.21],
                    t: 203,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.446],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.569],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [12.623],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.63],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.593],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.501],
                    t: 209,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.33],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.045],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.603],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.949],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.025],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.761],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [47.085],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [49.12],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.679],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.741],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.179],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.874],
                    t: 221,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [65.719],
                    t: 222,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [69.615],
                    t: 223,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [73.47],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.203],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [80.743],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [84.025],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [86.995],
                    t: 228,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [89.607],
                    t: 229,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [91.822],
                    t: 230,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [93.613],
                    t: 231,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [94.958],
                    t: 232,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [95.845],
                    t: 233,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [96.27],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [96.238],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [95.759],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [94.854],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [93.551],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [91.885],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [89.9],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [87.651],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [85.204],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [82.623],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [79.978],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.35],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [74.823],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [72.447],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.114],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [67.849],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [65.694],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.683],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.841],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.188],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.737],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.494],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.463],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.638],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.014],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.577],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.312],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.2],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.22],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.349],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.561],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.828],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.42],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.687],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.898],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.025],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.044],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.929],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.66],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.218],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.586],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.753],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.769],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.876],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.051],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.246],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [49.415],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.521],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [47.532],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.421],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.166],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.752],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.165],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.397],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.445],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.309],
                    t: 290,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.992],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.5],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.842],
                    t: 293,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.032],
                    t: 294,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.082],
                    t: 295,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.01],
                    t: 296,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.834],
                    t: 297,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.573],
                    t: 298,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.249],
                    t: 299,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.883],
                    t: 300,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.499],
                    t: 301,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.119],
                    t: 302,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 303,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 326,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [2.72],
                    t: 327,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.253],
                    t: 328,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [9.726],
                    t: 329,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.072],
                    t: 330,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.216],
                    t: 331,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.082],
                    t: 332,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.585],
                    t: 333,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.637],
                    t: 334,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.144],
                    t: 335,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.009],
                    t: 336,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.318],
                    t: 337,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.831],
                    t: 338,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.553],
                    t: 339,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.391],
                    t: 340,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.258],
                    t: 341,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.077],
                    t: 342,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.776],
                    t: 343,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.288],
                    t: 344,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.559],
                    t: 345,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.536],
                    t: 346,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.179],
                    t: 347,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.452],
                    t: 348,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.327],
                    t: 349,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.786],
                    t: 350,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.817],
                    t: 351,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.416],
                    t: 352,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.587],
                    t: 353,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [17.343],
                    t: 354,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.705],
                    t: 355,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [9.7],
                    t: 356,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.367],
                    t: 357,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.752],
                    t: 358,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 359,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      nm: 'Line 11',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Number',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 315.98, 0],
                  t: 180,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 181,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 201,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.93, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.517, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.503, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.749, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.156, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.636, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.663, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.188, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.728, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.306, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.957, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 244.726, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.662, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.826, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.279, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.089, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.33, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.077, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.409, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.409, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.158, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.92, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 209.959, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 207.411, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.396, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.026, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 197.393, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 193.586, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 189.677, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 185.727, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 181.785, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 177.89, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.064, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 170.333, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.697, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.266, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.091, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.243, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 154.739, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.626, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 150.939, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 149.713, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 148.953, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 148.697, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 148.946, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 149.713, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 150.976, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.743, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 154.996, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.708, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.85, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.39, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.29, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 172.496, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 177, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 181.894, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 187.098, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 192.524, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 198.089, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.716, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 209.329, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.859, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.237, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 225.397, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.274, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.806, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.93, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.59, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.854, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.75, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.285, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.469, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.327, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.873, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.136, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.146, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.941, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.554, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.028, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.409, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.749, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.093, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.495, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.008, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.689, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.602, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.8, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.318, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.825, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.272, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.711, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.211, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.815, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.567, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.515, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.694, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.13, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.858, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.895, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.259, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.969, 0],
                  t: 303,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.872, 0],
                  t: 304,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.759, 0],
                  t: 305,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.595, 0],
                  t: 306,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.325, 0],
                  t: 307,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 314.903, 0],
                  t: 308,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 309,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 325,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.328, 0],
                  t: 326,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.295, 0],
                  t: 327,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.087, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.803, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.531, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 294.366, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.419, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.805, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.642, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.061, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.202, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 277.927, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.442, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.79, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.06, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.344, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.741, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.323, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.168, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.342, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.913, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.929, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.435, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.477, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.073, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.257, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.032, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 277.411, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.375, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.918, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.011, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 296.609, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.647, 0],
                  t: 358,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.121, 0],
                  t: 359,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-21, -3.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'NUMBER',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 315.98, 0],
                  t: 180,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 181,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 201,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.93, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.517, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.503, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.749, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.156, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.636, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.663, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.188, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.728, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.306, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.957, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 244.726, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.662, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.826, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.279, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.089, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.33, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.077, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.409, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.409, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.158, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.92, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 209.959, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 207.411, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.396, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.026, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 197.393, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 193.586, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 189.677, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 185.727, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 181.785, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 177.89, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.064, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 170.333, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.697, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.266, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.091, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.243, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 154.739, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.626, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 150.939, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 149.713, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 148.953, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 148.697, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 148.946, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 149.713, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 150.976, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.743, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 154.996, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.708, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.85, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.39, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.29, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 172.496, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 177, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 181.894, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 187.098, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 192.524, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 198.089, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.716, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 209.329, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.859, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.237, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 225.397, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.274, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.806, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.93, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.59, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.854, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.75, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.285, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.469, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.327, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.873, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.136, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.146, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.941, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.554, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.028, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.409, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.749, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.093, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.495, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.008, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.689, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.602, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.8, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.318, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.825, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.272, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.711, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.211, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.815, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.567, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.515, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.694, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.13, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.858, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.895, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.259, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.969, 0],
                  t: 303,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.872, 0],
                  t: 304,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.759, 0],
                  t: 305,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.595, 0],
                  t: 306,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.325, 0],
                  t: 307,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 314.903, 0],
                  t: 308,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 309,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 325,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.328, 0],
                  t: 326,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.295, 0],
                  t: 327,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.087, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.803, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.531, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 294.366, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.419, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.805, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.642, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.061, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.202, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 277.927, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.442, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.79, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.06, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.344, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.741, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.323, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.168, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.342, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.913, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.929, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.435, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.477, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.073, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.257, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.032, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 277.411, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.375, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.918, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.011, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 296.609, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.647, 0],
                  t: 358,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.121, 0],
                  t: 359,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-197.873, -211.857, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-197.873, -223.357], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [0.557],
                    t: 180,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 181,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 201,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [2.744],
                    t: 202,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.207],
                    t: 203,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [9.461],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [12.569],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.595],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.578],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.545],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.467],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.419],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.35],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.241],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.068],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.805],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.419],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.877],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [49.141],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.173],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.931],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.373],
                    t: 221,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.454],
                    t: 222,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.13],
                    t: 223,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.8],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.859],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.237],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.866],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.688],
                    t: 228,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [64.651],
                    t: 229,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [66.709],
                    t: 230,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [68.822],
                    t: 231,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.957],
                    t: 232,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [73.087],
                    t: 233,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [75.193],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.26],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [79.28],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.241],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [83.098],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [84.812],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [86.355],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [87.705],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [88.846],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [89.758],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [90.424],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [90.831],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [90.97],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [90.835],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [90.424],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [89.739],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [88.783],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [87.567],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [86.102],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [84.402],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [82.489],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [80.382],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [78.11],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [75.674],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [73.028],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.216],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [67.284],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [64.276],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.235],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.2],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.211],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.304],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [49.515],
                    t: 266,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.879],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.43],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.201],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.222],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.458],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.893],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.523],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.341],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.338],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.503],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.82],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.274],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.845],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.513],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.256],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.049],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.866],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.681],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.464],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.187],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.817],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.325],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.677],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.857],
                    t: 290,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.041],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.261],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.482],
                    t: 293,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.672],
                    t: 294,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [22.806],
                    t: 295,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.857],
                    t: 296,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.803],
                    t: 297,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.626],
                    t: 298,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.309],
                    t: 299,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.836],
                    t: 300,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.195],
                    t: 301,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.377],
                    t: 302,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.371],
                    t: 303,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [9.263],
                    t: 304,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.161],
                    t: 305,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.089],
                    t: 306,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.073],
                    t: 307,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1.138],
                    t: 308,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 309,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 325,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.91],
                    t: 326,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.089],
                    t: 327,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.361],
                    t: 328,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.677],
                    t: 329,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [9.988],
                    t: 330,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [12.239],
                    t: 331,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.372],
                    t: 332,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.325],
                    t: 333,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.033],
                    t: 334,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.427],
                    t: 335,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.434],
                    t: 336,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.123],
                    t: 337,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.924],
                    t: 338,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [22.819],
                    t: 339,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.754],
                    t: 340,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.679],
                    t: 341,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.547],
                    t: 342,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.314],
                    t: 343,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.938],
                    t: 344,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.383],
                    t: 345,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.616],
                    t: 346,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.608],
                    t: 347,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.333],
                    t: 348,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.771],
                    t: 349,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.907],
                    t: 350,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.727],
                    t: 351,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.226],
                    t: 352,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.402],
                    t: 353,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.259],
                    t: 354,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.804],
                    t: 355,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.052],
                    t: 356,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.027],
                    t: 357,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.76],
                    t: 358,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.264],
                    t: 359,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      nm: 'Line 10',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Vectorized',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 316.71, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.899, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.575, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.953, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.323, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.496, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.02, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.713, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.175, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.985, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.705, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.838, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.704, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.563, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 153.62, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 145.052, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 137.976, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.504, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.11, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.13, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 144.174, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 151.057, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 158.55, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.418, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.399, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.296, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 190.34, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 198.25, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.676, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.328, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.019, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.96, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.103, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.359, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 232.661, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.973, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.305, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.692, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 232.195, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.897, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.907, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.349, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.368, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.123, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.785, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.542, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.582, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.112, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 196.337, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.468, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 193.489, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 191.933, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 189.635, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.704, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 183.202, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.161, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.923, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 170.822, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 167.14, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.117, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.947, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.791, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.773, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.976, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.445, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.199, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.2, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.393, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.679, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.927, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.966, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.594, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.577, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.641, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 243.485, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.792, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.46, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.415, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.529, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.667, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.703, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.93, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.467, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.393, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.758, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.66, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 304.172, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.382, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 306.381, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.25, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 308.091, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.011, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.098, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.454, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 313.194, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.413, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 303,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.571, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.229, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.103, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.465, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 284.602, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.747, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.2, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.296, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.077, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.302, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.779, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.826, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.745, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.825, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.338, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.531, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.629, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.828, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.293, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.16, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.53, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.45, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.946, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.991, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.501, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.357, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-21, -3.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'VECTORIZED',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 316.71, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.899, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.575, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.953, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.323, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.496, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.02, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.713, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.175, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.985, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.705, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.838, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.704, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.563, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 153.62, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 145.052, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 137.976, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.504, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.11, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.13, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 144.174, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 151.057, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 158.55, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.418, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.399, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.296, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 190.34, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 198.25, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.676, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.328, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.019, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.96, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.103, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.359, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 232.661, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.973, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.305, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.692, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 232.195, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.897, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.907, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.349, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.368, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.123, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.785, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.542, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.582, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.112, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 196.337, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.468, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 193.489, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 191.933, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 189.635, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.704, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 183.202, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.161, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.923, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 170.822, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 167.14, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.117, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.947, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.791, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.773, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.976, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.445, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.199, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.2, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.393, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.679, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.927, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.966, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.594, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.577, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.641, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 243.485, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.792, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.46, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.415, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.529, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.667, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.703, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.93, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.467, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.393, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.758, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.66, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 304.172, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.382, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 306.381, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.25, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 308.091, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.011, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.098, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.454, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 313.194, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.413, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 303,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.571, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.229, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.103, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.465, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 284.602, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.747, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.2, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.296, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.077, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.302, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.779, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.826, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.745, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.825, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.338, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.531, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.629, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.828, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.293, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.16, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.53, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.45, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.946, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.991, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.501, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.357, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-197.873, -211.857, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-197.873, -223.357], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [0],
                    t: 201,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.163],
                    t: 202,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.168],
                    t: 203,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.369],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.649],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.855],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.085],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.207],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.318],
                    t: 209,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [49.095],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.224],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.401],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.356],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [76.915],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [82.939],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [88.312],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [92.944],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [96.764],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.727],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [100],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [100],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.393],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [96.683],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [93.414],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [89.695],
                    t: 228,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [85.645],
                    t: 229,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.393],
                    t: 230,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.08],
                    t: 231,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [72.812],
                    t: 232,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [68.464],
                    t: 233,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [64.189],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.174],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.579],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.503],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.833],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.593],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.833],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.589],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.88],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.7],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.032],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.841],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [47.083],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.699],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.622],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.774],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.068],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.413],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.707],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.846],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.722],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [65.222],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [66.232],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [66.762],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [67.602],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [68.845],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.428],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [72.321],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [74.505],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [76.798],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [79.013],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.002],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [82.637],
                    t: 266,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [83.809],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [84.434],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [84.444],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [83.794],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [82.459],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [80.432],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.728],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [74.38],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.442],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [65.984],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.099],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.894],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.499],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.059],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.739],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.708],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.022],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.723],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.877],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.561],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.837],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.553],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.64],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.062],
                    t: 290,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.781],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.753],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.935],
                    t: 293,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.282],
                    t: 294,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.745],
                    t: 295,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.274],
                    t: 296,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.818],
                    t: 297,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.324],
                    t: 298,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.737],
                    t: 299,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.002],
                    t: 300,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [2.064],
                    t: 301,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.864],
                    t: 302,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 303,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 328,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.775],
                    t: 329,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.285],
                    t: 330,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [9.675],
                    t: 331,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.804],
                    t: 332,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [17.516],
                    t: 333,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.678],
                    t: 334,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.136],
                    t: 335,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.706],
                    t: 336,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.366],
                    t: 337,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.325],
                    t: 338,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.689],
                    t: 339,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.286],
                    t: 340,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.951],
                    t: 341,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.528],
                    t: 342,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.873],
                    t: 343,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.849],
                    t: 344,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.337],
                    t: 345,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.23],
                    t: 346,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.437],
                    t: 347,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.887],
                    t: 348,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.526],
                    t: 349,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.325],
                    t: 350,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.272],
                    t: 351,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.385],
                    t: 352,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [12.703],
                    t: 353,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.296],
                    t: 354,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 355,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_4',
      nm: 'Line 9',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Wordram',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 283.415, 0],
                  t: 180,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.525, 0],
                  t: 181,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.797, 0],
                  t: 182,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 296.196, 0],
                  t: 183,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.666, 0],
                  t: 184,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.172, 0],
                  t: 185,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.642, 0],
                  t: 186,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 314.026, 0],
                  t: 187,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 188,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 197,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.575, 0],
                  t: 198,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.523, 0],
                  t: 199,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.302, 0],
                  t: 200,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.498, 0],
                  t: 201,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.009, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.576, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.93, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 289.837, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 289.121, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.616, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.191, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.767, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.284, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.686, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.924, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 284.971, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.805, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.411, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.774, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.901, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.793, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.454, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.898, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.14, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.204, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.111, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.884, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.552, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.149, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.708, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.262, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.846, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.502, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 236.267, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.181, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.284, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.618, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 225.224, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.144, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.419, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.09, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.198, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.782, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.868, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.466, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.908, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.888, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.476, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.732, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 217.711, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.459, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.017, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.418, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.705, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 209.976, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.283, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.667, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.161, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.803, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.615, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.63, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.868, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.346, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.082, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.09, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.381, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.963, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.837, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.011, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.484, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.255, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.316, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.666, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.296, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.197, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.357, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.764, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.404, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.263, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.323, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.569, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.98, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.541, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.23, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.026, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.909, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.86, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.864, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.985, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.215, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.515, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.876, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.64, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.015, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 314.352, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 319,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.523, 0],
                  t: 320,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.892, 0],
                  t: 321,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 306.197, 0],
                  t: 322,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.478, 0],
                  t: 323,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 296.789, 0],
                  t: 324,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.179, 0],
                  t: 325,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.7, 0],
                  t: 326,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.414, 0],
                  t: 327,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.355, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 275.441, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.719, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.272, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.169, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.49, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.315, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.72, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.797, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.242, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.551, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.776, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.971, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.177, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.442, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.813, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.323, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.007, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.032, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.427, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.109, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.101, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.419, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.077, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.085, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.455, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.194, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.305, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.785, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.635, 0],
                  t: 358,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.845, 0],
                  t: 359,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-21, -3.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'WORDRAM',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 283.415, 0],
                  t: 180,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.525, 0],
                  t: 181,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.797, 0],
                  t: 182,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 296.196, 0],
                  t: 183,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.666, 0],
                  t: 184,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.172, 0],
                  t: 185,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.642, 0],
                  t: 186,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 314.026, 0],
                  t: 187,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 188,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 197,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.575, 0],
                  t: 198,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.523, 0],
                  t: 199,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.302, 0],
                  t: 200,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.498, 0],
                  t: 201,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.009, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.576, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.93, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 289.837, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 289.121, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.616, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.191, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.767, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.284, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.686, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.924, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 284.971, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.805, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.411, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.774, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.901, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.793, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.454, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.898, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.14, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.204, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.111, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.884, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.552, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.149, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.708, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.262, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.846, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.502, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 236.267, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.181, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.284, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.618, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 225.224, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.144, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.419, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.09, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.198, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.782, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.868, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.466, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.908, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.888, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.476, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.732, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 217.711, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.459, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.017, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.418, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.705, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 209.976, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.283, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.667, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.161, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.803, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.615, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.63, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.868, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.346, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.082, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.09, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.381, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.963, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.837, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.011, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.484, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.255, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.316, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.666, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.296, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.197, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.357, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.764, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.404, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.263, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.323, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.569, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.98, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.541, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.23, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.026, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.909, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.86, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.864, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.985, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.215, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.515, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.876, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.64, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.015, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 314.352, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 319,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.523, 0],
                  t: 320,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.892, 0],
                  t: 321,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 306.197, 0],
                  t: 322,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.478, 0],
                  t: 323,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 296.789, 0],
                  t: 324,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.179, 0],
                  t: 325,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.7, 0],
                  t: 326,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.414, 0],
                  t: 327,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.355, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 275.441, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.719, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.272, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.169, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.49, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.315, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.72, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.797, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.242, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.551, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.776, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.971, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.177, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.442, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.813, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.323, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.007, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.032, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.427, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.109, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.101, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.419, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.077, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.085, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.455, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.194, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.305, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.785, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.635, 0],
                  t: 358,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.845, 0],
                  t: 359,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-197.873, -211.857, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-197.873, -223.357], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [18.155],
                    t: 180,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.933],
                    t: 181,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.624],
                    t: 182,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.249],
                    t: 183,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.831],
                    t: 184,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.398],
                    t: 185,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.979],
                    t: 186,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1.614],
                    t: 187,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 188,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 197,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.235],
                    t: 198,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.042],
                    t: 199,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.41],
                    t: 200,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.005],
                    t: 201,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.89],
                    t: 202,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.206],
                    t: 203,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.096],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.684],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.073],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.347],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.576],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.803],
                    t: 209,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.064],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.389],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.801],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [17.315],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [17.946],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.7],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.583],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.595],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.736],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.381],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.871],
                    t: 221,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.458],
                    t: 222,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.131],
                    t: 223,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.876],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.676],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.515],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.375],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.238],
                    t: 228,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.084],
                    t: 229,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.891],
                    t: 230,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.64],
                    t: 231,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.308],
                    t: 232,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.874],
                    t: 233,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.315],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [49.609],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.733],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.665],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.384],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.866],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.091],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.044],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.721],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.482],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.493],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.715],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.118],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.67],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.346],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.126],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.99],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.916],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.85],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.765],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.639],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.452],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.187],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.828],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.361],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.774],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.056],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.198],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.193],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.036],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.722],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.249],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.615],
                    t: 266,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.819],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.862],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.747],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.477],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.055],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.488],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.78],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.938],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.971],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.885],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.69],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.396],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.011],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.546],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.012],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.42],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.78],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.104],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.399],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.632],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.806],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.938],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.044],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.226],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.322],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1.431],
                    t: 293,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 294,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 319,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.803],
                    t: 320,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.306],
                    t: 321,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.845],
                    t: 322,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.395],
                    t: 323,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.929],
                    t: 324,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.42],
                    t: 325,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.839],
                    t: 326,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.156],
                    t: 327,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.351],
                    t: 328,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [22.467],
                    t: 329,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.477],
                    t: 330,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.342],
                    t: 331,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.019],
                    t: 332,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.467],
                    t: 333,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.643],
                    t: 334,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.503],
                    t: 335,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.003],
                    t: 336,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.304],
                    t: 337,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.676],
                    t: 338,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.095],
                    t: 339,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.531],
                    t: 340,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.96],
                    t: 341,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.356],
                    t: 342,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.697],
                    t: 343,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.962],
                    t: 344,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.133],
                    t: 345,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.19],
                    t: 346,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.119],
                    t: 347,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.906],
                    t: 348,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.536],
                    t: 349,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.001],
                    t: 350,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.289],
                    t: 351,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.393],
                    t: 352,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.306],
                    t: 353,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.025],
                    t: 354,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.545],
                    t: 355,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.864],
                    t: 356,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.983],
                    t: 357,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [22.903],
                    t: 358,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.625],
                    t: 359,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_5',
      nm: 'Line 8',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Ultrawide',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 317, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 312.04, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.599, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.077, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.572, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 272.23, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.222, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.747, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.028, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.339, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.738, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.606, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 231.299, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.135, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.407, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.376, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 192.278, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.32, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 172.682, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.52, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 154.965, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.132, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 140.113, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.987, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.508, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.26, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 144.83, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.044, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 159.866, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.465, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 177.563, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.868, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 196.145, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.162, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.721, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.637, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 228.747, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.903, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.973, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 243.843, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.407, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.57, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.254, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.381, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.901, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.001, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.956, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 224.019, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.453, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.536, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.552, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 192.791, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 185.544, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.115, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.803, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 169.76, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.012, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 162.539, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 159.595, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.377, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 156.043, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 155.74, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 156.548, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 158.53, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.708, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.058, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 171.529, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 178.043, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 185.471, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 193.656, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.408, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.503, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.923, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.721, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 240.79, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.016, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.277, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.444, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.386, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.958, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.016, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 308.407, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.001, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 297.49, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.05, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.172, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.134, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.23, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.771, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.092, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.156, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.077, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.756, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.427, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 244.305, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.582, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.435, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.009, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.436, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.82, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.246, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.78, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.454, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.266, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.186, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.178, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.19, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.157, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.976, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.551, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-21, -3.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'ULTRAWIDE',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 317, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 312.04, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.599, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.077, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.572, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 272.23, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.222, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.747, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.028, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.339, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.738, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.606, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 231.299, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.135, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.407, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.376, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 192.278, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.32, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 172.682, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.52, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 154.965, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.132, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 140.113, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.987, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.508, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.26, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 144.83, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.044, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 159.866, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.465, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 177.563, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.868, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 196.145, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.162, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.721, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.637, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 228.747, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.903, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.973, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 243.843, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.407, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.57, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.254, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.381, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.901, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.001, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.956, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 224.019, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.453, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.536, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.552, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 192.791, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 185.544, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.115, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.803, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 169.76, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.012, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 162.539, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 159.595, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.377, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 156.043, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 155.74, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 156.548, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 158.53, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.708, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.058, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 171.529, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 178.043, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 185.471, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 193.656, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.408, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.503, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.923, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.721, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 240.79, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.016, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.277, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.444, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.386, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.958, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.016, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 308.407, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.001, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 297.49, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.05, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.172, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.134, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.23, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.771, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.092, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.156, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.077, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.756, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.427, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 244.305, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.582, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.435, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.009, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.436, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.82, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.246, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.78, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.454, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.266, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.186, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.178, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.19, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.157, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.976, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.551, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-197.873, -211.857, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-197.873, -223.357], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [0],
                    t: 203,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [2.686],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.868],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.556],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.693],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.202],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.991],
                    t: 209,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.949],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.958],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.953],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.981],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.835],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.325],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.278],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.536],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.958],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [67.416],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [72.799],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [78.009],
                    t: 221,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [82.962],
                    t: 222,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [87.584],
                    t: 223,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [91.816],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [95.609],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [98.925],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [100],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [100],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.725],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [96.615],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [93.063],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [89.162],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [84.935],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [80.287],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [75.371],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.34],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [65.326],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.452],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.826],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.547],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [47.705],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.377],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.636],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.545],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.16],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.53],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.701],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.713],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.595],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.243],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.51],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.26],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.349],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.628],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.944],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [67.14],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [71.056],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [74.53],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.401],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [79.587],
                    t: 266,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.614],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [83.489],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [85.082],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [86.282],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [87],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [87.165],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [86.727],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [85.656],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [83.94],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.589],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [78.63],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [75.11],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [71.095],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [66.671],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.94],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.025],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.933],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.638],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.195],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.668],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.121],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.625],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.253],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.08],
                    t: 290,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [9.185],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.647],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.545],
                    t: 293,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 294,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 328,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.277],
                    t: 329,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.547],
                    t: 330,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.651],
                    t: 331,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.45],
                    t: 332,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.794],
                    t: 333,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.525],
                    t: 334,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.475],
                    t: 335,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.465],
                    t: 336,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.511],
                    t: 337,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.635],
                    t: 338,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.89],
                    t: 339,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.149],
                    t: 340,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.296],
                    t: 341,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.226],
                    t: 342,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.847],
                    t: 343,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.077],
                    t: 344,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.846],
                    t: 345,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.098],
                    t: 346,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.786],
                    t: 347,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.877],
                    t: 348,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.349],
                    t: 349,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.209],
                    t: 350,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.468],
                    t: 351,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.148],
                    t: 352,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.276],
                    t: 353,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [12.891],
                    t: 354,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.041],
                    t: 355,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.786],
                    t: 356,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 357,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_6',
      nm: 'Line 7',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Sparse',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 317, 0],
                  t: 190,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.691, 0],
                  t: 191,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.526, 0],
                  t: 192,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 304.72, 0],
                  t: 193,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.061, 0],
                  t: 194,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.3, 0],
                  t: 195,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.18, 0],
                  t: 196,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.668, 0],
                  t: 197,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.277, 0],
                  t: 198,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.129, 0],
                  t: 199,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.239, 0],
                  t: 200,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.998, 0],
                  t: 201,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.351, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.091, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.999, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.89, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.61, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 207.035, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.062, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.603, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.58, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.925, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.581, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 207.537, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.771, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.247, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.937, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.809, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.839, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.002, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.271, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.624, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 225.036, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.484, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.947, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 232.401, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.823, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.191, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.484, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.679, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 243.752, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.68, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.461, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.196, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.896, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.559, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.168, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.72, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.21, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.629, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.98, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.24, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.404, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.465, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.429, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.297, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.066, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.744, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.335, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.837, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.254, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.59, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.848, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.031, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.932, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.721, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.449, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.115, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.71, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.185, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.543, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.809, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.99, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.102, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.172, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.207, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.227, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.258, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.307, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.399, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.553, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.783, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.109, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.556, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.136, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.868, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.867, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.127, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.97, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.707, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.405, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.086, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.773, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.487, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.248, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.073, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.1, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.343, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.728, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.266, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.969, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.844, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.907, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.164, 0],
                  t: 303,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.624, 0],
                  t: 304,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.291, 0],
                  t: 305,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.182, 0],
                  t: 306,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.295, 0],
                  t: 307,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.638, 0],
                  t: 308,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.217, 0],
                  t: 309,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.037, 0],
                  t: 310,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.099, 0],
                  t: 311,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.412, 0],
                  t: 312,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.928, 0],
                  t: 313,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.627, 0],
                  t: 314,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.472, 0],
                  t: 315,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 294.44, 0],
                  t: 316,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.505, 0],
                  t: 317,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.633, 0],
                  t: 318,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 306.812, 0],
                  t: 319,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.014, 0],
                  t: 320,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.204, 0],
                  t: 321,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 322,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-6, -4, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'SPARSE',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.215686276555, 0.215686276555, 0.215686276555, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [0],
                    t: 190,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.711],
                    t: 191,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.502],
                    t: 192,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.643],
                    t: 193,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.241],
                    t: 194,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.436],
                    t: 195,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.363],
                    t: 196,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.047],
                    t: 197,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.203],
                    t: 198,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.228],
                    t: 199,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.574],
                    t: 200,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [47.028],
                    t: 201,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.621],
                    t: 202,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.464],
                    t: 203,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.676],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.356],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.589],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.44],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.966],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.214],
                    t: 209,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.226],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.04],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.686],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.168],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.502],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.704],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.791],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.779],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.681],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.512],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.286],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.014],
                    t: 221,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [49.71],
                    t: 222,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.387],
                    t: 223,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [47.056],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.73],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.42],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.14],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.901],
                    t: 228,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.715],
                    t: 229,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.595],
                    t: 230,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.552],
                    t: 231,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.59],
                    t: 232,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.652],
                    t: 233,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.732],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.835],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.964],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.125],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.321],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.553],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.823],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.141],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.513],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.939],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.418],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.95],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.533],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.166],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.848],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.577],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.351],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.169],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.029],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.929],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.868],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.856],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.902],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.983],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.098],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.245],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.427],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.646],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.929],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.274],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.673],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.116],
                    t: 266,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.594],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.098],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.62],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.148],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.674],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.187],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.677],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.136],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.551],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.914],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.214],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.442],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.586],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.638],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.587],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.446],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.366],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.364],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.424],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.532],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.674],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.837],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.009],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.177],
                    t: 290,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.332],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.461],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.556],
                    t: 293,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.607],
                    t: 294,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.605],
                    t: 295,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.542],
                    t: 296,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.41],
                    t: 297,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.202],
                    t: 298,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.911],
                    t: 299,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.532],
                    t: 300,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.058],
                    t: 301,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.484],
                    t: 302,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.805],
                    t: 303,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.016],
                    t: 304,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.113],
                    t: 305,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.093],
                    t: 306,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.951],
                    t: 307,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.685],
                    t: 308,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.291],
                    t: 309,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.767],
                    t: 310,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [22.11],
                    t: 311,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.32],
                    t: 312,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.419],
                    t: 313,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.421],
                    t: 314,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.343],
                    t: 315,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [12.199],
                    t: 316,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.002],
                    t: 317,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.768],
                    t: 318,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.509],
                    t: 319,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.241],
                    t: 320,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.976],
                    t: 321,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 322,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_7',
      nm: 'Line 6',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Feeding',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 317, 0],
                  t: 190,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 314.367, 0],
                  t: 191,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.383, 0],
                  t: 192,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.734, 0],
                  t: 193,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 297.21, 0],
                  t: 194,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 289.564, 0],
                  t: 195,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.543, 0],
                  t: 196,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.097, 0],
                  t: 197,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.745, 0],
                  t: 198,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.617, 0],
                  t: 199,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.717, 0],
                  t: 200,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.441, 0],
                  t: 201,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.735, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 217.391, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.192, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 209.953, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 207.523, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.78, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.621, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.964, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.857, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.285, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.008, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 207.227, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.661, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.274, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.044, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.943, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.95, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.042, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.198, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.396, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.834, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.033, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 231.192, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.29, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 235.307, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.22, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.013, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 240.678, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.306, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 243.912, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.481, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.014, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.496, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.927, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.299, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.611, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.846, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.995, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.051, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.024, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.908, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.705, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.425, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.063, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.623, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.107, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.519, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.866, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.145, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.361, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.609, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.425, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.241, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.994, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.641, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.187, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.648, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.035, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.361, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.644, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.903, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.149, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.397, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.668, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.976, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.336, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.766, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.283, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.904, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.645, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.748, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.094, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.339, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.136, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.968, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.806, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.568, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.674, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.892, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.222, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.677, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.271, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.009, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.901, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.961, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.192, 0],
                  t: 303,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.604, 0],
                  t: 304,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.201, 0],
                  t: 305,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.996, 0],
                  t: 306,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.988, 0],
                  t: 307,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.184, 0],
                  t: 308,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.59, 0],
                  t: 309,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 272.211, 0],
                  t: 310,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 275.051, 0],
                  t: 311,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.107, 0],
                  t: 312,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.348, 0],
                  t: 313,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 284.749, 0],
                  t: 314,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.281, 0],
                  t: 315,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.919, 0],
                  t: 316,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.643, 0],
                  t: 317,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.435, 0],
                  t: 318,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.268, 0],
                  t: 319,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.107, 0],
                  t: 320,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.95, 0],
                  t: 321,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 314.766, 0],
                  t: 322,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 323,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-6, -4, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'FEEDING',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [0],
                    t: 190,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1.423],
                    t: 191,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.119],
                    t: 192,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.174],
                    t: 193,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.699],
                    t: 194,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.831],
                    t: 195,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.708],
                    t: 196,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.356],
                    t: 197,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.489],
                    t: 198,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.505],
                    t: 199,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.856],
                    t: 200,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [47.329],
                    t: 201,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.954],
                    t: 202,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.843],
                    t: 203,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.112],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.863],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.176],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.118],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.744],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.1],
                    t: 209,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.226],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.158],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.926],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.535],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.999],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.336],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.561],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.689],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.733],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.706],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.621],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.491],
                    t: 221,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.325],
                    t: 222,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.137],
                    t: 223,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.738],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [47.55],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.383],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.249],
                    t: 228,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.159],
                    t: 229,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.124],
                    t: 230,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.156],
                    t: 231,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.256],
                    t: 232,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.376],
                    t: 233,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.508],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.659],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.832],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.03],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.257],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.515],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.806],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.138],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.518],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.946],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.421],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.943],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.511],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.123],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.779],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.476],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.213],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.99],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.804],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.653],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.537],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.454],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.403],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.393],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.432],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.502],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.601],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.735],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.925],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.17],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.462],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.794],
                    t: 266,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.157],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.544],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.946],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.354],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.759],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.154],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.528],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.874],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.181],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.442],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.647],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.788],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.855],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.839],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.732],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.545],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.412],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.348],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.374],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.438],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.522],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.613],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.701],
                    t: 290,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.776],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.83],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.836],
                    t: 294,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.772],
                    t: 295,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.654],
                    t: 296,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.475],
                    t: 297,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.229],
                    t: 298,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.909],
                    t: 299,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.51],
                    t: 300,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.027],
                    t: 301,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.455],
                    t: 302,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.789],
                    t: 303,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.027],
                    t: 304,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.162],
                    t: 305,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.194],
                    t: 306,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.117],
                    t: 307,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.93],
                    t: 308,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.629],
                    t: 309,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.212],
                    t: 310,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [22.678],
                    t: 311,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.026],
                    t: 312,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.273],
                    t: 313,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [17.436],
                    t: 314,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.527],
                    t: 315,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.559],
                    t: 316,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.545],
                    t: 317,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [9.497],
                    t: 318,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.428],
                    t: 319,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.35],
                    t: 320,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.275],
                    t: 321,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1.214],
                    t: 322,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 323,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_8',
      nm: 'Line 5',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Pipelines',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 15,
                  s: [0],
                },
                { t: 75, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 316.937, 0],
                  t: 186,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.715, 0],
                  t: 187,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 314.611, 0],
                  t: 188,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 313.566, 0],
                  t: 189,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 312.544, 0],
                  t: 190,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.427, 0],
                  t: 191,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.078, 0],
                  t: 192,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 308.301, 0],
                  t: 193,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.878, 0],
                  t: 194,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.475, 0],
                  t: 195,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 297.81, 0],
                  t: 196,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.761, 0],
                  t: 197,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 284.813, 0],
                  t: 198,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.006, 0],
                  t: 199,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 272.293, 0],
                  t: 200,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268, 0],
                  t: 201,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.003, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.023, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.768, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.981, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.435, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.939, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.562, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.28, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.993, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.62, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.096, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.362, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.372, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.103, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.521, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.623, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.396, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.846, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 243.982, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 240.874, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.611, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.224, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.747, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.215, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.667, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.146, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.695, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.364, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.199, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 207.251, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.57, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.21, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.224, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 198.663, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 197.58, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 197.027, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 197.059, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 197.726, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.076, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.698, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.007, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.059, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.898, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.557, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.067, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.445, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.701, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.694, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.376, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.849, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.115, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.357, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.635, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.994, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.466, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.084, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.873, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.05, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.472, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.133, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.04, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.193, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.592, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 207.23, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 209.101, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.185, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.469, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.931, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.545, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.293, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 224.192, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.204, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.289, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 236.523, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.6, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.605, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.503, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.261, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.846, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.224, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.361, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.215, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.652, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.657, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.252, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.354, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.937, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.259, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.367, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.305, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.114, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.852, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.555, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.286, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.084, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.007, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.103, 0],
                  t: 303,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.422, 0],
                  t: 304,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.017, 0],
                  t: 305,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.938, 0],
                  t: 306,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.222, 0],
                  t: 307,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.646, 0],
                  t: 308,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.153, 0],
                  t: 309,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.796, 0],
                  t: 310,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.624, 0],
                  t: 311,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.686, 0],
                  t: 312,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.015, 0],
                  t: 313,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.654, 0],
                  t: 314,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.628, 0],
                  t: 315,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.967, 0],
                  t: 316,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.698, 0],
                  t: 317,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.841, 0],
                  t: 318,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.409, 0],
                  t: 319,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 272.423, 0],
                  t: 320,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.85, 0],
                  t: 321,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.601, 0],
                  t: 322,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.601, 0],
                  t: 323,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.769, 0],
                  t: 324,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 297.052, 0],
                  t: 325,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.364, 0],
                  t: 326,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.656, 0],
                  t: 327,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 312.842, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-6, -4, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'PIPELINES',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 1,
          op: 901,
          st: 1,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.215686276555, 0.215686276555, 0.215686276555, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [0],
                    t: 185,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.036],
                    t: 186,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.698],
                    t: 187,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1.298],
                    t: 188,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1.856],
                    t: 189,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [2.412],
                    t: 190,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.018],
                    t: 191,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.748],
                    t: 192,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.703],
                    t: 193,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.016],
                    t: 194,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.852],
                    t: 195,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.376],
                    t: 196,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.643],
                    t: 197,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [17.401],
                    t: 198,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.08],
                    t: 199,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.168],
                    t: 200,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.489],
                    t: 201,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.108],
                    t: 202,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.179],
                    t: 203,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.856],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.282],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.577],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.846],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.049],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.201],
                    t: 209,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.356],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.557],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.842],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.239],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.772],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.459],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.313],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.34],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.544],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.923],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.47],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.15],
                    t: 221,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.913],
                    t: 222,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.744],
                    t: 223,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.623],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.533],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.45],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.354],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.218],
                    t: 228,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.019],
                    t: 229,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.73],
                    t: 230,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.323],
                    t: 231,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.772],
                    t: 232,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.047],
                    t: 233,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.121],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.965],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [64.551],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [64.849],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [64.831],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [64.471],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.742],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.866],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.157],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.589],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.136],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.779],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.503],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.299],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.161],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.089],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.087],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.165],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.337],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.621],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.018],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.428],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.818],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.164],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.45],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.657],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.772],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.781],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.675],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.447],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.089],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.599],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.976],
                    t: 266,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.22],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.334],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.324],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.197],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.962],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.631],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.219],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.733],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.167],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.539],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.871],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.501],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.838],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.214],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.647],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.156],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.759],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.474],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.32],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.318],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.54],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.998],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.677],
                    t: 290,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.558],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.621],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.847],
                    t: 293,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.212],
                    t: 294,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.695],
                    t: 295,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.269],
                    t: 296,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.911],
                    t: 297,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.595],
                    t: 298,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.295],
                    t: 299,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.982],
                    t: 300,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.631],
                    t: 301,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.214],
                    t: 302,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.702],
                    t: 303,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.07],
                    t: 304,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.288],
                    t: 305,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.331],
                    t: 306,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.177],
                    t: 307,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.949],
                    t: 308,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.675],
                    t: 309,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.328],
                    t: 310,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.879],
                    t: 311,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.306],
                    t: 312,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.587],
                    t: 313,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.702],
                    t: 314,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.635],
                    t: 315,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.37],
                    t: 316,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.894],
                    t: 317,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.196],
                    t: 318,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.267],
                    t: 319,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.099],
                    t: 320,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.705],
                    t: 321,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.137],
                    t: 322,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.436],
                    t: 323,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.639],
                    t: 324,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.786],
                    t: 325,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.913],
                    t: 326,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.056],
                    t: 327,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [2.251],
                    t: 328,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 329,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_9',
      nm: 'Line 4',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Dataflow',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 317, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 312.04, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.599, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.077, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.572, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 272.23, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.222, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.747, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.028, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.339, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.738, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.606, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 231.299, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.135, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.407, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.376, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 192.278, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.32, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 172.682, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.52, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 154.965, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.132, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 140.113, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.987, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.508, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.26, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 144.83, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.044, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 159.866, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.465, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 177.563, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.868, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 196.145, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.162, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.721, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.637, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 228.747, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.903, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.973, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 243.843, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.407, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.57, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.254, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.381, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.901, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.001, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.956, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 224.019, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.453, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.536, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.552, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 192.791, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 185.544, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.115, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.803, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 169.76, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.012, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 162.539, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 159.595, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.377, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 156.043, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 155.74, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 156.548, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 158.53, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.708, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.058, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 171.529, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 178.043, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 185.471, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 193.656, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.408, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.503, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.923, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.721, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 240.79, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.016, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.277, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.444, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.386, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.958, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.016, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 308.407, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.001, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 297.49, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.05, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.172, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.134, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.23, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.771, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.092, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.156, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.077, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.756, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.427, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 244.305, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.582, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.435, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.009, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.436, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.82, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.246, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.78, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.454, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.266, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.186, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.178, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.19, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.157, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.976, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.551, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-21, -3.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'DATAFLOW',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 317, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 312.04, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.599, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.077, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.572, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 272.23, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.222, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.747, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.028, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.339, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.738, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.606, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 231.299, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.135, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.407, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.376, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 192.278, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.32, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 172.682, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.52, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 154.965, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.132, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 140.113, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.987, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.508, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.26, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 144.83, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.044, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 159.866, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.465, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 177.563, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.868, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 196.145, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.162, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.721, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.637, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 228.747, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.903, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.973, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 243.843, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.407, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.57, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 247.254, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.381, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.901, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.001, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.956, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 224.019, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.453, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.536, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 200.552, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 192.791, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 185.544, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.115, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.803, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 169.76, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.012, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 162.539, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 159.595, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.377, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 156.043, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 155.74, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 156.548, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 158.53, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.708, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.058, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 171.529, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 178.043, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 185.471, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 193.656, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.408, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 211.503, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 220.923, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.721, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 240.79, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.016, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.277, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.444, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.386, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.958, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.016, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 308.407, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.001, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 297.49, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.05, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.172, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.134, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.23, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.771, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.092, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.156, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.077, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.756, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.427, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 244.305, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.582, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.435, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.009, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 241.436, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.82, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.246, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 248.78, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.454, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.266, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.186, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.178, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.19, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.157, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 303.976, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.551, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-197.873, -211.857, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-197.873, -223.357], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [0],
                    t: 203,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [2.686],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.868],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.556],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.693],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.202],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.991],
                    t: 209,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.949],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.958],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.953],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.981],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.835],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.325],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.278],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.536],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.958],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [67.416],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [72.799],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [78.009],
                    t: 221,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [82.962],
                    t: 222,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [87.584],
                    t: 223,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [91.816],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [95.609],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [98.925],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [100],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [100],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.725],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [96.615],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [93.063],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [89.162],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [84.935],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [80.287],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [75.371],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.34],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [65.326],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.452],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.826],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.547],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [47.705],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.377],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.636],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.545],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.16],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.53],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [37.701],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.713],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.595],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [43.243],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.51],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.26],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.349],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.628],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.944],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [67.14],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [71.056],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [74.53],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.401],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [79.587],
                    t: 266,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.614],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [83.489],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [85.082],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [86.282],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [87],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [87.165],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [86.727],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [85.656],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [83.94],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.589],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [78.63],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [75.11],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [71.095],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [66.671],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.94],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.025],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.933],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.638],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.195],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.668],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.121],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.625],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.253],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.08],
                    t: 290,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [9.185],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.647],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.545],
                    t: 293,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 294,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 328,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.277],
                    t: 329,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.547],
                    t: 330,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.651],
                    t: 331,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.45],
                    t: 332,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.794],
                    t: 333,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.525],
                    t: 334,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.475],
                    t: 335,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.465],
                    t: 336,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.511],
                    t: 337,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.635],
                    t: 338,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.89],
                    t: 339,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.149],
                    t: 340,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.296],
                    t: 341,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.226],
                    t: 342,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.847],
                    t: 343,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.077],
                    t: 344,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.846],
                    t: 345,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.098],
                    t: 346,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.786],
                    t: 347,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.877],
                    t: 348,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.349],
                    t: 349,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.209],
                    t: 350,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.468],
                    t: 351,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.148],
                    t: 352,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.276],
                    t: 353,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [12.891],
                    t: 354,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.041],
                    t: 355,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.786],
                    t: 356,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 357,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_10',
      nm: 'Line 3',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Branchless',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 293.136, 0],
                  t: 180,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.081, 0],
                  t: 181,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.194, 0],
                  t: 182,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 183,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.36, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 313.155, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.649, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.859, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.781, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 297.415, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.804, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.939, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.855, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 277.579, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 272.128, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.533, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.833, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.116, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.495, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 244.078, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.973, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.295, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.163, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.695, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.449, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.252, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.232, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.566, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.419, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 195.95, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 189.298, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.605, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 175.985, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 169.549, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.389, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.592, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.244, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.424, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 143.199, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 139.616, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 136.71, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 134.536, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.094, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.418, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.507, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.338, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 134.898, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 137.182, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 140.124, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 143.704, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.849, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.494, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.575, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 162.991, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.708, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.774, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 181.139, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 187.765, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.587, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.561, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.624, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.728, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.973, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.336, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.731, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.083, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.326, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.395, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.242, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 272.826, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.103, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.048, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.642, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.858, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.695, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.153, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.225, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 312.925, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.265, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 320,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 314.938, 0],
                  t: 321,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.263, 0],
                  t: 322,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.472, 0],
                  t: 323,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.63, 0],
                  t: 324,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.769, 0],
                  t: 325,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.949, 0],
                  t: 326,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.217, 0],
                  t: 327,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.638, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 277.28, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.213, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.518, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.28, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.596, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.561, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.292, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.061, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.816, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.205, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.313, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.222, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.006, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.75, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.521, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.418, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.52, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.908, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.835, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.502, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.709, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.505, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.928, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.012, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.776, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.246, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.421, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.304, 0],
                  t: 358,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.881, 0],
                  t: 359,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-21, -3.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'BRANCHLESS',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 293.136, 0],
                  t: 180,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.081, 0],
                  t: 181,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.194, 0],
                  t: 182,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 183,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.36, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 313.155, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.649, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.859, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.781, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 297.415, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 292.804, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.939, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.855, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 277.579, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 272.128, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.533, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.833, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.116, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 249.495, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 244.078, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.973, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.295, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.163, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.695, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.449, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.252, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.232, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.566, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.419, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 195.95, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 189.298, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.605, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 175.985, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 169.549, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.389, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.592, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.244, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.424, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 143.199, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 139.616, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 136.71, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 134.536, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.094, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.418, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.507, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.338, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 134.898, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 137.182, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 140.124, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 143.704, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.849, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.494, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 157.575, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 162.991, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.708, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.774, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 181.139, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 187.765, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.587, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 201.561, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.624, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.728, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.973, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.336, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 237.731, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.083, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.326, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.395, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.242, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 272.826, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.103, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.048, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.642, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.858, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.695, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.153, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.225, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 312.925, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.265, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 320,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 314.938, 0],
                  t: 321,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.263, 0],
                  t: 322,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.472, 0],
                  t: 323,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.63, 0],
                  t: 324,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.769, 0],
                  t: 325,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 290.949, 0],
                  t: 326,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.217, 0],
                  t: 327,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 281.638, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 277.28, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.213, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.518, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.28, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.596, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.561, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.292, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.061, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.816, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.205, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.313, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.222, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.006, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.75, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.521, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.418, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.52, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.908, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.835, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.502, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.709, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.505, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.928, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.012, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 263.776, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.246, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.421, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 279.304, 0],
                  t: 358,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 285.881, 0],
                  t: 359,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-197.873, -211.857, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-197.873, -223.357], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [12.903],
                    t: 180,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.607],
                    t: 181,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.224],
                    t: 182,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 183,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.353],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [2.085],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.976],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.026],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.231],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.586],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.083],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.711],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.458],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.312],
                    t: 221,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.257],
                    t: 222,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.28],
                    t: 223,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.362],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.452],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.49],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.419],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.177],
                    t: 228,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.705],
                    t: 229,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.939],
                    t: 230,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.814],
                    t: 231,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.568],
                    t: 232,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.837],
                    t: 233,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.55],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.612],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.934],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [65.432],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [69.026],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [72.645],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [76.222],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [79.701],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [83.032],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [86.165],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [89.053],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [91.658],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [93.943],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [95.881],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [97.448],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [98.626],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.402],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.769],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.725],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.275],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [98.428],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [97.197],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [95.603],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [93.67],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [91.43],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [88.918],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [86.174],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [83.245],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [80.155],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [76.878],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [73.436],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [69.857],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [66.168],
                    t: 266,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.399],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.581],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.741],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.825],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.846],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.848],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.875],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.961],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.139],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.438],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.88],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.487],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [17.273],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.252],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.431],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.815],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.408],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.207],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [2.208],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.404],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 320,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1.118],
                    t: 321,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.647],
                    t: 322,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.232],
                    t: 323,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.851],
                    t: 324,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.478],
                    t: 325,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.085],
                    t: 326,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.643],
                    t: 327,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.118],
                    t: 328,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.473],
                    t: 329,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.671],
                    t: 330,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.668],
                    t: 331,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.417],
                    t: 332,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.868],
                    t: 333,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.967],
                    t: 334,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.655],
                    t: 335,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.867],
                    t: 336,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.78],
                    t: 337,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.912],
                    t: 338,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.242],
                    t: 339,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.724],
                    t: 340,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.314],
                    t: 341,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.97],
                    t: 342,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.65],
                    t: 343,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.313],
                    t: 344,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.911],
                    t: 345,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.396],
                    t: 346,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.726],
                    t: 347,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.861],
                    t: 348,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.765],
                    t: 349,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.405],
                    t: 350,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.753],
                    t: 351,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.783],
                    t: 352,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.473],
                    t: 353,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.807],
                    t: 354,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.77],
                    t: 355,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.355],
                    t: 356,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.558],
                    t: 357,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.379],
                    t: 358,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.824],
                    t: 359,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_11',
      nm: 'Line 2',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Specific',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 316.71, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.899, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.575, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.953, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.323, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.496, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.02, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.713, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.175, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.985, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.705, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.838, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.704, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.563, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 153.62, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 145.052, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 137.976, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.504, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.11, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.13, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 144.174, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 151.057, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 158.55, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.418, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.399, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.296, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 190.34, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 198.25, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.676, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.328, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.019, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.96, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.103, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.359, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 232.661, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.973, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.305, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.692, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 232.195, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.897, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.907, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.349, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.368, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.123, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.785, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.542, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.582, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.112, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 196.337, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.468, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 193.489, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 191.933, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 189.635, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.704, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 183.202, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.161, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.923, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 170.822, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 167.14, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.117, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.947, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.791, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.773, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.976, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.445, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.199, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.2, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.393, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.679, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.927, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.966, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.594, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.577, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.641, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 243.485, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.792, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.46, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.415, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.529, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.667, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.703, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.93, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.467, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.393, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.758, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.66, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 304.172, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.382, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 306.381, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.25, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 308.091, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.011, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.098, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.454, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 313.194, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.413, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 303,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.571, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.229, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.103, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.465, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 284.602, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.747, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.2, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.296, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.077, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.302, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.779, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.826, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.745, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.825, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.338, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.531, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.629, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.828, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.293, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.16, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.53, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.45, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.946, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.991, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.501, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.357, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-21, -3.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'SPECIFIC',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 316.71, 0],
                  t: 202,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 301.899, 0],
                  t: 203,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.575, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.953, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 267.323, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.496, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.02, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.713, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.175, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.985, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.705, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.838, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.704, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 163.563, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 153.62, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 145.052, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 137.976, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132.504, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 132, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.11, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.13, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 144.174, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 151.057, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 158.55, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 166.418, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.399, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 182.296, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 190.34, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 198.25, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.676, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.328, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.019, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.96, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 227.103, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.359, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 232.661, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.973, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 234.305, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.692, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 232.195, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 229.897, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.907, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.349, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.368, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.123, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.785, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 206.542, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.582, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 199.112, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 196.337, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.468, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 193.489, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 191.933, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 189.635, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.704, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 183.202, 0],
                  t: 261,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.161, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 174.923, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 170.822, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 167.14, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.117, 0],
                  t: 266,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.947, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.791, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.773, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 161.976, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 164.445, 0],
                  t: 271,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 168.199, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.2, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 179.393, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 186.679, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.927, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 203.966, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.594, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 223.577, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 233.641, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 243.485, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.792, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.46, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.415, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.529, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.667, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 287.703, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.93, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 295.467, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 298.393, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.758, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.66, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 304.172, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.382, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 306.381, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.25, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 308.091, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.011, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 310.098, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 311.454, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 313.194, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.413, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 303,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.571, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.229, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 299.103, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 291.465, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 284.602, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 278.747, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 274.2, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.296, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 270.077, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.302, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.779, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.826, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.745, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.825, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.338, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 252.531, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.629, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.828, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.293, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 256.16, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.53, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.45, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.946, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.991, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.501, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 305.357, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-197.873, -211.857, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: { a: 0, k: [8, 8], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.215686276555, 0.215686276555, 0.215686276555, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-197.873, -223.357], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.215686276555, 0.215686276555, 0.215686276555, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [0],
                    t: 201,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.163],
                    t: 202,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.168],
                    t: 203,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.369],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.649],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.855],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.085],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.207],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.318],
                    t: 209,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [49.095],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.224],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.401],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.356],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [76.915],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [82.939],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [88.312],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [92.944],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [96.764],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.727],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [100],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [100],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.393],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [96.683],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [93.414],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [89.695],
                    t: 228,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [85.645],
                    t: 229,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.393],
                    t: 230,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.08],
                    t: 231,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [72.812],
                    t: 232,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [68.464],
                    t: 233,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [64.189],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.174],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.579],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.503],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.833],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.593],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.833],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.589],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.88],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.7],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.032],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.841],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [47.083],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.699],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.622],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.774],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.068],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.413],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [59.707],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.846],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [63.722],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [65.222],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [66.232],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [66.762],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [67.602],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [68.845],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.428],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [72.321],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [74.505],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [76.798],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [79.013],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.002],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [82.637],
                    t: 266,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [83.809],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [84.434],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [84.444],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [83.794],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [82.459],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [80.432],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.728],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [74.38],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.442],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [65.984],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [61.099],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.894],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.499],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.059],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [39.739],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.708],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.022],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.723],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.877],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.561],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.837],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.553],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.64],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.062],
                    t: 290,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.781],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.753],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.935],
                    t: 293,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.282],
                    t: 294,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.745],
                    t: 295,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.274],
                    t: 296,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.818],
                    t: 297,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.324],
                    t: 298,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.737],
                    t: 299,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.002],
                    t: 300,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [2.064],
                    t: 301,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.864],
                    t: 302,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 303,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 328,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.775],
                    t: 329,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.285],
                    t: 330,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [9.675],
                    t: 331,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [13.804],
                    t: 332,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [17.516],
                    t: 333,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [20.678],
                    t: 334,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.136],
                    t: 335,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.706],
                    t: 336,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.366],
                    t: 337,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.325],
                    t: 338,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.689],
                    t: 339,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.286],
                    t: 340,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.951],
                    t: 341,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.528],
                    t: 342,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.873],
                    t: 343,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.849],
                    t: 344,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.337],
                    t: 345,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.23],
                    t: 346,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.437],
                    t: 347,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.887],
                    t: 348,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.526],
                    t: 349,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.325],
                    t: 350,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.272],
                    t: 351,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.385],
                    t: 352,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [12.703],
                    t: 353,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.296],
                    t: 354,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 355,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_12',
      nm: 'Line 1',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: 'Algorithm',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 14,
                  s: [0],
                },
                { t: 74, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              k: [
                {
                  s: [8, 317, 0],
                  t: 204,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 312.123, 0],
                  t: 205,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 304.186, 0],
                  t: 206,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 296.298, 0],
                  t: 207,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 288.444, 0],
                  t: 208,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.657, 0],
                  t: 209,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 273.001, 0],
                  t: 210,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.544, 0],
                  t: 211,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 258.382, 0],
                  t: 212,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 251.631, 0],
                  t: 213,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 245.412, 0],
                  t: 214,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 239.872, 0],
                  t: 215,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 235.164, 0],
                  t: 216,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 231.031, 0],
                  t: 217,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 225.806, 0],
                  t: 218,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.534, 0],
                  t: 219,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.478, 0],
                  t: 220,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 204.879, 0],
                  t: 221,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 196.963, 0],
                  t: 222,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 188.934, 0],
                  t: 223,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 180.979, 0],
                  t: 224,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 173.267, 0],
                  t: 225,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 165.947, 0],
                  t: 226,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 159.146, 0],
                  t: 227,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 152.978, 0],
                  t: 228,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 147.541, 0],
                  t: 229,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 142.914, 0],
                  t: 230,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 139.157, 0],
                  t: 231,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 136.318, 0],
                  t: 232,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 134.396, 0],
                  t: 233,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.436, 0],
                  t: 234,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 133.41, 0],
                  t: 235,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 134.286, 0],
                  t: 236,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 136.045, 0],
                  t: 237,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 138.618, 0],
                  t: 238,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 141.938, 0],
                  t: 239,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 145.901, 0],
                  t: 240,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 150.41, 0],
                  t: 241,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 155.333, 0],
                  t: 242,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 160.519, 0],
                  t: 243,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 165.846, 0],
                  t: 244,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 171.131, 0],
                  t: 245,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 176.211, 0],
                  t: 246,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 180.981, 0],
                  t: 247,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 185.665, 0],
                  t: 248,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 190.209, 0],
                  t: 249,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 194.521, 0],
                  t: 250,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 198.543, 0],
                  t: 251,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 202.213, 0],
                  t: 252,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 205.498, 0],
                  t: 253,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 208.367, 0],
                  t: 254,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 210.806, 0],
                  t: 255,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.813, 0],
                  t: 256,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.394, 0],
                  t: 257,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.564, 0],
                  t: 258,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.348, 0],
                  t: 259,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.779, 0],
                  t: 260,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.738, 0],
                  t: 262,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.359, 0],
                  t: 263,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.809, 0],
                  t: 264,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 215.145, 0],
                  t: 265,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.701, 0],
                  t: 267,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.038, 0],
                  t: 268,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.49, 0],
                  t: 269,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.115, 0],
                  t: 270,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.083, 0],
                  t: 272,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 212.521, 0],
                  t: 273,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 213.316, 0],
                  t: 274,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 214.502, 0],
                  t: 275,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 216.1, 0],
                  t: 276,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 218.009, 0],
                  t: 277,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 219.73, 0],
                  t: 278,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 221.311, 0],
                  t: 279,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 222.853, 0],
                  t: 280,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 224.447, 0],
                  t: 281,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 226.171, 0],
                  t: 282,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 228.09, 0],
                  t: 283,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 230.259, 0],
                  t: 284,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 232.724, 0],
                  t: 285,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 235.518, 0],
                  t: 286,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 238.666, 0],
                  t: 287,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 242.185, 0],
                  t: 288,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 246.083, 0],
                  t: 289,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 250.36, 0],
                  t: 290,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.011, 0],
                  t: 291,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.02, 0],
                  t: 292,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.369, 0],
                  t: 293,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.031, 0],
                  t: 294,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 276.981, 0],
                  t: 295,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 283.177, 0],
                  t: 296,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 289.592, 0],
                  t: 297,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 296.187, 0],
                  t: 298,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 302.906, 0],
                  t: 299,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 309.709, 0],
                  t: 300,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 316.553, 0],
                  t: 301,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 302,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 326,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.186, 0],
                  t: 327,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 307.81, 0],
                  t: 328,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 300.553, 0],
                  t: 329,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 293.557, 0],
                  t: 330,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 286.983, 0],
                  t: 331,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 280.974, 0],
                  t: 332,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 275.719, 0],
                  t: 333,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 271.384, 0],
                  t: 334,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 268.174, 0],
                  t: 335,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 266.28, 0],
                  t: 336,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 265.533, 0],
                  t: 337,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.362, 0],
                  t: 338,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 262.766, 0],
                  t: 339,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 260.928, 0],
                  t: 340,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 259.029, 0],
                  t: 341,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.233, 0],
                  t: 342,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.682, 0],
                  t: 343,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.515, 0],
                  t: 344,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.844, 0],
                  t: 345,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 253.775, 0],
                  t: 346,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 254.397, 0],
                  t: 347,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 255.777, 0],
                  t: 348,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 257.975, 0],
                  t: 349,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 261.027, 0],
                  t: 350,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 264.963, 0],
                  t: 351,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 269.785, 0],
                  t: 352,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 275.486, 0],
                  t: 353,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 282.041, 0],
                  t: 354,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 289.404, 0],
                  t: 355,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 297.52, 0],
                  t: 356,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 306.32, 0],
                  t: 357,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 315.688, 0],
                  t: 358,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
                {
                  s: [8, 317, 0],
                  t: 359,
                  i: { x: 1, y: 1 },
                  o: { x: 0, y: 0 },
                },
              ],
              l: 2,
            },
            a: { a: 0, k: [-10, -4.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'ALGORITHM',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 16,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'MainLine',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [8, 315, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 2],
                        [0, -183],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                k: [
                  {
                    s: [0],
                    t: 204,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [2.642],
                    t: 205,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [6.928],
                    t: 206,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.194],
                    t: 207,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [15.439],
                    t: 208,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.646],
                    t: 209,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [23.786],
                    t: 210,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.816],
                    t: 211,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.686],
                    t: 212,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [35.336],
                    t: 213,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.696],
                    t: 214,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [41.691],
                    t: 215,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.236],
                    t: 216,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.47],
                    t: 217,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [49.294],
                    t: 218,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.684],
                    t: 219,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.498],
                    t: 220,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.605],
                    t: 221,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [64.883],
                    t: 222,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [69.223],
                    t: 223,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [73.522],
                    t: 224,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [77.692],
                    t: 225,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.649],
                    t: 226,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [85.325],
                    t: 227,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [88.657],
                    t: 228,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [91.595],
                    t: 229,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [94.095],
                    t: 230,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [96.127],
                    t: 231,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [97.666],
                    t: 232,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [98.699],
                    t: 233,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.221],
                    t: 234,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [99.236],
                    t: 235,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [98.758],
                    t: 236,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [97.808],
                    t: 237,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [96.418],
                    t: 238,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [94.627],
                    t: 239,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [92.481],
                    t: 240,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [90.045],
                    t: 241,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [87.387],
                    t: 242,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [84.581],
                    t: 243,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [81.704],
                    t: 244,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [78.845],
                    t: 245,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [76.099],
                    t: 246,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [73.522],
                    t: 247,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [70.99],
                    t: 248,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [68.536],
                    t: 249,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [66.203],
                    t: 250,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [64.03],
                    t: 251,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [62.046],
                    t: 252,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [60.271],
                    t: 253,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [58.72],
                    t: 254,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [57.401],
                    t: 255,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.317],
                    t: 256,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.463],
                    t: 257,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.83],
                    t: 258,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.406],
                    t: 259,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.173],
                    t: 260,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.111],
                    t: 261,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.196],
                    t: 262,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.4],
                    t: 263,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.697],
                    t: 264,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.056],
                    t: 265,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.837],
                    t: 267,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.195],
                    t: 268,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.491],
                    t: 269,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.695],
                    t: 270,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.777],
                    t: 271,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.712],
                    t: 272,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.475],
                    t: 273,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [56.045],
                    t: 274,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [55.404],
                    t: 275,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [54.54],
                    t: 276,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [53.509],
                    t: 277,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [52.578],
                    t: 278,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [51.724],
                    t: 279,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.89],
                    t: 280,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [50.028],
                    t: 281,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [49.097],
                    t: 282,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [48.06],
                    t: 283,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [46.887],
                    t: 284,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [45.555],
                    t: 285,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [44.045],
                    t: 286,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [42.343],
                    t: 287,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [40.441],
                    t: 288,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [38.334],
                    t: 289,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [36.022],
                    t: 290,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.509],
                    t: 291,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.802],
                    t: 292,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.911],
                    t: 293,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.85],
                    t: 294,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [21.634],
                    t: 295,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.283],
                    t: 296,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.815],
                    t: 297,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [11.254],
                    t: 298,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [7.622],
                    t: 299,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [3.944],
                    t: 300,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.246],
                    t: 301,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 302,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 326,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.986],
                    t: 327,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [4.972],
                    t: 328,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [8.893],
                    t: 329,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [12.672],
                    t: 330,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [16.228],
                    t: 331,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [19.474],
                    t: 332,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [22.316],
                    t: 333,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [24.658],
                    t: 334,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [26.395],
                    t: 335,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.417],
                    t: 336,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [27.822],
                    t: 337,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.454],
                    t: 338,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [29.318],
                    t: 339,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.31],
                    t: 340,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.336],
                    t: 341,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [32.308],
                    t: 342,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.146],
                    t: 343,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.777],
                    t: 344,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.139],
                    t: 345,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [34.176],
                    t: 346,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.841],
                    t: 347,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [33.095],
                    t: 348,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [31.907],
                    t: 349,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [30.256],
                    t: 350,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [28.13],
                    t: 351,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [25.524],
                    t: 352,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [22.443],
                    t: 353,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [18.9],
                    t: 354,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [14.919],
                    t: 355,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [10.531],
                    t: 356,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [5.779],
                    t: 357,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.712],
                    t: 358,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0],
                    t: 359,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 28,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  fonts: {
    list: [
      {
        fName: 'Berkeley Mono',
        fFamily: 'Berkeley Mono',
        fStyle: 'Regular',
        ascent: 73.7996826171875,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Oval',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [240, 310, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.05, 0.05], y: [1, 1] },
                o: { x: [0.5, 0.5], y: [0, 0] },
                t: 240,
                s: [400, 0],
              },
              { t: 300, s: [400, 96] },
            ],
            ix: 2,
          },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 1, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 240,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Mask',
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [240, 310, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ind: 0,
          ty: 'sh',
          ix: 1,
          ks: {
            a: 0,
            k: {
              i: [
                [-38.916, 0],
                [-25.017, -2.701],
                [33, 0],
                [110.457, 0],
                [0, 26.51],
                [-79.042, 0],
              ],
              o: [
                [29.212, 0],
                [69.578, 7.513],
                [0, 26.51],
                [-110.457, 0],
                [-25, 0],
                [33.458, 0],
              ],
              v: [
                [-3.5, -287.5],
                [176.978, -289.295],
                [200, 0],
                [0, 48],
                [-200, 0],
                [-177.958, -286.218],
              ],
              c: true,
            },
            ix: 2,
          },
          nm: 'Path 1',
          mn: 'ADBE Vector Shape - Group',
          hd: false,
        },
        {
          ty: 'fl',
          c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        },
      ],
      ip: 240,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: 'Line 13',
      tt: 1,
      tp: 2,
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 0.05 },
              o: { x: 0.5, y: 0.5 },
              t: 240,
              s: [440, 153, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [440, 153, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: 'Line 12',
      tt: 1,
      tp: 2,
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 240,
              s: [406, 154, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [406, 184, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      nm: 'Line 11',
      tt: 1,
      tp: 2,
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 240,
              s: [374, 154, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [374, 191, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 0,
      nm: 'Line 10',
      tt: 1,
      tp: 2,
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 240,
              s: [341, 154, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [341, 194, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: 'Line 9',
      tt: 1,
      tp: 2,
      refId: 'comp_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 240,
              s: [307, 152, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [307, 199, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: 'Line 8',
      tt: 1,
      tp: 2,
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 240,
              s: [275, 154, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [275, 201, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: 'Line 7',
      tt: 1,
      tp: 2,
      refId: 'comp_6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 240,
              s: [242, 154, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [242, 203, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 0,
      nm: 'Line 6',
      tt: 1,
      tp: 2,
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 240,
              s: [209, 154, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [209, 201, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 0,
      nm: 'Line 5',
      tt: 1,
      tp: 2,
      refId: 'comp_8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 240,
              s: [177, 154, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [177, 198, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 0,
      nm: 'Line 4',
      tt: 1,
      tp: 2,
      refId: 'comp_9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 240,
              s: [143, 154, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [143, 195, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 0,
      nm: 'Line 3',
      tt: 1,
      tp: 2,
      refId: 'comp_10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 240,
              s: [110, 154, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [110, 191, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 0,
      nm: 'Line 2',
      tt: 1,
      tp: 2,
      refId: 'comp_11',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 240,
              s: [77, 154, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [77, 180, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 0,
      nm: 'Line 1',
      tt: 1,
      tp: 2,
      refId: 'comp_12',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 0.05 },
              o: { x: 0.5, y: 0.5 },
              t: 240,
              s: [40, 154, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 300, s: [40, 154, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [8, 160, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 16,
      h: 320,
      ip: 250,
      op: 1140,
      st: 240,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
  chars: [
    {
      ch: 'A',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [4.1, 0],
                      [12.6, 0],
                      [17.5, -17.2],
                      [42.6, -17.2],
                      [47.6, 0],
                      [56.1, 0],
                      [36.6, -68],
                      [23.6, -68],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'A',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.5, -24.7],
                      [29.7, -60.7],
                      [30.5, -60.7],
                      [40.7, -24.7],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'A',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'A',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'L',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [11.6, -0.1],
                      [53.2, -0.1],
                      [53.2, -8],
                      [20.2, -8],
                      [20.2, -68],
                      [11.6, -68],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'L',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'L',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'G',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.8, 0],
                      [-2.834, 0.666],
                      [-3.734, 1.867],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.766, -0.366],
                      [2.4, 0],
                      [2.433, 1.5],
                      [1, 3.867],
                      [0, 7.534],
                      [-0.934, 3.767],
                      [-2.334, 1.534],
                      [-4.4, 0],
                      [-1.834, -1.3],
                      [-0.467, -3.4],
                      [0, 0],
                      [1.433, 2.434],
                      [2.866, 1.067],
                      [4.866, 0],
                      [3.533, -2.033],
                      [1.466, -4.933],
                      [0, -9.133],
                      [-1.534, -5],
                      [-3.667, -2.1],
                    ],
                    o: [
                      [3.8, 0],
                      [2.833, -0.666],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.934, 0.934],
                      [-1.767, 0.367],
                      [-4.6, 0],
                      [-2.434, -1.5],
                      [-1, -3.866],
                      [0, -7.266],
                      [0.933, -3.766],
                      [2.333, -1.533],
                      [4.133, 0],
                      [1.833, 1.3],
                      [0, 0],
                      [-0.4, -4.266],
                      [-1.434, -2.433],
                      [-2.867, -1.066],
                      [-6.6, 0],
                      [-3.534, 2.034],
                      [-1.467, 4.934],
                      [0, 9.4],
                      [1.533, 5],
                      [3.666, 2.1],
                    ],
                    v: [
                      [32.9, 1],
                      [42.85, 0],
                      [52.7, -3.8],
                      [52.7, -36.5],
                      [31.2, -36.5],
                      [31.2, -28.6],
                      [44.7, -28.6],
                      [44.7, -9.4],
                      [39.15, -7.45],
                      [32.9, -6.9],
                      [22.35, -9.15],
                      [17.2, -17.2],
                      [15.7, -34.3],
                      [17.1, -50.85],
                      [22, -58.8],
                      [32.1, -61.1],
                      [41.05, -59.15],
                      [44.5, -52.1],
                      [52.8, -52.1],
                      [50.05, -62.15],
                      [43.6, -67.4],
                      [32, -69],
                      [16.8, -65.95],
                      [9.3, -55.5],
                      [7.1, -34.4],
                      [9.4, -12.8],
                      [17.2, -2.15],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'G',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'G',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'O',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.134, 0],
                      [-3.367, 2.1],
                      [-1.434, 4.967],
                      [0, 9.067],
                      [1.433, 5],
                      [3.366, 2.134],
                      [6.133, 0],
                      [3.366, -2.1],
                      [1.433, -5.033],
                      [0, -9.2],
                      [-1.434, -4.966],
                      [-3.4, -2.1],
                    ],
                    o: [
                      [6.133, 0],
                      [3.366, -2.1],
                      [1.433, -4.966],
                      [0, -9.2],
                      [-1.434, -5],
                      [-3.367, -2.133],
                      [-6.2, 0],
                      [-3.367, 2.1],
                      [-1.434, 5.034],
                      [0, 9.067],
                      [1.433, 4.967],
                      [3.4, 2.1],
                    ],
                    v: [
                      [30, 1],
                      [44.25, -2.15],
                      [51.45, -12.75],
                      [53.6, -33.8],
                      [51.45, -55.1],
                      [44.25, -65.8],
                      [30, -69],
                      [15.65, -65.85],
                      [8.45, -55.15],
                      [6.3, -33.8],
                      [8.45, -12.75],
                      [15.7, -2.15],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'O',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 7.134],
                      [-0.9, 3.934],
                      [-2.134, 1.634],
                      [-4, 0],
                      [-2.134, -1.633],
                      [-0.9, -3.933],
                      [0, -7.2],
                      [0.9, -3.833],
                      [2.133, -1.633],
                      [3.933, 0],
                      [2.133, 1.634],
                      [0.9, 3.834],
                    ],
                    o: [
                      [0, -7.2],
                      [0.9, -3.933],
                      [2.133, -1.633],
                      [3.933, 0],
                      [2.133, 1.634],
                      [0.9, 3.934],
                      [0, 7.134],
                      [-0.9, 3.834],
                      [-2.134, 1.634],
                      [-4, 0],
                      [-2.134, -1.633],
                      [-0.9, -3.833],
                    ],
                    v: [
                      [14.9, -33.8],
                      [16.25, -50.5],
                      [20.8, -58.85],
                      [30, -61.3],
                      [39.1, -58.85],
                      [43.65, -50.5],
                      [45, -33.8],
                      [43.65, -17.35],
                      [39.1, -9.15],
                      [30, -6.7],
                      [20.8, -9.15],
                      [16.25, -17.35],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'O',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'O',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'R',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2, -1.333],
                      [-0.8, -3.666],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.4, 1.7],
                      [3.066, 0.534],
                      [0, 0],
                      [-1.834, 2.4],
                      [0, 5.067],
                      [1.466, 2.834],
                      [3.833, 1.3],
                      [7.4, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.666, 0],
                      [2, 1.334],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.134, -4.2],
                      [-1.4, -1.7],
                      [0, 0],
                      [4.466, -0.533],
                      [1.833, -2.4],
                      [0, -5.2],
                      [-1.467, -2.833],
                      [-3.834, -1.3],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9, 0],
                      [17.6, 0],
                      [17.6, -29.3],
                      [25.9, -29.3],
                      [35.9, -27.3],
                      [40.1, -19.8],
                      [44.5, 0],
                      [54, 0],
                      [48.8, -19.8],
                      [45, -28.65],
                      [38.3, -32],
                      [38.3, -32.6],
                      [47.75, -37],
                      [50.5, -48.2],
                      [48.3, -60.25],
                      [40.35, -66.45],
                      [23.5, -68.4],
                      [19, -68.3],
                      [9, -68],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'R',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [-2.267, 0],
                      [-2.2, -0.266],
                      [-1.434, -0.7],
                      [-0.567, -1.433],
                      [0, -2.8],
                      [1.4, -1.733],
                      [3.733, -0.066],
                    ],
                    o: [
                      [0, 0],
                      [3, -0.266],
                      [2, 0],
                      [2.866, 0.334],
                      [1.433, 0.7],
                      [0.566, 1.434],
                      [0, 4.4],
                      [-1.4, 1.734],
                      [0, 0],
                    ],
                    v: [
                      [17.6, -37.2],
                      [17.6, -60.3],
                      [25.5, -60.7],
                      [31.8, -60.3],
                      [38.25, -58.75],
                      [41.25, -55.55],
                      [42.1, -49.2],
                      [40, -40],
                      [32.3, -37.3],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'R',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'R',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'I',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [12.9, 0],
                      [47, 0],
                      [47, -7.9],
                      [34.3, -7.9],
                      [34.3, -60.1],
                      [46.4, -60.1],
                      [46.4, -68],
                      [13.6, -68],
                      [13.6, -60.1],
                      [25.7, -60.1],
                      [25.7, -7.9],
                      [12.9, -7.9],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'I',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'I',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'T',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [25.6, 0],
                      [34.2, 0],
                      [34.2, -60.1],
                      [52.7, -60.1],
                      [52.7, -68],
                      [7.2, -68],
                      [7.2, -60.1],
                      [25.6, -60.1],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'T',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'T',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'H',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.8, 0],
                      [16.4, 0],
                      [16.4, -31.5],
                      [43.6, -31.5],
                      [43.6, 0],
                      [52.2, 0],
                      [52.2, -68],
                      [43.6, -68],
                      [43.6, -39.4],
                      [16.4, -39.4],
                      [16.4, -68],
                      [7.8, -68],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'H',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'H',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'M',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [5.7, 0],
                      [13.9, 0],
                      [13.9, -42.4],
                      [12.9, -57.2],
                      [13.4, -57.2],
                      [25.9, -24.1],
                      [34, -24.1],
                      [46.5, -57.2],
                      [47, -57.2],
                      [46, -42.4],
                      [46, 0],
                      [54.2, 0],
                      [54.2, -68],
                      [42.8, -68],
                      [30.5, -33.8],
                      [29.4, -33.8],
                      [17.1, -68],
                      [5.7, -68],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'M',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'M',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'S',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.2, 0],
                      [-3.267, 1.033],
                      [-1.367, 2.467],
                      [0, 4.6],
                      [1.1, 2.5],
                      [2.7, 1.734],
                      [5.133, 1.8],
                      [2.533, 1.234],
                      [1.066, 1.467],
                      [0, 2.267],
                      [-0.834, 1.234],
                      [-2.034, 0.567],
                      [-3.734, 0],
                      [-1.834, -0.466],
                      [-0.867, -1.2],
                      [-0.267, -2.333],
                      [0, 0],
                      [1.366, 2.267],
                      [3.066, 0.934],
                      [5.733, 0],
                      [3.166, -0.966],
                      [1.333, -2.3],
                      [0, -4.333],
                      [-1.134, -2.3],
                      [-2.7, -1.633],
                      [-5.267, -1.666],
                      [-2.534, -1.366],
                      [-1.067, -1.733],
                      [0, -2.8],
                      [0.866, -1.4],
                      [2, -0.566],
                      [3.533, 0],
                      [2.066, 0.6],
                      [0.966, 1.534],
                      [0.266, 3],
                      [0, 0],
                      [-1.367, -2.633],
                      [-3.267, -1.066],
                    ],
                    o: [
                      [6.2, 0],
                      [3.266, -1.033],
                      [1.366, -2.466],
                      [0, -4.2],
                      [-1.1, -2.5],
                      [-2.7, -1.733],
                      [-5.134, -1.733],
                      [-2.534, -1.233],
                      [-1.067, -1.466],
                      [0, -2.266],
                      [0.833, -1.233],
                      [2.033, -0.566],
                      [3.333, 0],
                      [1.833, 0.467],
                      [0.866, 1.2],
                      [0, 0],
                      [-0.267, -4.333],
                      [-1.367, -2.266],
                      [-3.067, -0.933],
                      [-5.867, 0],
                      [-3.167, 0.967],
                      [-1.334, 2.3],
                      [0, 3.8],
                      [1.133, 2.3],
                      [2.7, 1.634],
                      [4.933, 1.534],
                      [2.533, 1.367],
                      [1.066, 1.734],
                      [0, 2.667],
                      [-0.867, 1.4],
                      [-2, 0.567],
                      [-4, 0],
                      [-2.067, -0.6],
                      [-0.967, -1.533],
                      [0, 0],
                      [0, 4.934],
                      [1.366, 2.634],
                      [3.266, 1.066],
                    ],
                    v: [
                      [30, 0.7],
                      [44.2, -0.85],
                      [51.15, -6.1],
                      [53.2, -16.7],
                      [51.55, -26.75],
                      [45.85, -33.1],
                      [34.1, -38.4],
                      [22.6, -42.85],
                      [17.2, -46.9],
                      [15.6, -52.5],
                      [16.85, -57.75],
                      [21.15, -60.45],
                      [29.8, -61.3],
                      [37.55, -60.6],
                      [41.6, -58.1],
                      [43.3, -52.8],
                      [51.9, -52.8],
                      [49.45, -62.7],
                      [42.8, -67.5],
                      [29.6, -68.9],
                      [16.05, -67.45],
                      [9.3, -62.55],
                      [7.3, -52.6],
                      [9, -43.45],
                      [14.75, -37.55],
                      [26.7, -32.6],
                      [37.9, -28.25],
                      [43.3, -23.6],
                      [44.9, -16.8],
                      [43.6, -10.7],
                      [39.3, -7.75],
                      [31, -6.9],
                      [21.9, -7.8],
                      [17.35, -11],
                      [15.5, -17.8],
                      [6.8, -17.8],
                      [8.85, -6.45],
                      [15.8, -0.9],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'S',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'S',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'P',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.5, 1.267],
                      [-1.5, 2.967],
                      [0, 5.334],
                      [1.733, 3.067],
                      [4.066, 1.267],
                      [7.666, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [6.4, 0],
                      [3.5, -1.266],
                      [1.5, -2.966],
                      [0, -5.733],
                      [-1.734, -3.066],
                      [-4.067, -1.266],
                      [0, 0],
                    ],
                    v: [
                      [10.9, -68],
                      [10.9, 0],
                      [19.5, 0],
                      [19.5, -26],
                      [28.3, -26],
                      [43.15, -27.9],
                      [50.65, -34.25],
                      [52.9, -46.7],
                      [50.3, -59.9],
                      [41.6, -66.4],
                      [24, -68.3],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'P',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.634, -0.766],
                      [-1.1, -1.9],
                      [0, -3.533],
                      [1.033, -1.9],
                      [2.433, -0.8],
                      [4.4, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [5, 0],
                      [2.633, 0.767],
                      [1.1, 1.9],
                      [0, 3.467],
                      [-1.034, 1.9],
                      [-2.434, 0.8],
                      [0, 0],
                    ],
                    v: [
                      [19.5, -33.9],
                      [19.5, -60.5],
                      [25.7, -60.5],
                      [37.15, -59.35],
                      [42.75, -55.35],
                      [44.4, -47.2],
                      [42.85, -39.15],
                      [37.65, -35.1],
                      [27.4, -33.9],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'P',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'P',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'E',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [11.4, 0],
                      [51.1, 0],
                      [51.1, -7.8],
                      [20, -7.8],
                      [20, -31.5],
                      [45.3, -31.5],
                      [45.3, -39.3],
                      [20, -39.3],
                      [20, -60.2],
                      [49.1, -60.2],
                      [49.1, -68],
                      [11.4, -68],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'E',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'E',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'C',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.4, 0],
                      [-2.867, 1.033],
                      [-1.534, 2.467],
                      [-0.4, 4.334],
                      [0, 0],
                      [1.733, -1.4],
                      [3.933, 0],
                      [2.233, 1.634],
                      [0.933, 3.934],
                      [0, 7.4],
                      [-0.934, 3.9],
                      [-2.3, 1.6],
                      [-4.267, 0],
                      [-1.4, -0.5],
                      [-0.9, -1.466],
                      [-0.467, -2.866],
                      [0, 0],
                      [1.466, 2.534],
                      [2.766, 1.067],
                      [4.6, 0],
                      [3.433, -2.1],
                      [1.433, -4.966],
                      [0, -9.266],
                      [-1.434, -4.966],
                      [-3.467, -2.066],
                    ],
                    o: [
                      [4.6, 0],
                      [2.866, -1.033],
                      [1.533, -2.466],
                      [0, 0],
                      [-0.667, 3.667],
                      [-1.734, 1.4],
                      [-4.2, 0],
                      [-2.234, -1.633],
                      [-0.934, -3.933],
                      [0, -7.4],
                      [0.933, -3.9],
                      [2.3, -1.6],
                      [2.4, 0],
                      [1.4, 0.5],
                      [0.9, 1.467],
                      [0, 0],
                      [-0.4, -4.466],
                      [-1.467, -2.533],
                      [-2.767, -1.066],
                      [-6.4, 0],
                      [-3.434, 2.1],
                      [-1.434, 4.967],
                      [0, 9.2],
                      [1.433, 4.967],
                      [3.466, 2.067],
                    ],
                    v: [
                      [32.6, 1],
                      [43.8, -0.55],
                      [50.4, -5.8],
                      [53.3, -16],
                      [44.8, -16],
                      [41.2, -8.4],
                      [32.7, -6.3],
                      [23.05, -8.75],
                      [18.3, -17.1],
                      [16.9, -34.1],
                      [18.3, -51.05],
                      [23.15, -59.3],
                      [33, -61.7],
                      [38.7, -60.95],
                      [42.15, -58],
                      [44.2, -51.5],
                      [52.7, -51.5],
                      [49.9, -62],
                      [43.55, -67.4],
                      [32.5, -69],
                      [17.75, -65.85],
                      [10.45, -55.25],
                      [8.3, -33.9],
                      [10.45, -12.65],
                      [17.8, -2.1],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'C',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'C',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'F',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [11.2, 0],
                      [19.8, 0],
                      [19.8, -30.1],
                      [48, -30.1],
                      [48, -38],
                      [19.8, -38],
                      [19.8, -60.1],
                      [51.9, -60.1],
                      [51.9, -68],
                      [11.2, -68],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'F',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'F',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'B',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-5.8, 0],
                      [-3.834, 1.133],
                      [-1.6, 2.667],
                      [0, 4.934],
                      [0.733, 2.167],
                      [1.733, 1.334],
                      [3.266, 0.934],
                      [0, 0],
                      [-1.334, 1.034],
                      [-0.567, 1.9],
                      [0, 3.2],
                      [1.633, 2.567],
                      [3.9, 1.067],
                      [7.2, 0],
                      [11.733, -0.2],
                      [0, 0],
                    ],
                    o: [
                      [7, 0],
                      [3.833, -1.133],
                      [1.6, -2.666],
                      [0, -3.666],
                      [-0.734, -2.166],
                      [-1.734, -1.333],
                      [0, 0],
                      [2.533, -0.6],
                      [1.333, -1.033],
                      [0.566, -1.9],
                      [0, -4.733],
                      [-1.634, -2.566],
                      [-3.9, -1.066],
                      [2.666, 0],
                      [0, 0],
                      [5.533, 0.533],
                    ],
                    v: [
                      [26, 0.8],
                      [42.25, -0.9],
                      [50.4, -6.6],
                      [52.8, -18],
                      [51.7, -26.75],
                      [48, -32],
                      [40.5, -35.4],
                      [40.5, -36],
                      [46.3, -38.45],
                      [49.15, -42.85],
                      [50, -50.5],
                      [47.55, -61.45],
                      [39.25, -66.9],
                      [22.6, -68.5],
                      [9, -68.2],
                      [9, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'B',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [2.733, 0.334],
                      [0, 0],
                      [0, 0],
                      [-1.967, -0.766],
                      [-0.834, -1.766],
                      [0, -3.2],
                      [1.1, -1.733],
                      [2.6, -0.633],
                      [4.866, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [3.666, 0],
                      [1.966, 0.767],
                      [0.833, 1.767],
                      [0, 3.4],
                      [-1.1, 1.734],
                      [-2.6, 0.634],
                      [-2.867, 0],
                    ],
                    v: [
                      [17.4, -7.3],
                      [17.4, -31.4],
                      [30.3, -31.4],
                      [38.75, -30.25],
                      [42.95, -26.45],
                      [44.2, -19],
                      [42.55, -11.3],
                      [37, -7.75],
                      [25.8, -6.8],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'B',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 2,
                ty: 'sh',
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [-2.2, 0],
                      [-2.4, -0.2],
                      [-1.6, -0.8],
                      [-0.634, -1.533],
                      [0, -2.6],
                      [1.266, -1.533],
                      [3.4, -0.133],
                    ],
                    o: [
                      [0, 0],
                      [1, -0.066],
                      [3.133, 0],
                      [2.933, 0.2],
                      [1.6, 0.8],
                      [0.633, 1.534],
                      [0, 3.934],
                      [-1.267, 1.534],
                      [0, 0],
                    ],
                    v: [
                      [17.4, -38.8],
                      [17.4, -60.9],
                      [22.2, -61],
                      [30.5, -60.7],
                      [37.3, -59.2],
                      [40.65, -55.7],
                      [41.6, -49.5],
                      [39.7, -41.3],
                      [32.7, -38.8],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'B',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'B',
            np: 6,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'N',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.3, 0],
                      [15.7, 0],
                      [15.7, -42.4],
                      [14.4, -56.2],
                      [14.9, -56.2],
                      [42.4, 0],
                      [52.6, 0],
                      [52.6, -68],
                      [44.2, -68],
                      [44.2, -25.6],
                      [45.5, -11.8],
                      [45, -11.8],
                      [17.4, -68],
                      [7.3, -68],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'N',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'N',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'D',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.867, 0],
                      [-4.267, 2],
                      [-1.767, 4.767],
                      [0, 9],
                      [1.566, 4.734],
                      [3.666, 2.267],
                      [6.8, 0.734],
                      [3.866, 0],
                      [2.466, -0.266],
                      [0, 0],
                      [-2.267, -0.134],
                    ],
                    o: [
                      [8, 0],
                      [4.266, -2],
                      [1.766, -4.766],
                      [0, -8.533],
                      [-1.567, -4.733],
                      [-3.667, -2.266],
                      [-2.8, -0.333],
                      [-3.467, 0],
                      [0, 0],
                      [2.4, 0.266],
                      [2.266, 0.133],
                    ],
                    v: [
                      [23, 0.8],
                      [41.4, -2.2],
                      [50.45, -12.35],
                      [53.1, -33],
                      [50.75, -52.9],
                      [42.9, -63.4],
                      [27.2, -67.9],
                      [17.2, -68.4],
                      [8.3, -68],
                      [8.3, 0],
                      [15.3, 0.6],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'D',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [-3.334, -0.466],
                      [-2.467, -1.7],
                      [-1, -3.5],
                      [0, -6.466],
                      [1.033, -3.8],
                      [2.3, -1.666],
                      [4, -0.333],
                      [0.666, 0],
                      [2.666, 0.134],
                    ],
                    o: [
                      [0, 0],
                      [3.466, -0.133],
                      [4.666, 0.6],
                      [2.466, 1.7],
                      [1, 3.5],
                      [0, 6.867],
                      [-1.034, 3.8],
                      [-2.3, 1.667],
                      [-2, 0.134],
                      [-0.6, 0],
                      [0, 0],
                    ],
                    v: [
                      [16.9, -7.1],
                      [16.9, -60.8],
                      [27.1, -60.3],
                      [37.8, -56.85],
                      [43, -49.05],
                      [44.5, -34.1],
                      [42.95, -18.1],
                      [37.95, -9.9],
                      [28.5, -6.9],
                      [24.5, -6.7],
                      [19.6, -6.9],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'D',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'D',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'W',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [10.8, 0],
                      [20.4, 0],
                      [29.8, -61.2],
                      [30.2, -61.2],
                      [39.6, 0],
                      [49.2, 0],
                      [58.2, -68],
                      [50.1, -68],
                      [44.2, -10.5],
                      [43.8, -10.5],
                      [43.1, -19.9],
                      [34.9, -68],
                      [25, -68],
                      [16.8, -19.9],
                      [16.2, -10.5],
                      [15.8, -10.5],
                      [9.8, -68],
                      [1.7, -68],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'W',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'W',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'U',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-5.867, 0],
                      [-3.067, 1.333],
                      [-1.3, 3.2],
                      [0, 5.867],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.766, -2.1],
                      [1.833, -0.866],
                      [3.4, 0],
                      [1.966, 0.867],
                      [0.833, 2.134],
                      [0, 3.934],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.367, -3.166],
                      [-3.167, -1.333],
                    ],
                    o: [
                      [5.6, 0],
                      [3.066, -1.333],
                      [1.3, -3.2],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 3.934],
                      [-0.767, 2.1],
                      [-1.834, 0.867],
                      [-3.6, 0],
                      [-1.967, -0.866],
                      [-0.834, -2.133],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 5.867],
                      [1.366, 3.167],
                      [3.166, 1.333],
                    ],
                    v: [
                      [30.4, 0.9],
                      [43.4, -1.1],
                      [49.95, -7.9],
                      [51.9, -21.5],
                      [51.9, -68],
                      [43.3, -68],
                      [43.3, -21.8],
                      [42.15, -12.75],
                      [38.25, -8.3],
                      [30.4, -7],
                      [22.05, -8.3],
                      [17.85, -12.8],
                      [16.6, -21.9],
                      [16.6, -68],
                      [8, -68],
                      [8, -21.4],
                      [10.05, -7.85],
                      [16.85, -1.1],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'U',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'U',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'V',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [24.3, 0],
                      [35.6, 0],
                      [56.2, -68],
                      [47.6, -68],
                      [30.4, -9.5],
                      [29.5, -9.5],
                      [12.3, -68],
                      [3.7, -68],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'V',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'V',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: 'Z',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8, 0],
                      [51.9, 0],
                      [51.9, -7.9],
                      [17.7, -7.9],
                      [51.9, -61.1],
                      [51.9, -68],
                      [9.7, -68],
                      [9.7, -60.1],
                      [42, -60.1],
                      [8, -6.9],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Z',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'Z',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
  ],
}

import Module from './module'
import { LottieAnimate } from '../animations'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { hole } from '../lottie'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Unlimited extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    this.lottie = {
      hole: {
        anim: null,
        data: hole,
        el: qs('.unlimited-block.block-1'),
      },
    }
  }

  setAnimation(splitText) {
    const { hole } = this.lottie
    const title = splitText[0].lines
    const description = splitText[1].lines

    // * Setup Scroll Animation

    this.tl = gsap.timeline({ paused: true })

    title.forEach((line, i) => {
      const chars = qsa('.char-1', line)
      this.tl.from(
        chars,
        { duration: 0.6, y: '101%', stagger: 0.035, ease: 'power2.inOut' },
        `-=${i * 0.8}`,
      )
    })

    this.tl.from(
      description,
      { duration: 0.5, y: 20, autoAlpha: 0, stagger: 0.1 },
      '-=0.5',
    )

    // * Setup Lottie Animation

    hole.anim = new LottieAnimate({
      container: hole.el,
      data: hole.data,
      loop: true,
      autoplay: false,
    })

    hole.anim.load()
  }

  setTrigger() {
    const { hole } = this.lottie
    const { el } = this.dom
    if (!el) return

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=50%',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=100%',
      end: 'bottom bottom-=100%',
      onEnter: () => {
        hole.anim.play()
      },
      onLeave: () => {
        hole.anim.pause()
      },
      onEnterBack: () => {
        hole.anim.play()
      },
      onLeaveBack: () => {
        hole.anim.pause()
      },
    })
  }
}

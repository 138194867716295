import Module from './module'
import { Emitter } from '../core'
import { TunnelGL, Stick } from '../components'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Tunnel extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      page: qs('.page'),
      background: qs('.tunnel-background', el),
      innerBackground: qs('.bg-inner', el),
      sticky: qs('[data-stick]', el),
      stick: qs('.sticky', el),
      content: qsa('.sticky-content', el),
    })

    // * Tunnel Canvas Effect

    this.tunnel = new TunnelGL()

    // * Sticky makes the section sticky / fixed

    this.sticky = new Stick({
      container: el,
      sticky: this.dom.stick,
      items: this.dom.content,
      name: this.dom.stick.parentNode,
    })
  }

  run() {
    if (!this.state.isPreVisible) return

    // * Scaling Tunnel content based on sticky progress

    const { innerBackground } = this.dom
    const content = this.sticky.dom.items[0]

    gsap.to(content, {
      scaleX: 1 - this.sticky.progress + 0.5 * this.sticky.progress,
      scaleY: 1 - this.sticky.progress + 0.5 * this.sticky.progress,
    })

    gsap.to(innerBackground, {
      scaleX: 1.5 - this.sticky.progress + 0.44 * this.sticky.progress,
      scaleY: 1.5 - this.sticky.progress + 0.44 * this.sticky.progress,
    })
  }

  setAnimation(splitText) {
    const { page, background } = this.dom
    const title = splitText[0].words
    const description = splitText[1].lines

    gsap.set(page, { autoAlpha: 1 })

    const hero = gsap.timeline({
      paused: true,
      onComplete: () => {
        Emitter.emit('animateIn:ended')
      },
    })

    hero.from(background, { duration: 0.5, autoAlpha: 0 })
    hero.from(title, {
      duration: 0.8,
      autoAlpha: 0,
      stagger: {
        each: 0.1,
        from: 'random',
      },
    })

    hero.from(
      description,
      {
        autoAlpha: 0,
        duration: 0.8,
        yPercent: 100,
        stagger: 0.1,
      },
      '-=1',
    )

    hero.play()
  }

  destroy() {
    this.tunnel && this.tunnel.destroy()
  }
}

import * as THREE from 'three'
import { Emitter } from '../core'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { bounds } from '../utils'
import store from '../store'

const vertex = `
  precision mediump float;
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`
const fragment = `
  precision mediump float;
  varying vec2 vUv;
  uniform vec2 uResolution;
  uniform float uTime;

  void main() {
    vec2 uv = vUv;
    gl_FragColor = vec4( vec3(1., 0., 0.), 1. );
  }
`

export default class WebGL {
  constructor(obj) {
    const container = obj.container
    const canvas = obj.canvas

    this.bgContext = {
      canvas: canvas,
      container: container,
    }

    this.uniforms = {
      uTime: { value: 0 },
      uResolution: { value: [0, 0] },
    }

    this.sizes = {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
    }

    this.init()
  }

  setup() {
    const scene = new THREE.Scene()
    const clock = new THREE.Clock()
    const textureLoader = new THREE.TextureLoader()

    Object.assign(this.bgContext, { scene, clock, textureLoader })
  }

  setCamera() {
    const { scene, canvas } = this.bgContext
    const { width, height } = this.sizes
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    const controls = new OrbitControls(camera, canvas)

    camera.position.set(0, 0, 6)
    controls.target.set(0, 0, 0)
    controls.enableDamping = true
    controls.enabled = true
    scene.add(camera)

    Object.assign(this.bgContext, { camera, controls })
  }

  setRenderer() {
    const { canvas } = this.bgContext
    const { width, height } = this.sizes
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true,
      //depth: false,
      stencil: false,
    })

    renderer.outputColorSpace = THREE.LinearSRGBColorSpace
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(width, height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    Object.assign(this.bgContext, { renderer })
  }

  updateSize() {
    const { mesh } = this.bgContext
    this.viewSize = this.getViewSize()
    mesh.scale.x = this.viewSize.width
    mesh.scale.y = this.viewSize.height
  }

  setMesh() {
    const { scene } = this.bgContext
    const plane = new THREE.PlaneGeometry(1, 1)
    const material = new THREE.ShaderMaterial({
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: this.uniforms,
      transparent: true,
    })
    const mesh = new THREE.Mesh(plane, material)
    scene.add(mesh)

    Object.assign(this.bgContext, { mesh })
  }

  tick = (obj) => {
    if (this.isResizing) return
    const { camera, renderer, controls, scene } = this.bgContext
    this.update(obj)
    controls.update()
    renderer.render(scene, camera)
  }

  update(obj) {
    const { clock } = this.bgContext
    const elapsedTime = clock.getElapsedTime()
    this.uniforms.uTime.value = elapsedTime
  }

  getViewSize() {
    const { camera } = this.bgContext
    const fovInRadians = (camera.fov * Math.PI) / 180
    const height = Math.abs(camera.position.z * Math.tan(fovInRadians / 2) * 2)
    return { width: height * camera.aspect, height }
  }

  on() {
    Emitter.on('tick', this.tick)
    Emitter.on('resize', this.resize)
  }

  off() {
    Emitter.off('tick', this.tick)
    Emitter.off('resize', this.resize)
  }

  resize = () => {
    this.setResize()
  }

  setResize() {
    const { sizes } = store
    const { container, camera, renderer } = this.bgContext
    const rect = bounds(container)
    const width = rect.width
    const height = rect.height
    const top = rect.top
    const left = rect.left

    this.sizes.width = width
    this.sizes.height = height
    this.sizes.top = top
    this.sizes.left = left
    this.isResizing = true
    camera.aspect = width / height
    camera.updateProjectionMatrix()
    this.uniforms.uResolution.value = [width, height]
    this.bounds = {
      left: 0,
      top: 0,
      width: width,
      height: height,
    }

    // Update renderer
    renderer.setSize(width, height)
    renderer.setPixelRatio(2)
    this.updateSize()
    this.isResizing = false
  }

  destroy() {
    this.off()
  }

  init() {
    this.setup()
    this.setCamera()
    this.setRenderer()
    this.setMesh()
    this.on()
    this.resize()
  }
}

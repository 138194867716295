export default {
  title: 'Integrated Reasoning',
  author: {
    name: 'Non-Linear',
    link: 'https://www.non-linear.studio/',
  },
  body: document.body,
  page: null,
  sizes: {
    vw: 0,
    vh: 0,
    h: 0,
  },
  orientation: null,
  sniff: null,
  breakpoints: null,
  flags: {
    smooth: true,
    locked: true,
    resize: false,
  },
  msgs: {
    rotate: null,
  },
}

import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { PressGL, Tabs } from '../components'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Press extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    Object.assign(this.dom, {
      asset: qs('.article-asset.asset-1'),
      canvas: qs('.press-gl'),
      titles: qsa('.press-title .title-item'),
      subtitles: qsa('.article-subtitle .subtitle-item'),
      content: qsa('.article-excerpt .excerpt-item '),
    })
  }

  setAnimation() {
    const { canvas, asset, titles, subtitles, content } = this.dom

    const charsCurrent = qsa('.char-1', titles[0])
    const subtitleCurrent = qsa('.line-0', subtitles[0])

    this.tl = gsap.timeline({ paused: true })

    this.tl.fromTo(
      charsCurrent,
      { autoAlpha: 0, yPercent: 100 },
      { autoAlpha: 1, yPercent: 0, stagger: 0.035 },
      0,
    )

    this.tl.fromTo(
      subtitleCurrent,
      { autoAlpha: 0, yPercent: 100 },
      { autoAlpha: 1, yPercent: 0, stagger: 0.1 },
      0,
    )

    this.tl.fromTo(
      content[0],
      { autoAlpha: 0, yPercent: 100 },
      { autoAlpha: 1, yPercent: 0 },
      0,
    )

    // * Setup changing tabs

    this.tabs = new Tabs()

    // * Setup Assets in 3D

    this.press = new PressGL({
      container: asset,
      canvas: canvas,
    })
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=70%',
      end: 'bottom bottom',
      onEnter: () => {
        this.tl && this.tl.play()
        this.tabs && this.tabs.autoUpdate()
      },
    })
  }

  destroy() {
    this.tabs && this.tabs.destroy()
    this.press && this.press.destroy()
  }
}

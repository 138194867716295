import Module from './module'
import { LottieAnimate } from '../animations'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { circles, team } from '../lottie'
import { qs } from '../utils'
import gsap from 'gsap'

export default class Message extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    this.lottie = {
      circles: {
        data: circles,
        el: qs('.message-circles'),
      },
      team: {
        data: team,
        el: qs('.message-team'),
      },
      lottie: {},
    }
  }

  setAnimation(splitText) {
    const { circles, team, lottie } = this.lottie

    const description = splitText[0].lines

    this.tl = gsap.timeline({ paused: true })
    this.tl.from(description, {
      duration: 0.5,
      y: 20,
      autoAlpha: 0,
      stagger: 0.1,
    })

    // * Setup Lottie Animation

    if (circles.el) {
      lottie.anim = new LottieAnimate({
        container: circles.el,
        data: circles.data,
        loop: true,
        autoplay: false,
      })

      lottie.anim.load()
    }

    if (team.el) {
      lottie.anim = new LottieAnimate({
        container: team.el,
        data: team.data,
        loop: false,
        autoplay: false,
      })

      lottie.anim.load()
    }
  }

  setTrigger() {
    const { el } = this.dom
    const { lottie, circles, team } = this.lottie

    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'top top',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })

    // * Play / Pause Lottie animation

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=100%',
      end: 'bottom bottom-=100%',
      onEnter: () => {
        if (circles.el) lottie.anim.play()

        if (team.el) {
          const anim = lottie.anim.getAnimation()
          lottie.anim.playSegments(0, 90, true)
          anim.addEventListener('complete', () => {
            lottie.anim.playSegments(90, 210, true)
          })
        }
      },
      onLeave: () => {
        if (circles.el) lottie.anim.pause()
      },
      onEnterBack: () => {
        if (circles.el) lottie.anim.play()
      },
      onLeaveBack: () => {
        if (circles.el) lottie.anim.pause()
      },
    })
  }
}

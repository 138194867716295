import { Splits } from '../components'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { qsa } from '../utils'

export default class Module {
  constructor(obj) {
    this.name = obj.name || 'module'

    this.dom = {
      el: obj.el,
    }

    this.state = {
      isVisible: false,
      isPreVisible: false,
      isAnimating: false,
      splitText: obj.splitText || false,
      reverseSplit: obj.reverseSplit || false,
    }

    this.init()
  }

  setup() {}

  setAnimation(splitText) {}

  setTrigger() {}

  setObserver() {
    if (!this.dom.el) return

    // * Visibility Observer

    ScrollTrigger.create({
      trigger: this.dom.el,
      start: 'top top',
      end: 'bottom bottom',
      onEnter: () => {
        this.observerEnter()
      },
      onLeave: () => {
        this.observerLeave()
      },
      onEnterBack: () => {
        this.observerEnter()
      },
      onLeaveBack: () => {
        this.observerLeave()
      },
    })

    // * Split Observer

    ScrollTrigger.create({
      trigger: this.dom.el,
      start: 'top top+=100%',
      end: 'bottom bottom-=100%',
      once: true,
      onEnter: () => {
        this.splitText()
        this.state.isPreVisible = true
      },
      onLeave: () => {
        this.reverseSplit()
        this.state.isPreVisible = true
      },
      onEnterBack: () => {
        this.state.isPreVisible = true
      },
      onLeaveBack: () => {
        this.state.isPreVisible = false
      },
    })
  }

  observerEnter() {
    this.state.isVisible = true
  }

  observerLeave() {
    this.state.isVisible = false
  }

  splitText() {
    const { splitText } = this.state
    if (!splitText) return

    const { el } = this.dom
    const split = qsa('[data-split]', el)

    this.split = new Splits({
      els: split,
    })

    this.split.on('ready', (splitText) => this.setAnimation(splitText))
    this.split.init()
  }

  reverseSplit() {
    const { reverseSplit } = this.state
    if (!reverseSplit) return
    this.split.reverseContent()
  }

  on() {}

  off() {}

  resize() {}

  destroy() {
    this.off()
  }

  init() {
    this.setup()
    this.setObserver()
    this.setTrigger()
    this.on()
  }
}

import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { Form } from '../components'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Forms extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      page: qs('.page'),
      input: qsa('.form-input', el),
      button: qs('.form-button', el),
    })

    // * Form setup for Netlify

    this.form = new Form()

    this.setAnimation()
  }

  setAnimation() {
    const { input, button } = this.dom

    this.tl = gsap.timeline({ paused: true })

    this.tl.from(input, {
      duration: 0.5,
      y: 60,
      autoAlpha: 0,
      stagger: 0.1,
    })

    this.tl.from(button, { duration: 0.5, y: 20, autoAlpha: 0 })
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=50%',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })
  }

  destroy() {
    this.form && this.form.destroy()
  }
}

import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { qs } from '../utils'
import gsap from 'gsap'

export default class Paragraph extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    Object.assign(this.dom, {
      page: qs('.page'),
    })
  }

  setAnimation(splitText) {
    const description = splitText[0].words

    this.tl = gsap.timeline({ paused: true })
    this.tl.from(description, {
      duration: 0.35,
      y: 40,
      autoAlpha: 0,
      stagger: 0.005,
    })
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    ScrollTrigger.create({
      trigger: el,
      start: 'top 80%',
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })
  }
}

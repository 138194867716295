export const circles = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 240,
  w: 480,
  h: 480,
  nm: 'L - Circles',
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        fName: 'Berkeley Mono',
        fFamily: 'Berkeley Mono',
        fStyle: 'Regular',
        ascent: 72.7996826171875,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Mask',
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [240, 240, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'fl',
          c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 5,
      nm: 'random number 8',
      parent: 7,
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 322, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 12,
                f: 'Berkeley Mono',
                t: 'FAFAF',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 66.9562225341797,
                ls: 0,
                fc: [0.718, 0.718, 0.718],
              },
              t: 0,
            },
          ],
          x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 23900);\n$bm_rt = n = Math.round(n);',
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 241,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 5,
      nm: 'random number 4',
      parent: 7,
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, -318, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 12,
                f: 'Berkeley Mono',
                t: 'FAFAF',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 66.9562225341797,
                ls: 0,
                fc: [0.718, 0.718, 0.718],
              },
              t: 0,
            },
          ],
          x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 23900);\n$bm_rt = n = Math.round(n);',
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 241,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 5,
      nm: 'random number 7',
      parent: 7,
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 400, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 12,
                f: 'Berkeley Mono',
                t: 'FAFAF',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 66.9562225341797,
                ls: 0,
                fc: [0.718, 0.718, 0.718],
              },
              t: 0,
            },
          ],
          x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 23900);\n$bm_rt = n = Math.round(n);',
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 241,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 5,
      nm: 'random number 2',
      parent: 7,
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, -240, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 12,
                f: 'Berkeley Mono',
                t: 'FAFAF',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 66.9562225341797,
                ls: 0,
                fc: [0.718, 0.718, 0.718],
              },
              t: 0,
            },
          ],
          x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 23900);\n$bm_rt = n = Math.round(n);',
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 241,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 5,
      nm: 'random number 6',
      parent: 7,
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 80.358, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 12,
                f: 'Berkeley Mono',
                t: 'FAFAF',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 66.9562225341797,
                ls: 0,
                fc: [0.718, 0.718, 0.718],
              },
              t: 0,
            },
          ],
          x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 23900);\n$bm_rt = n = Math.round(n);',
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 241,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 5,
      nm: 'random number',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [239.757, 351.302, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [239.757, 31.302, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 12,
                f: 'Berkeley Mono',
                t: 'FAFAF',
                ca: 1,
                j: 2,
                tr: 0,
                lh: 66.9562225341797,
                ls: 0,
                fc: [0.718, 0.718, 0.718],
              },
              t: 0,
            },
          ],
          x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 23900);\n$bm_rt = n = Math.round(n);',
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 241,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Shape Layer 20',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [240, 240, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Shape Layer 19',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 960, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 640, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 241,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Shape Layer 18',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 920, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 600, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Shape Layer 17',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 880, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 560, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Shape Layer 16',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 840, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 520, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Shape Layer 15',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 800, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 480, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Shape Layer 14',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 760, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 440, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Shape Layer 13',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 720, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 400, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Shape Layer 12',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 680, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 360, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Shape Layer 11',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 640, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 320, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'Shape Layer 10',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 600, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 280, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: 'Shape Layer 9',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 560, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 240, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 240,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 4,
      nm: 'Shape Layer 8',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 520, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 200, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 211,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 21,
      ty: 4,
      nm: 'Shape Layer 7',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 480, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 160, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 181,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 22,
      ty: 4,
      nm: 'Shape Layer 6',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 440, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 120, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 151,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 23,
      ty: 4,
      nm: 'Shape Layer 5',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 400, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 80, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 121,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 24,
      ty: 4,
      nm: 'Shape Layer 4',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 360, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 40, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 91,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 25,
      ty: 4,
      nm: 'Shape Layer 3',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 320, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, 0, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 61,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 26,
      ty: 4,
      nm: 'Shape Layer 2',
      tt: 1,
      tp: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [240, 280, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 239, s: [240, -40, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [400, 400], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'st',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 3,
          },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false,
        },
      ],
      ip: 0,
      op: 31,
      st: 0,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
  chars: [
    {
      ch: '1',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [32.1, 0],
                      [40.5, 0],
                      [40.5, -68],
                      [30.9, -68],
                      [10.6, -53.4],
                      [10.6, -44],
                      [25.3, -53.3],
                      [32.1, -59],
                      [32.7, -59],
                      [32.1, -48.8],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '9',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [5.8, -5.8],
                      [0, 0],
                      [-3.334, 4.734],
                      [-1.267, 4.267],
                      [0, 5.934],
                      [1.166, 3.134],
                      [3.066, 1.2],
                      [6.333, 0],
                      [3.033, -1.166],
                      [1.166, -3.1],
                      [0, -6.4],
                      [-1.067, -3],
                      [-2.867, -1.133],
                      [-5.934, 0],
                      [-1.6, 0.6],
                      [-1.534, 1.934],
                      [0, 0],
                      [3.1, -3.8],
                    ],
                    o: [
                      [0, 0],
                      [6.866, -7.533],
                      [3.333, -4.733],
                      [1.266, -4.266],
                      [0, -6.533],
                      [-1.167, -3.133],
                      [-3.067, -1.2],
                      [-6.267, 0],
                      [-3.034, 1.167],
                      [-1.167, 3.1],
                      [0, 6.2],
                      [1.066, 3],
                      [2.866, 1.134],
                      [3.533, 0],
                      [1.6, -0.6],
                      [0, 0],
                      [-4.4, 6.734],
                      [-3.1, 3.8],
                    ],
                    v: [
                      [16.3, 1],
                      [27.4, 1],
                      [42.7, -17.4],
                      [49.6, -30.9],
                      [51.5, -46.2],
                      [49.75, -60.7],
                      [43.4, -67.2],
                      [29.3, -69],
                      [15.35, -67.25],
                      [9.05, -60.85],
                      [7.3, -46.6],
                      [8.9, -32.8],
                      [14.8, -26.6],
                      [28, -24.9],
                      [35.7, -25.8],
                      [40.4, -29.6],
                      [40.9, -29.2],
                      [29.65, -13.4],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '9',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 4.134],
                      [-0.734, 2.134],
                      [-1.9, 0.8],
                      [-3.934, 0],
                      [-1.934, -0.766],
                      [-0.767, -1.966],
                      [0, -4.066],
                      [0.7, -2.133],
                      [1.966, -0.8],
                      [4.133, 0],
                      [1.9, 0.767],
                      [0.7, 2],
                    ],
                    o: [
                      [0, -4.4],
                      [0.733, -2.133],
                      [1.9, -0.8],
                      [3.933, 0],
                      [1.933, 0.767],
                      [0.766, 1.967],
                      [0, 4.534],
                      [-0.7, 2.134],
                      [-1.967, 0.8],
                      [-3.867, 0],
                      [-1.9, -0.766],
                      [-0.7, -2],
                    ],
                    v: [
                      [15.5, -46.5],
                      [16.6, -56.3],
                      [20.55, -60.7],
                      [29.3, -61.9],
                      [38.1, -60.75],
                      [42.15, -56.65],
                      [43.3, -47.6],
                      [42.25, -37.6],
                      [38.25, -33.2],
                      [29.1, -32],
                      [20.45, -33.15],
                      [16.55, -37.3],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '9',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '9',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '7',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.4, -0.1],
                      [28, -0.1],
                      [40.6, -33.3],
                      [54, -33.3],
                      [54, -40.6],
                      [43.4, -40.6],
                      [50, -58],
                      [50, -68],
                      [9.4, -68],
                      [9.4, -59.8],
                      [42.4, -59.8],
                      [35, -40.6],
                      [15.7, -40.6],
                      [15.7, -33.3],
                      [32.2, -33.3],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '7',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '7',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '2',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [8.733, -7.466],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.667, 3.567],
                      [-4.867, 5.134],
                      [-0.934, 2.134],
                      [0, 3.067],
                      [1.266, 2.734],
                      [3.033, 1.134],
                      [5.6, 0],
                      [3, -1.033],
                      [1.433, -2.433],
                      [0.4, -4.533],
                      [0, 0],
                      [-0.867, 1.367],
                      [-1.8, 0.567],
                      [-3.2, 0],
                      [-1.8, -0.666],
                      [-0.767, -1.633],
                      [0, -2.933],
                      [0.533, -1.366],
                      [1.466, -1.733],
                      [4.133, -3.833],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.333, -4.6],
                      [3.666, -3.566],
                      [2.6, -2.8],
                      [0.933, -2.133],
                      [0, -5],
                      [-1.267, -2.733],
                      [-3.034, -1.133],
                      [-5.4, 0],
                      [-3, 1.034],
                      [-1.434, 2.434],
                      [0, 0],
                      [0.266, -2.533],
                      [0.866, -1.366],
                      [1.8, -0.566],
                      [3.333, 0],
                      [1.8, 0.667],
                      [0.766, 1.634],
                      [0, 1.934],
                      [-0.534, 1.367],
                      [-2.734, 3.467],
                      [-4.134, 3.834],
                    ],
                    v: [
                      [8.7, -12.6],
                      [8.7, 0],
                      [51.5, 0],
                      [51.5, -8],
                      [17.6, -8],
                      [17.6, -9.4],
                      [31.1, -21.65],
                      [43.9, -34.7],
                      [49.2, -42.1],
                      [50.6, -49.9],
                      [48.7, -61.5],
                      [42.25, -67.3],
                      [29.3, -69],
                      [16.7, -67.45],
                      [10.05, -62.25],
                      [7.3, -51.8],
                      [16.2, -51.8],
                      [17.9, -57.65],
                      [21.9, -60.55],
                      [29.4, -61.4],
                      [37.1, -60.4],
                      [40.95, -56.95],
                      [42.1, -50.1],
                      [41.3, -45.15],
                      [38.3, -40.5],
                      [28, -29.55],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '2',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '0',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.734, 0],
                      [-0.467, 0.267],
                      [-0.267, 1.134],
                      [-0.134, 0.634],
                      [0, 1.867],
                      [0.333, 1.4],
                      [0.533, 0.3],
                      [1.733, 0],
                      [0.466, -0.266],
                      [0.266, -1.133],
                      [0.133, -0.633],
                      [0, -1.866],
                      [-0.334, -1.4],
                      [-0.534, -0.3],
                    ],
                    o: [
                      [1.8, 0],
                      [0.466, -0.266],
                      [0.066, -0.2],
                      [0.133, -0.633],
                      [0, -2],
                      [-0.2, -1],
                      [-0.534, -0.3],
                      [-1.8, 0],
                      [-0.467, 0.267],
                      [-0.067, 0.2],
                      [-0.134, 0.634],
                      [0, 2],
                      [0.2, 1],
                      [0.533, 0.3],
                    ],
                    v: [
                      [29.8, -26.1],
                      [33.2, -26.5],
                      [34.3, -28.6],
                      [34.6, -29.85],
                      [34.8, -33.6],
                      [34.3, -38.7],
                      [33.2, -40.65],
                      [29.8, -41.1],
                      [26.4, -40.7],
                      [25.3, -38.6],
                      [25, -37.35],
                      [24.8, -33.6],
                      [25.3, -28.5],
                      [26.4, -26.55],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '0',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -4.733],
                      [-0.334, -3],
                      [-0.6, -2.533],
                      [-1.4, -2.066],
                      [-2.534, -0.7],
                      [-5.067, 0],
                      [-2.534, 0.7],
                      [-1.4, 2.067],
                      [-1.067, 4.467],
                      [-0.334, 3],
                      [0, 4.8],
                      [0.366, 3],
                      [0.6, 2.6],
                      [1.366, 2.067],
                      [2.533, 0.7],
                      [5.066, 0],
                      [2.533, -0.7],
                      [1.366, -2.066],
                      [1.066, -4.466],
                      [0.366, -3],
                    ],
                    o: [
                      [0, 4.8],
                      [0.333, 3],
                      [1.066, 4.467],
                      [1.4, 2.067],
                      [2.533, 0.7],
                      [5.066, 0],
                      [2.533, -0.7],
                      [1.4, -2.066],
                      [0.6, -2.533],
                      [0.333, -3],
                      [0, -4.733],
                      [-0.367, -3],
                      [-1.067, -4.466],
                      [-1.367, -2.066],
                      [-2.534, -0.7],
                      [-5.067, 0],
                      [-2.534, 0.7],
                      [-1.367, 2.067],
                      [-0.6, 2.6],
                      [-0.367, 3],
                    ],
                    v: [
                      [7.1, -33.6],
                      [7.6, -21.9],
                      [9, -13.6],
                      [12.7, -3.8],
                      [18.6, 0.35],
                      [30, 1.4],
                      [41.4, 0.35],
                      [47.3, -3.8],
                      [51, -13.6],
                      [52.4, -21.9],
                      [52.9, -33.6],
                      [52.35, -45.2],
                      [50.9, -53.6],
                      [47.25, -63.4],
                      [41.4, -67.55],
                      [30, -68.6],
                      [18.6, -67.55],
                      [12.75, -63.4],
                      [9.1, -53.6],
                      [7.65, -45.2],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '0',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 2,
                ty: 'sh',
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 4.267],
                      [-0.267, 2.267],
                      [-0.467, 2.8],
                      [-0.767, 1.4],
                      [-1.7, 0.667],
                      [-3.2, 0],
                      [-1.7, -0.666],
                      [-0.767, -1.4],
                      [-0.467, -2.666],
                      [-0.267, -2.266],
                      [0, -4.266],
                      [0.266, -2.266],
                      [0.466, -2.8],
                      [0.766, -1.4],
                      [1.7, -0.666],
                      [3.2, 0],
                      [1.7, 0.667],
                      [0.766, 1.4],
                      [0.466, 2.667],
                      [0.266, 2.267],
                    ],
                    o: [
                      [0, -4.266],
                      [0.266, -2.266],
                      [0.466, -2.666],
                      [0.766, -1.4],
                      [1.7, -0.666],
                      [3.2, 0],
                      [1.7, 0.667],
                      [0.766, 1.4],
                      [0.466, 2.8],
                      [0.266, 2.267],
                      [0, 4.267],
                      [-0.267, 2.267],
                      [-0.467, 2.667],
                      [-0.767, 1.4],
                      [-1.7, 0.667],
                      [-3.2, 0],
                      [-1.7, -0.666],
                      [-0.767, -1.4],
                      [-0.467, -2.8],
                      [-0.267, -2.266],
                    ],
                    v: [
                      [15.6, -33.6],
                      [16, -43.4],
                      [17.1, -51],
                      [18.95, -57.1],
                      [22.65, -60.2],
                      [30, -61.2],
                      [37.35, -60.2],
                      [41.05, -57.1],
                      [42.9, -51],
                      [44, -43.4],
                      [44.4, -33.6],
                      [44, -23.8],
                      [42.9, -16.2],
                      [41.05, -10.1],
                      [37.35, -7],
                      [30, -6],
                      [22.65, -7],
                      [18.95, -10.1],
                      [17.1, -16.2],
                      [16, -23.8],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '0',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '0',
            np: 6,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '3',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.534, 0],
                      [-3.5, 1.4],
                      [-1.567, 3.2],
                      [0, 5.534],
                      [2.5, 2.967],
                      [6.066, 0],
                      [2.133, -0.133],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.334, 0],
                      [-1.967, -1.9],
                      [0, -4.4],
                      [1.033, -2.133],
                      [2.366, -0.933],
                      [4.133, 0],
                      [2.533, 0.667],
                      [3.466, 1.667],
                      [0, 0],
                      [-2.534, -0.5],
                    ],
                    o: [
                      [6.133, 0],
                      [3.5, -1.4],
                      [1.566, -3.2],
                      [0, -7.066],
                      [-2.5, -2.966],
                      [-0.8, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.866, -0.533],
                      [4.666, 0],
                      [1.966, 1.9],
                      [0, 3.734],
                      [-1.034, 2.134],
                      [-2.367, 0.934],
                      [-2.8, 0],
                      [-2.534, -0.666],
                      [0, 0],
                      [3, 1.267],
                      [2.533, 0.5],
                    ],
                    v: [
                      [24.9, 0.9],
                      [39.35, -1.2],
                      [46.95, -8.1],
                      [49.3, -21.2],
                      [45.55, -36.25],
                      [32.7, -40.7],
                      [28.3, -40.5],
                      [28.1, -40.9],
                      [46.7, -58.5],
                      [46.7, -68],
                      [8.9, -68],
                      [8.9, -60.6],
                      [37.7, -60.6],
                      [37.7, -60.3],
                      [18.7, -42.4],
                      [18.7, -33],
                      [28, -33.8],
                      [37.95, -30.95],
                      [40.9, -21.5],
                      [39.35, -12.7],
                      [34.25, -8.1],
                      [24.5, -6.7],
                      [16.5, -7.7],
                      [7.5, -11.2],
                      [7.5, -2.5],
                      [15.8, 0.15],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '3',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '3',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '6',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.334, 0],
                      [-3.034, 1.166],
                      [-1.167, 3.034],
                      [0, 6.267],
                      [1.033, 2.934],
                      [2.766, 1.1],
                      [5.666, 0],
                      [2.666, -0.966],
                      [0.733, -1.933],
                      [0, 0],
                      [-1.434, 2.3],
                      [-3.8, 3.9],
                      [-7.4, 6.534],
                      [0, 0],
                      [3.5, -4.866],
                      [0.933, -4.2],
                      [0, -6.8],
                      [-1.167, -3.066],
                      [-3.067, -1.166],
                    ],
                    o: [
                      [6.266, 0],
                      [3.033, -1.166],
                      [1.166, -3.033],
                      [0, -6.066],
                      [-1.034, -2.933],
                      [-2.767, -1.1],
                      [-4.067, 0],
                      [-2.667, 0.967],
                      [0, 0],
                      [0.733, -2.533],
                      [1.433, -2.3],
                      [3.8, -3.9],
                      [0, 0],
                      [-7.667, 6.867],
                      [-3.5, 4.867],
                      [-0.934, 4.2],
                      [0, 6.4],
                      [1.166, 3.067],
                      [3.066, 1.166],
                    ],
                    v: [
                      [30.1, 1],
                      [44.05, -0.75],
                      [50.35, -7.05],
                      [52.1, -21],
                      [50.55, -34.5],
                      [44.85, -40.55],
                      [32.2, -42.2],
                      [22.1, -40.75],
                      [17, -36.4],
                      [16.5, -36.8],
                      [19.75, -44.05],
                      [27.6, -53.35],
                      [44.4, -69],
                      [32.7, -69],
                      [15.95, -51.4],
                      [9.3, -37.8],
                      [7.9, -21.3],
                      [9.65, -7.1],
                      [16, -0.75],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '6',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 3.934],
                      [-0.7, 2.034],
                      [-1.9, 0.767],
                      [-4.067, 0],
                      [-1.9, -0.733],
                      [-0.734, -1.933],
                      [0, -4],
                      [0.733, -2.033],
                      [1.9, -0.766],
                      [3.933, 0],
                      [1.9, 0.734],
                      [0.766, 1.934],
                    ],
                    o: [
                      [0, -4.333],
                      [0.7, -2.033],
                      [1.9, -0.766],
                      [3.933, 0],
                      [1.9, 0.734],
                      [0.733, 1.934],
                      [0, 4.267],
                      [-0.734, 2.034],
                      [-1.9, 0.767],
                      [-3.934, 0],
                      [-1.9, -0.733],
                      [-0.767, -1.933],
                    ],
                    v: [
                      [16.2, -20],
                      [17.25, -29.55],
                      [21.15, -33.75],
                      [30.1, -34.9],
                      [38.85, -33.8],
                      [42.8, -29.8],
                      [43.9, -20.9],
                      [42.8, -11.45],
                      [38.85, -7.25],
                      [30.1, -6.1],
                      [21.35, -7.2],
                      [17.35, -11.2],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '6',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '6',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '5',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.467, 0],
                      [-3.6, 1.467],
                      [-1.6, 3.267],
                      [0, 5.667],
                      [1.266, 2.934],
                      [2.8, 1.5],
                      [4.866, 0.534],
                      [1.4, 0],
                      [2.2, -0.666],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.534, 0],
                      [-1.6, -0.2],
                      [-1.634, -1.966],
                      [0, -4.533],
                      [2.4, -2.266],
                      [5.733, 0],
                      [2.6, 0.7],
                      [3.666, 1.734],
                      [0, 0],
                      [-2.534, -0.5],
                    ],
                    o: [
                      [6.2, 0],
                      [3.6, -1.466],
                      [1.6, -3.266],
                      [0, -4.933],
                      [-1.267, -2.933],
                      [-2.8, -1.5],
                      [-1.8, -0.2],
                      [-2.6, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [6.733, -0.866],
                      [1.866, 0],
                      [4.2, 0.534],
                      [1.633, 1.967],
                      [0, 5.534],
                      [-2.4, 2.267],
                      [-3.067, 0],
                      [-2.6, -0.7],
                      [0, 0],
                      [2.933, 1.267],
                      [2.533, 0.5],
                    ],
                    v: [
                      [25.6, 0.7],
                      [40.3, -1.5],
                      [48.1, -8.6],
                      [50.5, -22],
                      [48.6, -33.8],
                      [42.5, -40.45],
                      [31, -43.5],
                      [26.2, -43.8],
                      [19, -42.8],
                      [20.3, -60.4],
                      [47.6, -60.6],
                      [47.6, -68],
                      [13, -68],
                      [10.3, -34.5],
                      [25.7, -35.8],
                      [30.9, -35.5],
                      [39.65, -31.75],
                      [42.1, -22],
                      [38.5, -10.3],
                      [26.3, -6.9],
                      [17.8, -7.95],
                      [8.4, -11.6],
                      [8.4, -2.7],
                      [16.6, -0.05],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '5',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '5',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '4',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8, -12.8],
                      [35.4, -12.8],
                      [35.4, 0],
                      [43.4, 0],
                      [43.4, -12.8],
                      [53, -12.8],
                      [53, -20.4],
                      [43.4, -20.4],
                      [43.4, -37],
                      [35.4, -37],
                      [35.4, -20.4],
                      [16.6, -20.4],
                      [16.6, -22.6],
                      [39.7, -69],
                      [30.6, -69],
                      [8, -22.6],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '4',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '4',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '8',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.4, 0],
                      [-3.134, 1],
                      [-1.167, 2.634],
                      [0, 5.467],
                      [0.666, 1.934],
                      [1.766, 1.534],
                      [3.666, 2.067],
                      [0, 0],
                      [-1.534, 1.334],
                      [-0.567, 1.667],
                      [0, 2.8],
                      [1.066, 2.234],
                      [2.866, 0.867],
                      [5.933, 0],
                      [2.833, -0.866],
                      [1.066, -2.233],
                      [0, -4.666],
                      [-0.567, -1.666],
                      [-1.534, -1.333],
                      [-3.134, -1.666],
                      [0, 0],
                      [1.766, -1.566],
                      [0.666, -1.9],
                      [0, -3.266],
                      [-1.167, -2.633],
                      [-3.067, -1],
                    ],
                    o: [
                      [6.4, 0],
                      [3.133, -1],
                      [1.166, -2.633],
                      [0, -3.2],
                      [-0.667, -1.933],
                      [-1.767, -1.533],
                      [0, 0],
                      [3.133, -1.666],
                      [1.533, -1.333],
                      [0.566, -1.666],
                      [0, -4.666],
                      [-1.067, -2.233],
                      [-2.867, -0.866],
                      [-5.934, 0],
                      [-2.834, 0.867],
                      [-1.067, 2.234],
                      [0, 2.8],
                      [0.566, 1.667],
                      [1.533, 1.334],
                      [0, 0],
                      [-3.6, 2],
                      [-1.767, 1.567],
                      [-0.667, 1.9],
                      [0, 5.467],
                      [1.166, 2.634],
                      [3.066, 1],
                    ],
                    v: [
                      [29.9, 1],
                      [44.2, -0.5],
                      [50.65, -5.95],
                      [52.4, -18.1],
                      [51.4, -25.8],
                      [47.75, -31],
                      [39.6, -36.4],
                      [39.6, -37],
                      [46.6, -41.5],
                      [49.75, -46],
                      [50.6, -52.7],
                      [49, -63.05],
                      [43.1, -67.7],
                      [29.9, -69],
                      [16.75, -67.7],
                      [10.9, -63.05],
                      [9.3, -52.7],
                      [10.15, -46],
                      [13.3, -41.5],
                      [20.3, -37],
                      [20.3, -36.4],
                      [12.25, -31.05],
                      [8.6, -25.85],
                      [7.6, -18.1],
                      [9.35, -5.95],
                      [15.7, -0.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 3.267],
                      [-0.734, 1.734],
                      [-1.934, 1.234],
                      [-4, 1.467],
                      [-1.967, -1.266],
                      [-0.734, -1.766],
                      [0, -3.066],
                      [0.733, -1.533],
                      [1.966, -0.566],
                      [4.066, 0],
                      [1.933, 0.6],
                      [0.733, 1.534],
                    ],
                    o: [
                      [0, -3.066],
                      [0.733, -1.733],
                      [1.933, -1.233],
                      [4.066, 1.467],
                      [1.966, 1.267],
                      [0.733, 1.767],
                      [0, 3.2],
                      [-0.734, 1.534],
                      [-1.967, 0.567],
                      [-4, 0],
                      [-1.934, -0.6],
                      [-0.734, -1.533],
                    ],
                    v: [
                      [15.9, -17.7],
                      [17, -24.9],
                      [21, -29.35],
                      [29.9, -33.4],
                      [38.95, -29.3],
                      [43, -24.75],
                      [44.1, -17.5],
                      [43, -10.4],
                      [38.95, -7.25],
                      [29.9, -6.4],
                      [21, -7.3],
                      [17, -10.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 2,
                ty: 'sh',
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 2.6],
                      [-0.634, 1.234],
                      [-1.7, 0.467],
                      [-3.534, 0],
                      [-1.734, -0.466],
                      [-0.634, -1.266],
                      [0, -2.6],
                      [0.633, -1.433],
                      [1.733, -0.933],
                      [3.533, -1.066],
                      [1.7, 0.967],
                      [0.633, 1.434],
                    ],
                    o: [
                      [0, -2.6],
                      [0.633, -1.233],
                      [1.7, -0.466],
                      [3.533, 0],
                      [1.733, 0.467],
                      [0.633, 1.267],
                      [0, 2.534],
                      [-0.634, 1.434],
                      [-1.734, 0.934],
                      [-3.534, -1],
                      [-1.7, -0.966],
                      [-0.634, -1.433],
                    ],
                    v: [
                      [17.6, -52.7],
                      [18.55, -58.45],
                      [22.05, -61],
                      [29.9, -61.7],
                      [37.8, -61],
                      [41.35, -58.4],
                      [42.3, -52.6],
                      [41.35, -46.65],
                      [37.8, -43.1],
                      [29.9, -40.1],
                      [22.05, -43.05],
                      [18.55, -46.65],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '8',
            np: 6,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
  ],
}

import Module from './module'
import { LottieAnimate } from '../animations'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { drop } from '../lottie'
import { qs } from '../utils'
import gsap from 'gsap'

export default class LargeTitle extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    this.lottie = {
      drop: {
        data: drop,
        el: qs('.largetitle-block.block-1'),
      },
    }
  }

  setAnimation(splitText) {
    const { drop } = this.lottie
    const title = splitText[0].chars

    this.tl = gsap.timeline({ paused: true })

    this.tl.from(title, {
      duration: 0.5,
      y: '101%',
      stagger: 0.035,
      ease: 'power2.inOut',
    })

    // * Setup Lottie Animation

    drop.anim = new LottieAnimate({
      container: drop.el,
      data: drop.data,
      loop: false,
      autoplay: false,
    })

    drop.anim.load()
  }

  setTrigger() {
    const { el } = this.dom
    const { drop } = this.lottie
    let anim = null

    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'top top',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=20%',
      end: 'bottom bottom-=80%',
      scrub: true,
      onEnter: () => {
        anim = drop.anim.getAnimation()
      },
      onUpdate: (self) => {
        const progress = self.progress
        anim && anim.goToAndStop(progress * anim.totalFrames, true)
      },
    })
  }
}
